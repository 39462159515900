import { MyTitle } from "components/hrWrapper/style";
import TableComponent from "components/table";
import React from "react";
import useData from "./components/hooks/usedata";
import { Wrapper } from "./style";
import { Button, Input } from "antd";
export default function Users() {
  const { data, columns, value, setValue, isLoading, handleSearch } = useData();
  return (
    <Wrapper>
      <MyTitle>
        <h2>Страница пользователя</h2>
        <div className="search">
          <Input
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder="Поиск"
            style={{ borderRadius: 6 }}
          />
          <Button
            onClick={() => handleSearch()}
            disabled={!(value?.length > 3)}
            loading={isLoading}
          >
            Поиск
          </Button>
        </div>
      </MyTitle>

      <TableComponent
        isloading={isLoading}
        data={data?.data}
        columns={columns}
        params={data?.param}
      />
    </Wrapper>
  );
}
