import { Col, Row } from "antd";
import { InputComponent } from "components/inputs/input";
import { CustomMask } from "components/inputs/mask";
import { ErrorP } from "components/inputs/input/style";
import React from "react";
import { Wrapper } from "./style";
import { MyTitle } from "components/hrWrapper/style";
import ButtonComponent from "components/buttons";
import useCrud from "./hooks/useCrud";
import LoaderComponent from "components/loader";
import { useNavigate, useParams } from "react-router-dom";
import { TextareaComponent } from "components/inputs/textarea";

export default function CrudFaqType() {
  const navigate = useNavigate();
  const { id } = useParams();

  const { sbmt, handleSubmit, control, loader, errors, img, setImg } =
    useCrud();

  return (
    <Wrapper>
      {loader && <LoaderComponent />}
      <MyTitle>
        <h2>FAQ ТИП {id === undefined ? "Создавать" : "Редактирование"}</h2>
        <ButtonComponent className="back" onClick={() => navigate(-1)}>
          Назад
        </ButtonComponent>
      </MyTitle>
      <form onSubmit={handleSubmit(sbmt)}>
        <Row gutter={[16, 16]}>
          <Col span={12} md={12}>
            <InputComponent
              control={control}
              nameProps="name_uz"
              plProps="Имя (o'zbekcha)"
              type="text"
              required={true}
              minLength={4}
              className={
                errors && errors?.hasOwnProperty("name_uz")
                  ? "input-error"
                  : " "
              }
            />
            {errors && errors?.hasOwnProperty("name_uz") && (
              <ErrorP>Это поле является обязательным!</ErrorP>
            )}
          </Col>
          <Col span={12} md={12}>
            <InputComponent
              control={control}
              nameProps="name_ru"
              plProps="Имя (ruscha)"
              type="text"
              required={true}
              minLength={4}
              className={
                errors && errors?.hasOwnProperty("name_ru")
                  ? "input-error"
                  : " "
              }
            />
            {errors && errors?.hasOwnProperty("name_ru") && (
              <ErrorP>Это поле является обязательным!</ErrorP>
            )}
          </Col>

          <Col span={12} md={12}>
            <InputComponent
              control={control}
              nameProps="name_en"
              plProps="Имя (en)"
              type="text"
              required={true}
              minLength={4}
              className={
                errors && errors?.hasOwnProperty("name_en")
                  ? "input-error"
                  : " "
              }
            />
            {errors && errors?.hasOwnProperty("name_en") && (
              <ErrorP>Это поле является обязательным!</ErrorP>
            )}
          </Col>
          <Col
            span={24}
            md={6}
            style={{ marginLeft: "auto", marginTop: "24px" }}
          >
            <ButtonComponent type="submit">
              {id === undefined ? "Создавать" : "Редактирование"}
            </ButtonComponent>
          </Col>
        </Row>
      </form>
    </Wrapper>
  );
}
