import { useMutation, useQuery } from "@tanstack/react-query";
import { notification } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { getsApi, postApi, putApi } from "services/api/pagesApi";
import { ApiSiteKey } from "services/jsons";

export default function useCrud() {
  const [img, setImg] = useState(undefined);
  const [imgLink, setImgLink] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  console.log(id);
  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const { mutate: fileMutate, isLoading: fileLoading } = useMutation(
    (data) => postApi.addImage(data),
    {
      onSuccess: (res) => {
        setImgLink(res?.data);
        notification["success"]({
          message: "Файл загружен",
        });
      },
      onError: (er) => {
        notification["error"]({
          message: "Xatolik",
          description:
            er?.message ?? er?.msg ?? "При загрузке файла произошла ошибка",
        });
      },
    }
  );

  const { isLoading: loadCat, data: catalogs } = useQuery(
    [ApiSiteKey.getCatalogs],
    () => getsApi.getCatalogs({ limit: 99, offset: 0 }),

    {
      select: (data) => {
        return data?.data?.map((x) => {
          return {
            value: x?.id,
            label: x?.name,
          };
        });
      },
      onSuccess: (data) => data,
    }
  );

  const { isLoading: load } = useQuery(
    [ApiSiteKey.getSubCatalogsById],
    () => getsApi.getSubCatalogById({ id }),

    {
      select: (data) => {
        return data?.data;
      },
      onSuccess: (data) => {
        reset({
          ...data,
          catalog_id: {
            value: data?.catalog_id,
            label: catalogs?.find((x) => x?.value === data?.catalog_id)?.label,
          },
        });
        // setImg(data?.image_link);
      },
      enabled: id !== undefined ? true : false,
    }
  );

  const { mutate: keyMutate, isLoading: loadingKey } = useMutation(
    (data) => putApi.updateSubCataloKey(data),
    {
      onSuccess: () => {
        navigate("/subcatalogs");
        notification["success"]({
          message: "Подкатегория и ключ созданы",
        });
      },
      onError: () => {
        notification["error"]({
          message: "Ошибка",
        });
      },
    }
  );

  const { mutate, isLoading: loading } = useMutation(
    (data) =>
      id === undefined
        ? postApi.addSubCatalog(data)
        : putApi.editSubCatalog(data),
    {
      onSuccess: (data) => {
        if (id === undefined) {
          keyMutate({ ...data?.data, key: watch("key") });
        } else {
          navigate("/subcatalogs");
          notification["success"]({
            message: "Подкатегория и ключ отредактированы",
          });
        }
      },
      onError: () => {
        notification["error"]({
          message: "Ошибка",
        });
      },
    }
  );

  function sbmt(info) {
    let data = {
      catalog_id: info?.catalog_id.value,
      description: info.description,
      image: " ",
      name: info.name,
    };
    let dataEdit = {
      catalog_id: info?.catalog_id.value,
      description: info.description,
      image: " ",
      name: info.name,
      subcatalog_id: id,
      key: info.key,
    };

    mutate(id === undefined ? data : dataEdit);
  }

  let loader =
    fileLoading ||
    (id !== undefined ? load : false) ||
    loading ||
    loadingKey ||
    loadCat;

  return {
    sbmt,
    handleSubmit,
    control,
    reset,
    loader,
    errors,
    img,
    setImg,
    catalogs,
  };
}
