import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, Modal, Tag } from "antd";
import { Image } from "components/image/style";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DeletesApi, getsApi } from "services/api/pagesApi";
import { ApiSiteKey } from "services/jsons";

const { confirm } = Modal;
export default function useData() {
  const navigate = useNavigate();
  const location = useLocation();

  const { search = "?offset=0&limit=10" } = location;

  const { refetch, data, isLoading } = useQuery(
    [ApiSiteKey.getPartnerss, search],
    () => getsApi.getPartners(search),

    {
      select: (data) => {
        const info = {
          param: data?.data?.pagination,
          data: data?.data?.list?.partner_list?.map((x) => {
            return {
              ...x,
              key: x?.id?.replaceAll("-", ""),
            };
          }),
        };
        return info;
      },
      onSuccess: (data) => {
        console.log(data);
      },
    }
  );

  function handleDel(id) {
    confirm({
      title: "Удалить",
      content: "Вы уверены, что хотите удалить?",
      onOk() {
        mutate(id);
      },
      onCancel() {
        console.log("close");
      },
      okText: "Удалить",
    });
  }

  const { mutate, isLoading: load } = useMutation(
    (id) => DeletesApi.delPartner(id),
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  function handleEdit(id) {
    navigate(`edit/${id}`);
  }

  let loading = load || isLoading;

  const columns = [
    {
      title: "Заголовок",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "URL-адрес",
      dataIndex: "link",
      key: "link",
    },

    {
      title: "Фото",
      dataIndex: "image",
      key: "image",
      render: (x) =>
        x === "" ? (
          <Tag style={{ borderRadius: 4 }}>Изображение не загружено</Tag>
        ) : (
          <div
            style={{
              padding: 4,
              backgroundColor: "#fff",
              maxWidth: 150,
              textAlign: "center",
            }}
          >
            <Image width={"100%"} src={x} />
          </div>
        ),
    },

    {
      title: "Действие",
      dataIndex: "Action",
      key: "Action",
      render: (id, row) => (
        <>
          <div className="btnWrapper-list" key={row.key}>
            <Button
              style={{ marginRight: 8 }}
              type="primary"
              icon={<EditOutlined />}
              onClick={() => handleEdit(row?.id)}
            />

            <Button
              type="dashed"
              onClick={() => handleDel(row?.id)}
              icon={<DeleteOutlined />}
            />
          </div>
        </>
      ),
    },
  ].filter((item) => !item.hidden);

  return { data, columns, loading };
}
