import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  td {
    min-width: 200px;
  }
  .myYmap {
    width: 100%;
    padding-top: 16px;
    .map {
      min-height: 70vh;
      width: 100%;
      border-radius: 8px;
      overflow: hidden;
    }
  }
  .content {
    p {
      font-size: 18px;
      border: 1px solid var(--fc-event-bg-color);
      border-radius: 12px;
      padding: 16px 20px;
      margin: 0;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
      background-color: #fefefe;
      b {
        font-size: 16px;
        width: 100%;
        display: block;
        padding-bottom: 4px;
      }

      a {
        color: var(--green);
        transition: all 0.3s;
        &:hover {
          transform: scale(1.5) !important;
        }
      }
    }

    .btns {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 12px;
      button {
        cursor: pointer;
      }

      .red {
        background-color: #e72e4e !important;
        border-color: #e72e4e !important;
      }
    }
  }

  .desc_dangerously {
    p {
      padding: 0;
      border: 0;
      box-shadow: none !important;
    }
  }

  img {
    border: 1px solid var(--fc-event-bg-color);
  }
`;

export const CardCatalog = styled.div`
  background-color: red;
`;
