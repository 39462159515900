import Api from "../index";

class Puts extends Api {
  editAdmin(data, id) {
    return this.execute(
      "put",
      `boss/admin-account/admin-update?admin_id=${id}`,
      data
    );
  }
  editCatalog(data) {
    return this.execute("put", `admin/catalog/update`, data);
  }
  editFaqType(data) {
    return this.execute("put", `admin/faq-category/update`, data);
  }
  editFaq(data) {
    return this.execute("put", `admin/faq/update`, data);
  }
  editTarif(data) {
    return this.execute("put", `admin/vir-pay-mod/update`, data);
  }

  editInvestment(data) {
    return this.execute("put", `admin/investment/update`, data);
  }
  editPartner(data) {
    return this.execute("put", `admin/partner/update`, data);
  }
  editSubCatalog(data) {
    return this.execute("put", `admin/subcatalog/update`, data);
  }
  updateCataloKey(data) {
    return this.execute("put", `admin/catalog/update-key`, data);
  }
  updateSubCataloKey(data) {
    return this.execute("put", `admin/subcatalog/update-key`, data);
  }
  activedPost(id) {
    return this.execute("put", `admin/check-post/activate-post?postId=` + id);
  }
  premium(id, type) {
    return this.execute(
      "put",
      `admin/analytics/giving-user-privilege?userId=${id}&privileged=${type}`
    );
  }
  rejectedPost(id, data) {
    return this.execute(
      "put",
      `admin/check-post/write-errorMessage?postId=` + id,
      data
    );
  }
}

export default Puts;
