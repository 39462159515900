export const customStyles = {
  dropdownIndicator: (provided, state) => ({
    ...provided,
    transition: "all 0.3s",
    transform: state.selectProps.menuIsOpen && "rotate(180deg)",
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    // backgroundColor: state.isSelected
    //   ? 'rgba(44, 71, 62, 1)'
    //   : state.isHover
    //   ? 'rgba(231, 240, 236, 1)'
    //   : '#fff',
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#ccc",
      fontSize: "12px",
      fontFamily: "Rubik, sans-serif",
    };
  },

  control: (provided, { isDisabled, isFocused, isHover }) => ({
    ...provided,
    width: "100%",
    marginTop: "6px",
    lineHeight: "1",
    cursor: isDisabled ? "text" : "pointer",
    minHeight: "30px !important",
    height: "35px !important",
    // overflowY: 'auto',
    backgroundColor: isDisabled
      ? "#f9f9f9 "
      : isFocused
      ? "transparent"
      : isHover
      ? "rgba(231, 240, 236, 1)"
      : "#fff",
    backdropFilter: isDisabled ? "blur(50px)" : null,
    border: isFocused
      ? "1px solid rgba(44, 71, 62, 1) !important"
      : "1px solid #eee !important",
    boxShadow: isFocused ?? "1px solid rgba(44, 71, 62, 1) !important",
    userSelect: "none",
    borderRadius: "4px",
    fontSize: "14px",
    "&:focus": {
      border: isFocused
        ? "border: 1px solid rgba(44, 71, 62, 1) !important"
        : "transparent",
    },
    color: "rgb(127, 127, 127) !important",
  }),
};
