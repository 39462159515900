import styled from "styled-components";

export const Wrapper = styled.div`
  .wrapperClassName {
    border: 1px solid #eee;
  }

  .back {
    opacity: 0.9;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .my-btn {
      margin: 0 4px;
      background-color: transparent;
      border: 1px solid var(--green);
      color: var(--green);
    }
    .remove {
      border: 1px solid var(--red);
      color: var(--red);
    }
  }
`;
