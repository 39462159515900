import { useMutation, useQuery } from "@tanstack/react-query";
import { notification } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { getsApi, postApi, putApi } from "services/api/pagesApi";
import { ApiSiteKey } from "services/jsons";

export default function useCrud() {
  const [img, setImg] = useState(undefined);
  const [imgLink, setImgLink] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const { mutate: fileMutate, isLoading: fileLoading } = useMutation(
    (data) => postApi.addImage(data),
    {
      onSuccess: (res) => {
        setImgLink(res?.data);
        notification["success"]({
          message: "Файл загружен",
        });
      },
      onError: (er) => {
        notification["error"]({
          message: "Ошибка",
          description:
            er?.message ?? er?.msg ?? "При загрузке файла произошла ошибка",
        });
      },
    }
  );

  const { isLoading: loadft, data: faqtype } = useQuery(
    [ApiSiteKey.geFaqType],
    () => getsApi.getFaqType({ limit: 99, offset: 0 }),

    {
      select: (data) => {
        return data?.data?.faq_category_list?.map((x) => {
          return {
            value: x?.id,
            label: x?.name_uz,
          };
        });
      },
      onSuccess: (data) => {
        console.log(data);
      },
    }
  );
  const { isLoading: load } = useQuery(
    [ApiSiteKey.geFaqById, faqtype],
    () => getsApi.getFaqById({ id }),

    {
      select: (data) => {
        return data?.data;
      },
      onSuccess: (data) => {
        reset({
          ...data,
          category_id: faqtype?.find((x) => x?.value === data?.category_id),
        });
        // setImg(data?.image_link);
      },
      enabled: id !== undefined ? true : false,
    }
  );
  const { mutate, isLoading: loading } = useMutation(
    (data) => (id === undefined ? postApi.addFaq(data) : putApi.editFaq(data)),
    {
      onSuccess: (data) => {
        navigate("/faq");
        notification["success"]({
          message: `${id !== undefined ? "Отредактировано" : "Созданный"}`,
        });
      },
      onError: () => {
        notification["error"]({
          message: "Ошибка",
        });
      },
    }
  );

  function sbmt(data) {
    mutate(
      id === undefined
        ? { ...data, category_id: data?.category_id?.value }
        : { ...data, id }
    );
  }

  let loader =
    fileLoading || (id !== undefined ? load : false) || loading || loadft;

  return {
    sbmt,
    handleSubmit,
    control,
    reset,
    loader,
    errors,
    img,
    setImg,
    faqtype,
  };
}
