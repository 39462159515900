import { Col, Row } from "antd";
import { InputComponent } from "components/inputs/input";
import { ErrorP } from "components/inputs/input/style";
import React from "react";
import { Wrapper } from "./style";
import { MyTitle } from "components/hrWrapper/style";
import ButtonComponent from "components/buttons";
import useCrud from "./hooks/useCrud";
import FileCustom from "components/inputs/file";
import LoaderComponent from "components/loader";
import { useNavigate, useParams } from "react-router-dom";
import { TextareaComponent } from "components/inputs/textarea";
import UseFormSelectComponent from "components/inputs/select";

export default function CrudSubCatalog() {
  const navigate = useNavigate();
  const { id } = useParams();

  const { sbmt, handleSubmit, control, loader, errors, img, setImg, catalogs } =
    useCrud();
  return (
    <Wrapper>
      {loader && <LoaderComponent />}
      <MyTitle>
        <h2>{id === undefined ? "Создавать" : "Редактирование"} Подкаталог</h2>
        <ButtonComponent className="back" onClick={() => navigate(-1)}>
          Назад
        </ButtonComponent>
      </MyTitle>
      <form onSubmit={handleSubmit(sbmt)}>
        <Row gutter={[16, 16]}>
          <Col span={12} md={12}>
            <InputComponent
              control={control}
              nameProps="name"
              plProps="Имя"
              type="text"
              required={true}
              minLength={4}
              className={
                errors && errors?.hasOwnProperty("name") ? "input-error" : " "
              }
            />
            {errors && errors?.hasOwnProperty("name") && (
              <ErrorP>Это поле является обязательным!</ErrorP>
            )}
          </Col>
          <Col span={24} lg={12}>
            <UseFormSelectComponent
              control={control}
              required={true}
              name="catalog_id"
              placeholder={"Выберите категорию"}
              options={catalogs}
              disabled={false}
              className={
                errors && errors?.hasOwnProperty("catalog_id")
                  ? "input-error select"
                  : " select "
              }
            />
            {errors && errors?.hasOwnProperty("catalog_id") && (
              <ErrorP>
                <ErrorP>Это поле является обязательным!</ErrorP>
              </ErrorP>
            )}
          </Col>
          <Col span={12} md={12}>
            <InputComponent
              control={control}
              nameProps="key"
              plProps="Key"
              type="text"
              required={true}
              minLength={4}
              className={
                errors && errors?.hasOwnProperty("key") ? "input-error" : " "
              }
            />
            {errors && errors?.hasOwnProperty("key") && (
              <ErrorP>Это поле является обязательным!</ErrorP>
            )}
          </Col>
          {/* <Col span={12} md={4}>
            <FileCustom imageUrl={img} setImageUrl={setImg} />
          </Col> */}
          <Col span={12} md={8}>
            <TextareaComponent
              control={control}
              nameProps="description"
              plProps="Описание"
              type="text"
              minLength={4}
              className={
                errors && errors?.hasOwnProperty("description")
                  ? "input-error"
                  : " "
              }
            />
          </Col>

          <Col
            span={24}
            md={6}
            style={{ marginLeft: "auto", marginTop: "24px" }}
          >
            <ButtonComponent type="submit">
              {id === undefined ? "Создавать" : "Редактирование"}
            </ButtonComponent>
          </Col>
        </Row>
      </form>
    </Wrapper>
  );
}
