import styled from "styled-components";

export const Wrapper = styled.div`
  .wrapperClassName {
    border: 1px solid #eee;
  }

  .back {
    opacity: 0.9;
  }
`;
