import Api from "../index";

class Auth extends Api {
  login(data) {
    // return this.execute("post", `admin/account/sign-in`, data);
    return this.execute("post", `boss-admin/sign-in`, data);
  }
  loginBoss(data) {
    return this.execute("post", `boss/sign-in`, data);
  }
}

export default Auth;
