import { useMutation, useQuery } from "@tanstack/react-query";
import { notification } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { getsApi, postApi, putApi } from "services/api/pagesApi";
import { ApiSiteKey, StatusInvestment } from "services/jsons";

export default function useCrud() {
  const [img, setImg] = useState(undefined);
  const [location, setLocation] = useState("");
  const [imgLink, setImgLink] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  const form = useForm();

  const { mutate: fileMutate, isLoading: fileLoading } = useMutation(
    // (data) => request.post("minio/upload/image", data),
    (data) => postApi.addImage(data),
    {
      onSuccess: (res) => {
        setImgLink(res?.data);
        notification["success"]({
          message: "Файл загружен",
        });
      },
      onError: (er) => {
        notification["error"]({
          message: "Ошибка",
          description: er?.message ?? "При загрузке файла произошла ошибка",
        });
      },
    }
  );

  const { isLoading: load } = useQuery(
    [ApiSiteKey.getInvestmentsById],
    () => getsApi.getPartnersById({ id }),

    {
      select: (data) => {
        return data?.data;
      },
      onSuccess: (data) => {
        form.reset({
          ...data,
          title: data?.name,
        });
        setImg(data?.image[0]);
      },
      enabled: id !== undefined ? true : false,
    }
  );

  const { mutate, isLoading: loading } = useMutation(
    (data) =>
      id === undefined ? postApi.addPartner(data) : putApi.editPartner(data),
    {
      onSuccess: (data) => {
        navigate("/partners");
        notification["success"]({
          message: `Операция успешно завершена`,
        });
      },
      onError: () => {
        notification["error"]({
          message: "Ошибка",
        });
      },
    }
  );

  useEffect(() => {
    if (img != undefined && typeof img !== "string") {
      const formdata = new FormData();
      formdata.append("file", img);
      fileMutate(formdata);
    }
  }, [img]);

  function sbmt(info) {
    const { title, link } = info;

    let data = {
      link: link,
      name: title,
      image: imgLink,
    };

    mutate(data);
  }

  let loader = fileLoading || (id !== undefined ? load : false) || loading;
  return {
    sbmt,
    setLocation,
    loader,
    img,
    setImg,
    form,
  };
}
