import { useTranslation } from "react-i18next";

export default function useJsons() {
  const { t } = useTranslation();
  const Province = [
    {
      regionid: 1703,
      value: 1703200,
      label: "Andijon viloyatining tumanlari",
    },
    {
      regionid: 1703,
      value: 1703400,
      label: "Andijon v-tining vil-t ahamiyatiga ega shaharlari",
    },
    {
      regionid: 1706,
      value: 1706200,
      label: "Buxoro viloyatining tumanlari",
    },
    {
      regionid: 1706,
      value: 1706400,
      label: "Buxoro viloyatining viloyat ahamiyatiga ega shahar",
    },
    {
      regionid: 1708,
      value: 1708200,
      label: "Jizzax viloyatining tumanlari",
    },
    {
      regionid: 1708,
      value: 1708400,
      label: "Jizzax viloyatining viloyat ahamiyatiga ega shahar",
    },
    {
      regionid: 1710,
      value: 1710200,
      label: "Qashqadaryo viloyatining tumanlari",
    },
    {
      regionid: 1703,
      value: 1703203,
      label: "Andijon tumani",
    },
    {
      regionid: 1703,
      value: 1703206,
      label: "Baliqchi tumani",
    },
    {
      regionid: 1703,
      value: 1703210,
      label: "Buloqboshi tumani",
    },
    {
      regionid: 1703,
      value: 1703214,
      label: "Izboskan tumani",
    },
    {
      regionid: 1703,
      value: 1703224,
      label: "Asaka tumani",
    },
    {
      regionid: 1703,
      value: 1703227,
      label: "Marxamat tumani",
    },
    {
      regionid: 1703,
      value: 1703230,
      label: "Shaxrixon tumani",
    },
    {
      regionid: 1703,
      value: 1703232,
      label: "Paxtaobod tumani",
    },
    {
      regionid: 1703,
      value: 1703401,
      label: "Andijon",
    },
    {
      regionid: 1706,
      value: 1706204,
      label: "Olot tumani",
    },
    {
      regionid: 1706,
      value: 1706207,
      label: "Buxoro tumani",
    },
    {
      regionid: 1706,
      value: 1706212,
      label: "Vobkent tumani",
    },
    {
      regionid: 1706,
      value: 1706219,
      label: "Kogon tumani",
    },
    {
      regionid: 1706,
      value: 1706232,
      label: "Qorovulbozor tumani",
    },
    {
      regionid: 1706,
      value: 1706240,
      label: "Peshku tumani",
    },
    {
      regionid: 1706,
      value: 1706242,
      label: "Romitan tumani",
    },
    {
      regionid: 1706,
      value: 1706246,
      label: "Jondor tumani",
    },
    {
      regionid: 1706,
      value: 1706258,
      label: "Shofirkon tumani",
    },
    {
      regionid: 1706,
      value: 1706401,
      label: "Buxoro shahar",
    },
    {
      regionid: 1706,
      value: 1706403,
      label: "Kogon",
    },
    {
      regionid: 1708,
      value: 1708201,
      label: "Arnasoy tumani",
    },
    {
      regionid: 1708,
      value: 1708204,
      label: "Baxmal tumani",
    },
    {
      regionid: 1708,
      value: 1708212,
      label: "Sharof Rashidov tumani",
    },
    {
      regionid: 1708,
      value: 1708218,
      label: "Zomin tumani",
    },
    {
      regionid: 1708,
      value: 1708220,
      label: "Zarbdor tumani",
    },
    {
      regionid: 1708,
      value: 1708228,
      label: "Paxtakor tumani",
    },
    {
      regionid: 1708,
      value: 1708235,
      label: "Forish tumani",
    },
    {
      regionid: 1708,
      value: 1708237,
      label: "Yangiobod tumani",
    },
    {
      regionid: 1708,
      value: 1708401,
      label: "Jizzax",
    },
    {
      regionid: 1710,
      value: 1710212,
      label: "Dehqonobod tumani",
    },
    {
      regionid: 1710,
      value: 1710220,
      label: "Qamashi tumani",
    },
    {
      regionid: 1710,
      value: 1710224,
      label: "Qarshi tumani",
    },
    {
      regionid: 1710,
      value: 1710229,
      label: "Koson tumani",
    },
    {
      regionid: 1710,
      value: 1710232,
      label: "Kitob tumani",
    },
    {
      regionid: 1710,
      value: 1710233,
      label: "Mirishkor tumani",
    },
    {
      regionid: 1710,
      value: 1710234,
      label: "Muborak tumani",
    },
    {
      regionid: 1710,
      value: 1710400,
      label: "Qashqadaryo viloyatining viloyat ahamiyatiga ega s",
    },
    {
      regionid: 1712,
      value: 1712200,
      label: "Navoiy viloyatining tumanlari",
    },
    {
      regionid: 1712,
      value: 1712400,
      label: "Navoiy viloyatining viloyat ahamiyatiga ega shahar",
    },
    {
      regionid: 1703,
      value: 1703217,
      label: "Ulug'nor tumani",
    },
    {
      regionid: 1714,
      value: 1714200,
      label: "Namangan viloyatining tumanlari",
    },
    {
      regionid: 1714,
      value: 1714400,
      label: "Namangan viloyatining viloyat ahamiyatiga ega shah",
    },
    {
      regionid: 1718,
      value: 1718400,
      label: "Samarqand viloyatining viloyat ahamiyatiga ega sha",
    },
    {
      regionid: 1722,
      value: 1722200,
      label: "Surxandaryo viloyatining tumanlari",
    },
    {
      regionid: 1710,
      value: 1710237,
      label: "Kasbi tumani",
    },
    {
      regionid: 1710,
      value: 1710242,
      label: "Chiroqchi tumani",
    },
    {
      regionid: 1710,
      value: 1710245,
      label: "Shahrisabz tumani",
    },
    {
      regionid: 1710,
      value: 1710401,
      label: "Qarshi",
    },
    {
      regionid: 1712,
      value: 1712211,
      label: "Konimex tumani",
    },
    {
      regionid: 1712,
      value: 1712216,
      label: "Qiziltepa tumani",
    },
    {
      regionid: 1712,
      value: 1712230,
      label: "Navbahor tumani",
    },
    {
      regionid: 1712,
      value: 1712234,
      label: "Karmana tumani",
    },
    {
      regionid: 1712,
      value: 1712238,
      label: "Nurota tumani",
    },
    {
      regionid: 1712,
      value: 1712248,
      label: "Uchquduq tumani",
    },
    {
      regionid: 1712,
      value: 1712251,
      label: "Xatirchi tumani",
    },
    {
      regionid: 1712,
      value: 1712401,
      label: "Navoiy",
    },
    {
      regionid: 1712,
      value: 1712408,
      label: "Zarafshon",
    },
    {
      regionid: 1714,
      value: 1714204,
      label: "Mingbuloq tumani",
    },
    {
      regionid: 1714,
      value: 1714207,
      label: "Kosonsoy tumani",
    },
    {
      regionid: 1714,
      value: 1714212,
      label: "Namangan tumani",
    },
    {
      regionid: 1714,
      value: 1714216,
      label: "Norin tumani",
    },
    {
      regionid: 1714,
      value: 1714219,
      label: "Pop tumani",
    },
    {
      regionid: 1714,
      value: 1714229,
      label: "Uychi tumani",
    },
    {
      regionid: 1714,
      value: 1714236,
      label: "Chortoq tumani",
    },
    {
      regionid: 1714,
      value: 1714237,
      label: "Chust tumani",
    },
    {
      regionid: 1714,
      value: 1714401,
      label: "Namangan",
    },
    {
      regionid: 1718,
      value: 1718203,
      label: "Oqdaryo tumani",
    },
    {
      regionid: 1718,
      value: 1718209,
      label: "Jomboy tumani",
    },
    {
      regionid: 1718,
      value: 1718212,
      label: "Ishtixon tumani",
    },
    {
      regionid: 1718,
      value: 1718218,
      label: "Narpay tumani",
    },
    {
      regionid: 1718,
      value: 1718224,
      label: "Payariq tumani",
    },
    {
      regionid: 1718,
      value: 1718230,
      label: "Paxtachi tumani",
    },
    {
      regionid: 1718,
      value: 1718233,
      label: "Samarqand tumani",
    },
    {
      regionid: 1718,
      value: 1718235,
      label: "Nurobod tumani",
    },
    {
      regionid: 1718,
      value: 1718236,
      label: "Urgut tumani",
    },
    {
      regionid: 1718,
      value: 1718238,
      label: "Tayloq tumani",
    },
    {
      regionid: 1718,
      value: 1718401,
      label: "Samarqand",
    },
    {
      regionid: 1722,
      value: 1722201,
      label: "Oltinsoy tumani",
    },
    {
      regionid: 1722,
      value: 1722202,
      label: "Angor tumani",
    },
    {
      regionid: 1722,
      value: 1722204,
      label: "Boysun tumani",
    },
    {
      regionid: 1722,
      value: 1722210,
      label: "Denov tumani",
    },
    {
      regionid: 1722,
      value: 1722400,
      label: "Surxondaryo viloyatining viloyat ahamiyatiga ega s",
    },
    {
      regionid: 1724,
      value: 1724200,
      label: "Sirdaryo viloyatining tumanlari",
    },
    {
      regionid: 1724,
      value: 1724400,
      label: "Sirdaryo viloyatining viloyat ahamiyatiga ega shah",
    },
    {
      regionid: 1726,
      value: 1726260,
      label: "Toshkent shahrining tumanlari",
    },
    {
      regionid: 1726,
      value: 1726262,
      label: "Uchtepa tumani",
    },
    {
      regionid: 1726,
      value: 1726264,
      label: "Bektemir tumani",
    },
    {
      regionid: 1726,
      value: 1726266,
      label: "Yunusobod tumani",
    },
    {
      regionid: 1726,
      value: 1726273,
      label: "Mirobod tumani",
    },
    {
      regionid: 1718,
      value: 1718200,
      label: "Samarqand viloyatining tumanlari",
    },
    {
      regionid: 1726,
      value: 1726277,
      label: "Shayxontoxur tumani",
    },
    {
      regionid: 1726,
      value: 1726280,
      label: "Olmazor tumani",
    },
    {
      regionid: 1726,
      value: 1726287,
      label: "Yakkasaroy tumani",
    },
    {
      regionid: 1726,
      value: 1726290,
      label: "Yashnobod tumani",
    },
    {
      regionid: 1726,
      value: 1726294,
      label: "Chilonzor tumani",
    },
    {
      regionid: 1727,
      value: 1727200,
      label: "Toshkent viloyatining tumanlari",
    },
    {
      regionid: 1727,
      value: 1727400,
      label: "Toshkent viloyatining viloyat ahamiyatiga ega shah",
    },
    {
      regionid: 1722,
      value: 1722215,
      label: "Qiziriq tumani",
    },
    {
      regionid: 1722,
      value: 1722217,
      label: "Sariosiyo tumani",
    },
    {
      regionid: 1722,
      value: 1722220,
      label: "Termiz tumani",
    },
    {
      regionid: 1722,
      value: 1722221,
      label: "Uzun tumani",
    },
    {
      regionid: 1722,
      value: 1722223,
      label: "Sherobod tumani",
    },
    {
      regionid: 1722,
      value: 1722401,
      label: "Termiz",
    },
    {
      regionid: 1724,
      value: 1724206,
      label: "Oqoltin tumani",
    },
    {
      regionid: 1724,
      value: 1724212,
      label: "Boyovut tumani",
    },
    {
      regionid: 1724,
      value: 1724220,
      label: "Guliston tumani",
    },
    {
      regionid: 1724,
      value: 1724226,
      label: "Sardoba tumani",
    },
    {
      regionid: 1724,
      value: 1724228,
      label: "Mirzaobod tumani",
    },
    {
      regionid: 1724,
      value: 1724231,
      label: "Sirdaryo tumani",
    },
    {
      regionid: 1724,
      value: 1724401,
      label: "Guliston",
    },
    {
      regionid: 1724,
      value: 1724410,
      label: "Shirin",
    },
    {
      regionid: 1724,
      value: 1724413,
      label: "Yangiyer",
    },
    {
      regionid: 1727,
      value: 1727212,
      label: "Ohangaron tumani",
    },
    {
      regionid: 1727,
      value: 1727220,
      label: "Bekobod tumani",
    },
    {
      regionid: 1727,
      value: 1727233,
      label: "Qiyichirchiq tumani",
    },
    {
      regionid: 1727,
      value: 1727237,
      label: "Zangiota tumani",
    },
    {
      regionid: 1727,
      value: 1727248,
      label: "Qibray tumani",
    },
    {
      regionid: 1727,
      value: 1727249,
      label: "Parkent tumani",
    },
    {
      regionid: 1727,
      value: 1727250,
      label: "Pskent tumani",
    },
    {
      regionid: 1727,
      value: 1727256,
      label: "Chinoz tumani",
    },
    {
      regionid: 1727,
      value: 1727404,
      label: "Olmaliq",
    },
    {
      regionid: 1727,
      value: 1727407,
      label: "Angren",
    },
    {
      regionid: 1727,
      value: 1727413,
      label: "Bekobod",
    },
    {
      regionid: 1727,
      value: 1727419,
      label: "Chirchiq",
    },
    {
      regionid: 1730,
      value: 1730203,
      label: "Oltiariq tumani",
    },
    {
      regionid: 1730,
      value: 1730212,
      label: "Buvayda tumani",
    },
    {
      regionid: 1730,
      value: 1730215,
      label: "Beshariq tumani",
    },
    {
      regionid: 1733,
      value: 1733200,
      label: "Xorazm viloyatining tumanlari",
    },
    {
      regionid: 1733,
      value: 1733400,
      label: "Xorazm viloyatining viloyat ahamiyatiga ega shahar",
    },
    {
      regionid: 1735,
      value: 1735204,
      label: "Amudaryo tumani",
    },
    {
      regionid: 1735,
      value: 1735207,
      label: "Beruniy tumani",
    },
    {
      regionid: 1735,
      value: 1735212,
      label: "Kegeyli tumani",
    },
    {
      regionid: 1724,
      value: 1724235,
      label: "Xovos tumani",
    },
    {
      regionid: 1730,
      value: 1730209,
      label: "Bog'dod tumani",
    },
    {
      regionid: 1735,
      value: 1735218,
      label: "Qanliko'l tumani",
    },
    {
      regionid: 1735,
      value: 1735200,
      label: "Qoraqalpog'iston Respublikasining tumanlari",
    },
    {
      regionid: 1727,
      value: 1727228,
      label: "Bo'ka tumani",
    },
    {
      regionid: 1722,
      value: 1722226,
      label: "Sho'rchi tumani",
    },
    {
      regionid: 1735,
      value: 1735225,
      label: "Nukus tumani",
    },
    {
      regionid: 1735,
      value: 1735240,
      label: "Chimboy tumani",
    },
    {
      regionid: 1735,
      value: 1735243,
      label: "Shumanay tumani",
    },
    {
      regionid: 1735,
      value: 1735250,
      label: "Ellikkala tumani",
    },
    {
      regionid: 1708,
      value: 1708225,
      label: "Zafarobod tumani",
    },
    {
      regionid: 1710,
      value: 1710235,
      label: "Nishon tumani",
    },
    {
      regionid: 1712,
      value: 1712244,
      label: "Tomdi tumani",
    },
    {
      regionid: 1722,
      value: 1722207,
      label: "Muzrabot tumani",
    },
    {
      regionid: 1724,
      value: 1724216,
      label: "Sayxunobod tumani",
    },
    {
      regionid: 1727,
      value: 1727239,
      label: "Yuqorichirchiq tumani",
    },
    {
      regionid: 1730,
      value: 1730224,
      label: "Rishton tumani",
    },
    {
      regionid: 1730,
      value: 1730227,
      label: "Toshloq tumani",
    },
    {
      regionid: 1730,
      value: 1730238,
      label: "Furqat tumani",
    },
    {
      regionid: 1730,
      value: 1730242,
      label: "Yozyovon tumani",
    },
    {
      regionid: 1730,
      value: 1730408,
      label: "Quvasoy",
    },
    {
      regionid: 1733,
      value: 1733204,
      label: "Bog`ot tumani",
    },
    {
      regionid: 1733,
      value: 1733208,
      label: "Gurlan tumani",
    },
    {
      regionid: 1733,
      value: 1733217,
      label: "Urganch tumani",
    },
    {
      regionid: 1733,
      value: 1733220,
      label: "Xazorasp tumani",
    },
    {
      regionid: 1733,
      value: 1733223,
      label: "Xonqa tumani",
    },
    {
      regionid: 1733,
      value: 1733226,
      label: "Xiva tumani",
    },
    {
      regionid: 1733,
      value: 1733230,
      label: "Shovot tumani",
    },
    {
      regionid: 1733,
      value: 1733233,
      label: "Yangiariq tumani",
    },
    {
      regionid: 1733,
      value: 1733236,
      label: "Yangibozor tumani",
    },
    {
      regionid: 1733,
      value: 1733401,
      label: "Urganch",
    },
    {
      regionid: 1730,
      value: 1730218,
      label: "Quva tumani",
    },
    {
      regionid: 1703,
      value: 1703408,
      label: "Xonobod",
    },
    {
      regionid: 1735,
      value: 1735401,
      label: "Nukus",
    },
    {
      regionid: 1735,
      value: 1735209,
      label: "Bo'zatov tumani",
    },
    {
      regionid: 1733,
      value: 1733221,
      label: "Tuproqqal'a tumani",
    },
    {
      regionid: 1726,
      value: 1726292,
      label: "Yangihayot tumani",
    },
    {
      regionid: 1712,
      value: 1712412,
      label: "G'ozg'on shahar ",
    },
    {
      regionid: 1727,
      value: 1727415,
      label: "Ohangaron shahar",
    },
    {
      regionid: 1727,
      value: 1727265,
      label: "Toshkent tumani",
    },
    {
      regionid: 1735,
      value: 1735228,
      label: "Taxiatosh",
    },
    {
      regionid: 1710,
      value: 1710405,
      label: "Shahrisabz shahar",
    },
    {
      regionid: 1727,
      value: 1727424,
      label: "Yangiyo'l shahar",
    },
    {
      regionid: 1727,
      value: 1727401,
      label: "Nurafshon shahar",
    },
    {
      regionid: 1733,
      value: 1733406,
      label: "Xiva shahar",
    },
    {
      regionid: 1703,
      value: 1703211,
      label: "Jalaquduq tumani",
    },
    {
      regionid: 1730,
      value: 1730230,
      label: "O'zbekiston tumani",
    },
    {
      regionid: 1722,
      value: 1722203,
      label: "Bandixon tumani",
    },
    {
      regionid: 1730,
      value: 1730226,
      label: "So'x tumani",
    },
    {
      regionid: 1730,
      value: 1730236,
      label: "Dang'ara tumani",
    },
    {
      regionid: 1730,
      value: 1730405,
      label: "Qo'qon",
    },
    {
      regionid: 1730,
      value: 1730401,
      label: "Farg'ona",
    },
    {
      regionid: 1735,
      value: 1735233,
      label: "To'rtko'l tumani",
    },
    {
      regionid: 1730,
      value: 1730412,
      label: "Marg'ilon",
    },
    {
      regionid: 1722,
      value: 1722212,
      label: "Jarqo'rg'on tumani",
    },
    {
      regionid: 1733,
      value: 1733212,
      label: "Qo'shko'pir tumani",
    },
    {
      regionid: 1730,
      value: 1730400,
      label: "Farg'ona viloyatining viloyat ahamiyatiga ega shah",
    },
    {
      regionid: 1730,
      value: 1730200,
      label: "Farg'ona viloyatining tumanlari",
    },
    {
      regionid: 1730,
      value: 1730221,
      label: "Uchko'prik tumani",
    },
    {
      regionid: 1730,
      value: 1730233,
      label: "Farg'ona tumani",
    },
    {
      regionid: 1730,
      value: 1730206,
      label: "Qo'shtepa tumani",
    },
    {
      regionid: 1718,
      value: 1718216,
      label: "Qo'shrabot tumani",
    },
    {
      regionid: 1710,
      value: 1710250,
      label: "Yakkabog' tumani",
    },
    {
      regionid: 1718,
      value: 1718206,
      label: "Bulung'ur tumani",
    },
    {
      regionid: 1714,
      value: 1714234,
      label: "Uchqo'rg'on tumani",
    },
    {
      regionid: 1714,
      value: 1714224,
      label: "To'raqo'rg'on tumani",
    },
    {
      regionid: 1735,
      value: 1735230,
      label: "Taxtako'pir tumani",
    },
    {
      regionid: 1735,
      value: 1735236,
      label: "Xo'jayli tumani",
    },
    {
      regionid: 1710,
      value: 1710240,
      label: "Ko'kdala tumani",
    },
    {
      regionid: 1727,
      value: 1727206,
      label: "Oqqo'rg'on tumani",
    },
    {
      regionid: 1727,
      value: 1727224,
      label: "Bo'stonliq tumani",
    },
    {
      regionid: 1726,
      value: 1726283,
      label: "Sirg'ali tumani",
    },
    {
      regionid: 1726,
      value: 1726269,
      label: "Mirzo Ulug'bek tumani",
    },
    {
      regionid: 1718,
      value: 1718227,
      label: "Pastdarg'om tumani",
    },
    {
      regionid: 1735,
      value: 1735215,
      label: "Qo'ng'irot tumani",
    },
    {
      regionid: 1714,
      value: 1714242,
      label: "Yangiqo'rg'on tumani",
    },
    {
      regionid: 1735,
      value: 1735211,
      label: "Qorao'zak tumani",
    },
    {
      regionid: 1708,
      value: 1708223,
      label: "Mirzacho'l tumani",
    },
    {
      regionid: 1703,
      value: 1703202,
      label: "Oltinko'l tumani",
    },
    {
      regionid: 1706,
      value: 1706215,
      label: "G'ijduvon tumani",
    },
    {
      regionid: 1708,
      value: 1708215,
      label: "Do'stlik tumani",
    },
    {
      regionid: 1718,
      value: 1718406,
      label: "Kattaqo'rg'on",
    },
    {
      regionid: 1727,
      value: 1727253,
      label: "O'rtachirchiq tumani",
    },
    {
      regionid: 1703,
      value: 1703220,
      label: "Qo'rg'ontepa tumani",
    },
    {
      regionid: 1703,
      value: 1703236,
      label: "Xo'jaobod tumani",
    },
    {
      regionid: 1706,
      value: 1706230,
      label: "Qorako'l tumani",
    },
    {
      regionid: 1722,
      value: 1722214,
      label: "Qumqo'rg'on tumani",
    },
    {
      regionid: 1703,
      value: 1703209,
      label: "Bo'z tumani",
    },
    {
      regionid: 1727,
      value: 1727259,
      label: "Yangiyo'l tumani",
    },
    {
      regionid: 1718,
      value: 1718215,
      label: "Kattaqo'rg'on tumani",
    },
    {
      regionid: 1708,
      value: 1708209,
      label: "G'allaorol tumani",
    },
    {
      regionid: 1710,
      value: 1710207,
      label: "G'uzor tumani",
    },
    {
      regionid: 1735,
      value: 1735222,
      label: "Mo'ynoq tumani",
    },
    {
      regionid: 1735,
      value: 1735400,
      label: "Qoraqalpog'iston Respublikasining ahamiyatiga ega shah",
    },
  ];

  const Regions = [
    {
      value: 1726,
      label: "Toshkent shahri",
    },
    {
      value: 1735,
      label: "Qoraqalpog`iston Respublikasi",
    },
    {
      value: 1703,
      label: "Andijon viloyati",
    },
    {
      value: 1706,
      label: "Buxoro viloyati",
    },
    {
      value: 1708,
      label: "Jizzax viloyati",
    },
    {
      value: 1710,
      label: "Qashqadaryo viloyati",
    },
    {
      value: 1712,
      label: "Navoiy viloyati",
    },
    {
      value: 1714,
      label: "Namangan viloyati",
    },
    {
      value: 1718,
      label: "Samarqand viloyati",
    },
    {
      value: 1722,
      label: "Surxandaryo viloyati",
    },
    {
      value: 1724,
      label: "Sirdaryo viloyati",
    },
    {
      value: 1727,
      label: "Toshkent viloyati",
    },
    {
      value: 1730,
      label: "Farg`ona viloyati",
    },
    {
      value: 1733,
      label: "Xorazm viloyati",
    },
  ];

  return { Province, Regions };
}

export const ApiSiteKey = {
  getAdmins: "get-admins",
  getInvestments: "get-investments",
  getPartnerss: "get-partners",
  getInvestmentsById: "get-investment-by-id",
  getAnalytics: "get-analytics",
  getAllAnalytics: "get-all-analytics",
  getAdmin: "get-admin",
  getTarif: "get-tarif",
  getCatalogs: "get-catalogs",
  getUserPayment: "get-user=payment",
  geFaqType: "get-faq-type",
  geFaq: "get-faq",
  geFaqById: "get-faq-byId",
  getTarifs: "get-tarifs",
  getSubCatalogs: "get-subcatalogs",
  getSubCatalogsById: "get-subcatalogs-by-id",
  getApplications: "get-application",
  getUncheckedPosts: "get-uncheckedPosts",
  getActivePosts: "get-activePosts",
  getRejectedkedPosts: "get-rejectedPosts",
  getUncheckedPostById: "get-uncheckedPostsById",
};

export const StatusInvestment = [
  { value: 0, label: "Строительство не началось" },
  { value: 1, label: "Он находится в стадии строительства" },
  { value: 2, label: "Готовый" },
];

export const Districts = {
  uz: [
    {
      regionid: 1703,
      value: 1703200,
      label: "Andijon viloyatining tumanlari",
    },
    {
      regionid: 1703,
      value: 1703400,
      label: "Andijon v-tining vil-t ahamiyatiga ega shaharlari",
    },
    {
      regionid: 1706,
      value: 1706200,
      label: "Buxoro viloyatining tumanlari",
    },
    {
      regionid: 1706,
      value: 1706400,
      label: "Buxoro viloyatining viloyat ahamiyatiga ega shahar",
    },
    {
      regionid: 1708,
      value: 1708200,
      label: "Jizzax viloyatining tumanlari",
    },
    {
      regionid: 1708,
      value: 1708400,
      label: "Jizzax viloyatining viloyat ahamiyatiga ega shahar",
    },
    {
      regionid: 1710,
      value: 1710200,
      label: "Qashqadaryo viloyatining tumanlari",
    },
    {
      regionid: 1703,
      value: 1703203,
      label: "Andijon tumani",
    },
    {
      regionid: 1703,
      value: 1703206,
      label: "Baliqchi tumani",
    },
    {
      regionid: 1703,
      value: 1703210,
      label: "Buloqboshi tumani",
    },
    {
      regionid: 1703,
      value: 1703214,
      label: "Izboskan tumani",
    },
    {
      regionid: 1703,
      value: 1703224,
      label: "Asaka tumani",
    },
    {
      regionid: 1703,
      value: 1703227,
      label: "Marxamat tumani",
    },
    {
      regionid: 1703,
      value: 1703230,
      label: "Shaxrixon tumani",
    },
    {
      regionid: 1703,
      value: 1703232,
      label: "Paxtaobod tumani",
    },
    {
      regionid: 1703,
      value: 1703401,
      label: "Andijon",
    },
    {
      regionid: 1706,
      value: 1706204,
      label: "Olot tumani",
    },
    {
      regionid: 1706,
      value: 1706207,
      label: "Buxoro tumani",
    },
    {
      regionid: 1706,
      value: 1706212,
      label: "Vobkent tumani",
    },
    {
      regionid: 1706,
      value: 1706219,
      label: "Kogon tumani",
    },
    {
      regionid: 1706,
      value: 1706232,
      label: "Qorovulbozor tumani",
    },
    {
      regionid: 1706,
      value: 1706240,
      label: "Peshku tumani",
    },
    {
      regionid: 1706,
      value: 1706242,
      label: "Romitan tumani",
    },
    {
      regionid: 1706,
      value: 1706246,
      label: "Jondor tumani",
    },
    {
      regionid: 1706,
      value: 1706258,
      label: "Shofirkon tumani",
    },
    {
      regionid: 1706,
      value: 1706401,
      label: "Buxoro shahar",
    },
    {
      regionid: 1706,
      value: 1706403,
      label: "Kogon",
    },
    {
      regionid: 1708,
      value: 1708201,
      label: "Arnasoy tumani",
    },
    {
      regionid: 1708,
      value: 1708204,
      label: "Baxmal tumani",
    },
    {
      regionid: 1708,
      value: 1708212,
      label: "Sharof Rashidov tumani",
    },
    {
      regionid: 1708,
      value: 1708218,
      label: "Zomin tumani",
    },
    {
      regionid: 1708,
      value: 1708220,
      label: "Zarbdor tumani",
    },
    {
      regionid: 1708,
      value: 1708228,
      label: "Paxtakor tumani",
    },
    {
      regionid: 1708,
      value: 1708235,
      label: "Forish tumani",
    },
    {
      regionid: 1708,
      value: 1708237,
      label: "Yangiobod tumani",
    },
    {
      regionid: 1708,
      value: 1708401,
      label: "Jizzax",
    },
    {
      regionid: 1710,
      value: 1710212,
      label: "Dehqonobod tumani",
    },
    {
      regionid: 1710,
      value: 1710220,
      label: "Qamashi tumani",
    },
    {
      regionid: 1710,
      value: 1710224,
      label: "Qarshi tumani",
    },
    {
      regionid: 1710,
      value: 1710229,
      label: "Koson tumani",
    },
    {
      regionid: 1710,
      value: 1710232,
      label: "Kitob tumani",
    },
    {
      regionid: 1710,
      value: 1710233,
      label: "Mirishkor tumani",
    },
    {
      regionid: 1710,
      value: 1710234,
      label: "Muborak tumani",
    },
    {
      regionid: 1710,
      value: 1710400,
      label: "Qashqadaryo viloyatining viloyat ahamiyatiga ega s",
    },
    {
      regionid: 1712,
      value: 1712200,
      label: "Navoiy viloyatining tumanlari",
    },
    {
      regionid: 1712,
      value: 1712400,
      label: "Navoiy viloyatining viloyat ahamiyatiga ega shahar",
    },
    {
      regionid: 1703,
      value: 1703217,
      label: "Ulug'nor tumani",
    },
    {
      regionid: 1714,
      value: 1714200,
      label: "Namangan viloyatining tumanlari",
    },
    {
      regionid: 1714,
      value: 1714400,
      label: "Namangan viloyatining viloyat ahamiyatiga ega shah",
    },
    {
      regionid: 1718,
      value: 1718400,
      label: "Samarqand viloyatining viloyat ahamiyatiga ega sha",
    },
    {
      regionid: 1722,
      value: 1722200,
      label: "Surxandaryo viloyatining tumanlari",
    },
    {
      regionid: 1710,
      value: 1710237,
      label: "Kasbi tumani",
    },
    {
      regionid: 1710,
      value: 1710242,
      label: "Chiroqchi tumani",
    },
    {
      regionid: 1710,
      value: 1710245,
      label: "Shahrisabz tumani",
    },
    {
      regionid: 1710,
      value: 1710401,
      label: "Qarshi",
    },
    {
      regionid: 1712,
      value: 1712211,
      label: "Konimex tumani",
    },
    {
      regionid: 1712,
      value: 1712216,
      label: "Qiziltepa tumani",
    },
    {
      regionid: 1712,
      value: 1712230,
      label: "Navbahor tumani",
    },
    {
      regionid: 1712,
      value: 1712234,
      label: "Karmana tumani",
    },
    {
      regionid: 1712,
      value: 1712238,
      label: "Nurota tumani",
    },
    {
      regionid: 1712,
      value: 1712248,
      label: "Uchquduq tumani",
    },
    {
      regionid: 1712,
      value: 1712251,
      label: "Xatirchi tumani",
    },
    {
      regionid: 1712,
      value: 1712401,
      label: "Navoiy",
    },
    {
      regionid: 1712,
      value: 1712408,
      label: "Zarafshon",
    },
    {
      regionid: 1714,
      value: 1714204,
      label: "Mingbuloq tumani",
    },
    {
      regionid: 1714,
      value: 1714207,
      label: "Kosonsoy tumani",
    },
    {
      regionid: 1714,
      value: 1714212,
      label: "Namangan tumani",
    },
    {
      regionid: 1714,
      value: 1714216,
      label: "Norin tumani",
    },
    {
      regionid: 1714,
      value: 1714219,
      label: "Pop tumani",
    },
    {
      regionid: 1714,
      value: 1714229,
      label: "Uychi tumani",
    },
    {
      regionid: 1714,
      value: 1714236,
      label: "Chortoq tumani",
    },
    {
      regionid: 1714,
      value: 1714237,
      label: "Chust tumani",
    },
    {
      regionid: 1714,
      value: 1714401,
      label: "Namangan",
    },
    {
      regionid: 1718,
      value: 1718203,
      label: "Oqdaryo tumani",
    },
    {
      regionid: 1718,
      value: 1718209,
      label: "Jomboy tumani",
    },
    {
      regionid: 1718,
      value: 1718212,
      label: "Ishtixon tumani",
    },
    {
      regionid: 1718,
      value: 1718218,
      label: "Narpay tumani",
    },
    {
      regionid: 1718,
      value: 1718224,
      label: "Payariq tumani",
    },
    {
      regionid: 1718,
      value: 1718230,
      label: "Paxtachi tumani",
    },
    {
      regionid: 1718,
      value: 1718233,
      label: "Samarqand tumani",
    },
    {
      regionid: 1718,
      value: 1718235,
      label: "Nurobod tumani",
    },
    {
      regionid: 1718,
      value: 1718236,
      label: "Urgut tumani",
    },
    {
      regionid: 1718,
      value: 1718238,
      label: "Tayloq tumani",
    },
    {
      regionid: 1718,
      value: 1718401,
      label: "Samarqand",
    },
    {
      regionid: 1722,
      value: 1722201,
      label: "Oltinsoy tumani",
    },
    {
      regionid: 1722,
      value: 1722202,
      label: "Angor tumani",
    },
    {
      regionid: 1722,
      value: 1722204,
      label: "Boysun tumani",
    },
    {
      regionid: 1722,
      value: 1722210,
      label: "Denov tumani",
    },
    {
      regionid: 1722,
      value: 1722400,
      label: "Surxondaryo viloyatining viloyat ahamiyatiga ega s",
    },
    {
      regionid: 1724,
      value: 1724200,
      label: "Sirdaryo viloyatining tumanlari",
    },
    {
      regionid: 1724,
      value: 1724400,
      label: "Sirdaryo viloyatining viloyat ahamiyatiga ega shah",
    },
    {
      regionid: 1726,
      value: 1726260,
      label: "Toshkent shahrining tumanlari",
    },
    {
      regionid: 1726,
      value: 1726262,
      label: "Uchtepa tumani",
    },
    {
      regionid: 1726,
      value: 1726264,
      label: "Bektemir tumani",
    },
    {
      regionid: 1726,
      value: 1726266,
      label: "Yunusobod tumani",
    },
    {
      regionid: 1726,
      value: 1726273,
      label: "Mirobod tumani",
    },
    {
      regionid: 1718,
      value: 1718200,
      label: "Samarqand viloyatining tumanlari",
    },
    {
      regionid: 1726,
      value: 1726277,
      label: "Shayxontoxur tumani",
    },
    {
      regionid: 1726,
      value: 1726280,
      label: "Olmazor tumani",
    },
    {
      regionid: 1726,
      value: 1726287,
      label: "Yakkasaroy tumani",
    },
    {
      regionid: 1726,
      value: 1726290,
      label: "Yashnobod tumani",
    },
    {
      regionid: 1726,
      value: 1726294,
      label: "Chilonzor tumani",
    },
    {
      regionid: 1727,
      value: 1727200,
      label: "Toshkent viloyatining tumanlari",
    },
    {
      regionid: 1727,
      value: 1727400,
      label: "Toshkent viloyatining viloyat ahamiyatiga ega shah",
    },
    {
      regionid: 1722,
      value: 1722215,
      label: "Qiziriq tumani",
    },
    {
      regionid: 1722,
      value: 1722217,
      label: "Sariosiyo tumani",
    },
    {
      regionid: 1722,
      value: 1722220,
      label: "Termiz tumani",
    },
    {
      regionid: 1722,
      value: 1722221,
      label: "Uzun tumani",
    },
    {
      regionid: 1722,
      value: 1722223,
      label: "Sherobod tumani",
    },
    {
      regionid: 1722,
      value: 1722401,
      label: "Termiz",
    },
    {
      regionid: 1724,
      value: 1724206,
      label: "Oqoltin tumani",
    },
    {
      regionid: 1724,
      value: 1724212,
      label: "Boyovut tumani",
    },
    {
      regionid: 1724,
      value: 1724220,
      label: "Guliston tumani",
    },
    {
      regionid: 1724,
      value: 1724226,
      label: "Sardoba tumani",
    },
    {
      regionid: 1724,
      value: 1724228,
      label: "Mirzaobod tumani",
    },
    {
      regionid: 1724,
      value: 1724231,
      label: "Sirdaryo tumani",
    },
    {
      regionid: 1724,
      value: 1724401,
      label: "Guliston",
    },
    {
      regionid: 1724,
      value: 1724410,
      label: "Shirin",
    },
    {
      regionid: 1724,
      value: 1724413,
      label: "Yangiyer",
    },
    {
      regionid: 1727,
      value: 1727212,
      label: "Ohangaron tumani",
    },
    {
      regionid: 1727,
      value: 1727220,
      label: "Bekobod tumani",
    },
    {
      regionid: 1727,
      value: 1727233,
      label: "Qiyichirchiq tumani",
    },
    {
      regionid: 1727,
      value: 1727237,
      label: "Zangiota tumani",
    },
    {
      regionid: 1727,
      value: 1727248,
      label: "Qibray tumani",
    },
    {
      regionid: 1727,
      value: 1727249,
      label: "Parkent tumani",
    },
    {
      regionid: 1727,
      value: 1727250,
      label: "Pskent tumani",
    },
    {
      regionid: 1727,
      value: 1727256,
      label: "Chinoz tumani",
    },
    {
      regionid: 1727,
      value: 1727404,
      label: "Olmaliq",
    },
    {
      regionid: 1727,
      value: 1727407,
      label: "Angren",
    },
    {
      regionid: 1727,
      value: 1727413,
      label: "Bekobod",
    },
    {
      regionid: 1727,
      value: 1727419,
      label: "Chirchiq",
    },
    {
      regionid: 1730,
      value: 1730203,
      label: "Oltiariq tumani",
    },
    {
      regionid: 1730,
      value: 1730212,
      label: "Buvayda tumani",
    },
    {
      regionid: 1730,
      value: 1730215,
      label: "Beshariq tumani",
    },
    {
      regionid: 1733,
      value: 1733200,
      label: "Xorazm viloyatining tumanlari",
    },
    {
      regionid: 1733,
      value: 1733400,
      label: "Xorazm viloyatining viloyat ahamiyatiga ega shahar",
    },
    {
      regionid: 1735,
      value: 1735204,
      label: "Amudaryo tumani",
    },
    {
      regionid: 1735,
      value: 1735207,
      label: "Beruniy tumani",
    },
    {
      regionid: 1735,
      value: 1735212,
      label: "Kegeyli tumani",
    },
    {
      regionid: 1724,
      value: 1724235,
      label: "Xovos tumani",
    },
    {
      regionid: 1730,
      value: 1730209,
      label: "Bog'dod tumani",
    },
    {
      regionid: 1735,
      value: 1735218,
      label: "Qanliko'l tumani",
    },
    {
      regionid: 1735,
      value: 1735200,
      label: "Qoraqalpog'iston Respublikasining tumanlari",
    },
    {
      regionid: 1727,
      value: 1727228,
      label: "Bo'ka tumani",
    },
    {
      regionid: 1722,
      value: 1722226,
      label: "Sho'rchi tumani",
    },
    {
      regionid: 1735,
      value: 1735225,
      label: "Nukus tumani",
    },
    {
      regionid: 1735,
      value: 1735240,
      label: "Chimboy tumani",
    },
    {
      regionid: 1735,
      value: 1735243,
      label: "Shumanay tumani",
    },
    {
      regionid: 1735,
      value: 1735250,
      label: "Ellikkala tumani",
    },
    {
      regionid: 1708,
      value: 1708225,
      label: "Zafarobod tumani",
    },
    {
      regionid: 1710,
      value: 1710235,
      label: "Nishon tumani",
    },
    {
      regionid: 1712,
      value: 1712244,
      label: "Tomdi tumani",
    },
    {
      regionid: 1722,
      value: 1722207,
      label: "Muzrabot tumani",
    },
    {
      regionid: 1724,
      value: 1724216,
      label: "Sayxunobod tumani",
    },
    {
      regionid: 1727,
      value: 1727239,
      label: "Yuqorichirchiq tumani",
    },
    {
      regionid: 1730,
      value: 1730224,
      label: "Rishton tumani",
    },
    {
      regionid: 1730,
      value: 1730227,
      label: "Toshloq tumani",
    },
    {
      regionid: 1730,
      value: 1730238,
      label: "Furqat tumani",
    },
    {
      regionid: 1730,
      value: 1730242,
      label: "Yozyovon tumani",
    },
    {
      regionid: 1730,
      value: 1730408,
      label: "Quvasoy",
    },
    {
      regionid: 1733,
      value: 1733204,
      label: "Bog`ot tumani",
    },
    {
      regionid: 1733,
      value: 1733208,
      label: "Gurlan tumani",
    },
    {
      regionid: 1733,
      value: 1733217,
      label: "Urganch tumani",
    },
    {
      regionid: 1733,
      value: 1733220,
      label: "Xazorasp tumani",
    },
    {
      regionid: 1733,
      value: 1733223,
      label: "Xonqa tumani",
    },
    {
      regionid: 1733,
      value: 1733226,
      label: "Xiva tumani",
    },
    {
      regionid: 1733,
      value: 1733230,
      label: "Shovot tumani",
    },
    {
      regionid: 1733,
      value: 1733233,
      label: "Yangiariq tumani",
    },
    {
      regionid: 1733,
      value: 1733236,
      label: "Yangibozor tumani",
    },
    {
      regionid: 1733,
      value: 1733401,
      label: "Urganch",
    },
    {
      regionid: 1730,
      value: 1730218,
      label: "Quva tumani",
    },
    {
      regionid: 1703,
      value: 1703408,
      label: "Xonobod",
    },
    {
      regionid: 1735,
      value: 1735401,
      label: "Nukus",
    },
    {
      regionid: 1735,
      value: 1735209,
      label: "Bo'zatov tumani",
    },
    {
      regionid: 1733,
      value: 1733221,
      label: "Tuproqqal'a tumani",
    },
    {
      regionid: 1726,
      value: 1726292,
      label: "Yangihayot tumani",
    },
    {
      regionid: 1712,
      value: 1712412,
      label: "G'ozg'on shahar ",
    },
    {
      regionid: 1727,
      value: 1727415,
      label: "Ohangaron shahar",
    },
    {
      regionid: 1727,
      value: 1727265,
      label: "Toshkent tumani",
    },
    {
      regionid: 1735,
      value: 1735228,
      label: "Taxiatosh",
    },
    {
      regionid: 1710,
      value: 1710405,
      label: "Shahrisabz shahar",
    },
    {
      regionid: 1727,
      value: 1727424,
      label: "Yangiyo'l shahar",
    },
    {
      regionid: 1727,
      value: 1727401,
      label: "Nurafshon shahar",
    },
    {
      regionid: 1733,
      value: 1733406,
      label: "Xiva shahar",
    },
    {
      regionid: 1703,
      value: 1703211,
      label: "Jalaquduq tumani",
    },
    {
      regionid: 1730,
      value: 1730230,
      label: "O'zbekiston tumani",
    },
    {
      regionid: 1722,
      value: 1722203,
      label: "Bandixon tumani",
    },
    {
      regionid: 1730,
      value: 1730226,
      label: "So'x tumani",
    },
    {
      regionid: 1730,
      value: 1730236,
      label: "Dang'ara tumani",
    },
    {
      regionid: 1730,
      value: 1730405,
      label: "Qo'qon",
    },
    {
      regionid: 1730,
      value: 1730401,
      label: "Farg'ona",
    },
    {
      regionid: 1735,
      value: 1735233,
      label: "To'rtko'l tumani",
    },
    {
      regionid: 1730,
      value: 1730412,
      label: "Marg'ilon",
    },
    {
      regionid: 1722,
      value: 1722212,
      label: "Jarqo'rg'on tumani",
    },
    {
      regionid: 1733,
      value: 1733212,
      label: "Qo'shko'pir tumani",
    },
    {
      regionid: 1730,
      value: 1730400,
      label: "Farg'ona viloyatining viloyat ahamiyatiga ega shah",
    },
    {
      regionid: 1730,
      value: 1730200,
      label: "Farg'ona viloyatining tumanlari",
    },
    {
      regionid: 1730,
      value: 1730221,
      label: "Uchko'prik tumani",
    },
    {
      regionid: 1730,
      value: 1730233,
      label: "Farg'ona tumani",
    },
    {
      regionid: 1730,
      value: 1730206,
      label: "Qo'shtepa tumani",
    },
    {
      regionid: 1718,
      value: 1718216,
      label: "Qo'shrabot tumani",
    },
    {
      regionid: 1710,
      value: 1710250,
      label: "Yakkabog' tumani",
    },
    {
      regionid: 1718,
      value: 1718206,
      label: "Bulung'ur tumani",
    },
    {
      regionid: 1714,
      value: 1714234,
      label: "Uchqo'rg'on tumani",
    },
    {
      regionid: 1714,
      value: 1714224,
      label: "To'raqo'rg'on tumani",
    },
    {
      regionid: 1735,
      value: 1735230,
      label: "Taxtako'pir tumani",
    },
    {
      regionid: 1735,
      value: 1735236,
      label: "Xo'jayli tumani",
    },
    {
      regionid: 1710,
      value: 1710240,
      label: "Ko'kdala tumani",
    },
    {
      regionid: 1727,
      value: 1727206,
      label: "Oqqo'rg'on tumani",
    },
    {
      regionid: 1727,
      value: 1727224,
      label: "Bo'stonliq tumani",
    },
    {
      regionid: 1726,
      value: 1726283,
      label: "Sirg'ali tumani",
    },
    {
      regionid: 1726,
      value: 1726269,
      label: "Mirzo Ulug'bek tumani",
    },
    {
      regionid: 1718,
      value: 1718227,
      label: "Pastdarg'om tumani",
    },
    {
      regionid: 1735,
      value: 1735215,
      label: "Qo'ng'irot tumani",
    },
    {
      regionid: 1714,
      value: 1714242,
      label: "Yangiqo'rg'on tumani",
    },
    {
      regionid: 1735,
      value: 1735211,
      label: "Qorao'zak tumani",
    },
    {
      regionid: 1708,
      value: 1708223,
      label: "Mirzacho'l tumani",
    },
    {
      regionid: 1703,
      value: 1703202,
      label: "Oltinko'l tumani",
    },
    {
      regionid: 1706,
      value: 1706215,
      label: "G'ijduvon tumani",
    },
    {
      regionid: 1708,
      value: 1708215,
      label: "Do'stlik tumani",
    },
    {
      regionid: 1718,
      value: 1718406,
      label: "Kattaqo'rg'on",
    },
    {
      regionid: 1727,
      value: 1727253,
      label: "O'rtachirchiq tumani",
    },
    {
      regionid: 1703,
      value: 1703220,
      label: "Qo'rg'ontepa tumani",
    },
    {
      regionid: 1703,
      value: 1703236,
      label: "Xo'jaobod tumani",
    },
    {
      regionid: 1706,
      value: 1706230,
      label: "Qorako'l tumani",
    },
    {
      regionid: 1722,
      value: 1722214,
      label: "Qumqo'rg'on tumani",
    },
    {
      regionid: 1703,
      value: 1703209,
      label: "Bo'z tumani",
    },
    {
      regionid: 1727,
      value: 1727259,
      label: "Yangiyo'l tumani",
    },
    {
      regionid: 1718,
      value: 1718215,
      label: "Kattaqo'rg'on tumani",
    },
    {
      regionid: 1708,
      value: 1708209,
      label: "G'allaorol tumani",
    },
    {
      regionid: 1710,
      value: 1710207,
      label: "G'uzor tumani",
    },
    {
      regionid: 1735,
      value: 1735222,
      label: "Mo'ynoq tumani",
    },
    {
      regionid: 1735,
      value: 1735400,
      label: "Qoraqalpog'iston Respublikasining ahamiyatiga ega shah",
    },
  ],
  ru: [
    {
      regionid: 1703,
      value: 1703200,
      label: "Andijon viloyatining tumanlari",
    },
    {
      regionid: 1703,
      value: 1703400,
      label: "Административно-территориальное деление Андижанской области",
    },
    {
      regionid: 1706,
      value: 1706200,
      label: "Районы Бухарской области",
    },
    {
      regionid: 1706,
      value: 1706400,
      label: "Города областного значения в Бухарской области",
    },
    {
      regionid: 1708,
      value: 1708200,
      label: "Районы Джизакской области",
    },
    {
      regionid: 1708,
      value: 1708400,
      label: "Город районного значения в Джизакской области",
    },
    {
      regionid: 1710,
      value: 1710200,
      label: "Районы Кашкадарьинской области",
    },
    {
      regionid: 1703,
      value: 1703203,
      label: "Андижанский район",
    },
    {
      regionid: 1703,
      value: 1703206,
      label: "Рыбацкий район",
    },
    {
      regionid: 1703,
      value: 1703210,
      label: "Булагбошинский район",
    },
    {
      regionid: 1703,
      value: 1703214,
      label: "Избосканский район",
    },
    {
      regionid: 1703,
      value: 1703224,
      label: "Асакинский район",
    },
    {
      regionid: 1703,
      value: 1703227,
      label: "Мархаматский район",
    },
    {
      regionid: 1703,
      value: 1703230,
      label: "Шахриханский район",
    },
    {
      regionid: 1703,
      value: 1703232,
      label: "Пахтаабадский район",
    },
    {
      regionid: 1703,
      value: 1703401,
      label: "Андижан",
    },
    {
      regionid: 1706,
      value: 1706204,
      label: "Олотский район",
    },
    {
      regionid: 1706,
      value: 1706207,
      label: "Бухарский район",
    },
    {
      regionid: 1706,
      value: 1706212,
      label: "Вобкентский район",
    },
    {
      regionid: 1706,
      value: 1706219,
      label: "Когонский район",
    },
    {
      regionid: 1706,
      value: 1706232,
      label: "Каровулбазарский район",
    },
    {
      regionid: 1706,
      value: 1706240,
      label: "Пешку район",
    },
    {
      regionid: 1706,
      value: 1706242,
      label: "Ромитанский район",
    },
    {
      regionid: 1706,
      value: 1706246,
      label: "Жондорский район",
    },
    {
      regionid: 1706,
      value: 1706258,
      label: "Шафирконский район",
    },
    {
      regionid: 1706,
      value: 1706401,
      label: "город Бухара",
    },
    {
      regionid: 1706,
      value: 1706403,
      label: "Каган",
    },
    {
      regionid: 1708,
      value: 1708201,
      label: "Арнасойский район",
    },
    {
      regionid: 1708,
      value: 1708204,
      label: "Бахмальский район",
    },
    {
      regionid: 1708,
      value: 1708212,
      label: "Шараф Рашидов район",
    },
    {
      regionid: 1708,
      value: 1708218,
      label: "Заминский район",
    },
    {
      regionid: 1708,
      value: 1708220,
      label: "Зарбдарский район",
    },
    {
      regionid: 1708,
      value: 1708228,
      label: "Пахтакорский район",
    },
    {
      regionid: 1708,
      value: 1708235,
      label: "Форишский район",
    },
    {
      regionid: 1708,
      value: 1708237,
      label: "Янгиабадский район",
    },
    {
      regionid: 1708,
      value: 1708401,
      label: "Джизак",
    },
    {
      regionid: 1710,
      value: 1710212,
      label: "Дехканабадский район",
    },
    {
      regionid: 1710,
      value: 1710220,
      label: "Камаши район",
    },
    {
      regionid: 1710,
      value: 1710224,
      label: "Каршинский район",
    },
    {
      regionid: 1710,
      value: 1710229,
      label: "Косонский район",
    },
    {
      regionid: 1710,
      value: 1710232,
      label: "Китабский район",
    },
    {
      regionid: 1710,
      value: 1710233,
      label: "Миришкорский район",
    },
    {
      regionid: 1710,
      value: 1710234,
      label: "Мубаракский район",
    },
    {
      regionid: 1710,
      value: 1710400,
      label: "Кашкадарьинская область имеет региональное значение",
    },
    {
      regionid: 1712,
      value: 1712200,
      label: "Районы Навоийской области",
    },
    {
      regionid: 1712,
      value: 1712400,
      label: "Города областного значения Навоийской области",
    },
    {
      regionid: 1703,
      value: 1703217,
      label: "Улугнорский район",
    },
    {
      regionid: 1714,
      value: 1714200,
      label: "Районы Наманганской области",
    },
    {
      regionid: 1714,
      value: 1714400,
      label: "Города областного значения Наманганской области",
    },
    {
      regionid: 1718,
      value: 1718400,
      label: "Города районного значения Самаркандской области",
    },
    {
      regionid: 1722,
      value: 1722200,
      label: "Районы Сурхандарьинской области",
    },
    {
      regionid: 1710,
      value: 1710237,
      label: "Касбинский район",
    },
    {
      regionid: 1710,
      value: 1710242,
      label: "Чиракчинский район",
    },
    {
      regionid: 1710,
      value: 1710245,
      label: "Шахрисабзский район",
    },
    {
      regionid: 1710,
      value: 1710401,
      label: "Каршинский район",
    },
    {
      regionid: 1712,
      value: 1712211,
      label: "Конимекс район",
    },
    {
      regionid: 1712,
      value: 1712216,
      label: "Кызылтепинский район",
    },
    {
      regionid: 1712,
      value: 1712230,
      label: "Навбахорский район",
    },
    {
      regionid: 1712,
      value: 1712234,
      label: "Карманский район",
    },
    {
      regionid: 1712,
      value: 1712238,
      label: "Нуратинский район",
    },
    {
      regionid: 1712,
      value: 1712248,
      label: "Узгудукский район",
    },
    {
      regionid: 1712,
      value: 1712251,
      label: "Хатырчинский район",
    },
    {
      regionid: 1712,
      value: 1712401,
      label: "Навои",
    },
    {
      regionid: 1712,
      value: 1712408,
      label: "Зарафшан",
    },
    {
      regionid: 1714,
      value: 1714204,
      label: "Мингбулокский район",
    },
    {
      regionid: 1714,
      value: 1714207,
      label: "Косонсойский район",
    },
    {
      regionid: 1714,
      value: 1714212,
      label: "Наманганский район",
    },
    {
      regionid: 1714,
      value: 1714216,
      label: "Норинский район",
    },
    {
      regionid: 1714,
      value: 1714219,
      label: "Попский район",
    },
    {
      regionid: 1714,
      value: 1714229,
      label: "Уйчинский район",
    },
    {
      regionid: 1714,
      value: 1714236,
      label: "Чортокский район",
    },
    {
      regionid: 1714,
      value: 1714237,
      label: "Чустский район",
    },
    {
      regionid: 1714,
      value: 1714401,
      label: "Наманган",
    },
    {
      regionid: 1718,
      value: 1718203,
      label: "Акдарьинский район",
    },
    {
      regionid: 1718,
      value: 1718209,
      label: "Джомбойский район",
    },
    {
      regionid: 1718,
      value: 1718212,
      label: "Иштиханский район",
    },
    {
      regionid: 1718,
      value: 1718218,
      label: "Нарпайский район",
    },
    {
      regionid: 1718,
      value: 1718224,
      label: "Пайарикский район",
    },
    {
      regionid: 1718,
      value: 1718230,
      label: "Пахтачинский район",
    },
    {
      regionid: 1718,
      value: 1718233,
      label: "Самаркандский район",
    },
    {
      regionid: 1718,
      value: 1718235,
      label: "Нурабадский район",
    },
    {
      regionid: 1718,
      value: 1718236,
      label: "Ургутский район",
    },
    {
      regionid: 1718,
      value: 1718238,
      label: "Тайлакский район",
    },
    {
      regionid: 1718,
      value: 1718401,
      label: "Самарканд",
    },
    {
      regionid: 1722,
      value: 1722201,
      label: "Алтынсойский район",
    },
    {
      regionid: 1722,
      value: 1722202,
      label: "Ангорский район",
    },
    {
      regionid: 1722,
      value: 1722204,
      label: "Байсунский район",
    },
    {
      regionid: 1722,
      value: 1722210,
      label: "Деновский район",
    },
    {
      regionid: 1722,
      value: 1722400,
      label: "Города районного значения Сурхандарьинской области",
    },
    {
      regionid: 1724,
      value: 1724200,
      label: "Районы Сырдарьинской области",
    },
    {
      regionid: 1724,
      value: 1724400,
      label: "Города областного значения Сырдарьинской области",
    },
    {
      regionid: 1726,
      value: 1726260,
      label: "Районы города Ташкента",
    },
    {
      regionid: 1726,
      value: 1726262,
      label: "Учтепинский район",
    },
    {
      regionid: 1726,
      value: 1726264,
      label: "Бектемирский район",
    },
    {
      regionid: 1726,
      value: 1726266,
      label: "Юнусабадский район",
    },
    {
      regionid: 1726,
      value: 1726273,
      label: "Мирабадский район",
    },
    {
      regionid: 1718,
      value: 1718200,
      label: "Районы Самаркандской области",
    },
    {
      regionid: 1726,
      value: 1726277,
      label: "Шайхонтахурский район",
    },
    {
      regionid: 1726,
      value: 1726280,
      label: "Алмазарский район",
    },
    {
      regionid: 1726,
      value: 1726287,
      label: "Яккасарайский район",
    },
    {
      regionid: 1726,
      value: 1726290,
      label: "Яшнабадский район",
    },
    {
      regionid: 1726,
      value: 1726294,
      label: "Чиланзарский район",
    },
    {
      regionid: 1727,
      value: 1727200,
      label: "Районы Ташкентской области",
    },
    {
      regionid: 1727,
      value: 1727400,
      label: "Города районного значения Ташкентской области",
    },
    {
      regionid: 1722,
      value: 1722215,
      label: "Кызырикский район",
    },
    {
      regionid: 1722,
      value: 1722217,
      label: "Сариосский район",
    },
    {
      regionid: 1722,
      value: 1722220,
      label: "Термезский район",
    },
    {
      regionid: 1722,
      value: 1722221,
      label: "Узунский район",
    },
    {
      regionid: 1722,
      value: 1722223,
      label: "Шерабадский район",
    },
    {
      regionid: 1722,
      value: 1722401,
      label: "Термез",
    },
    {
      regionid: 1724,
      value: 1724206,
      label: "Аколтинский район",
    },
    {
      regionid: 1724,
      value: 1724212,
      label: "Boyovut district",
    },
    {
      regionid: 1724,
      value: 1724220,
      label: "Гулистанский район",
    },
    {
      regionid: 1724,
      value: 1724226,
      label: "Сардобинский район",
    },
    {
      regionid: 1724,
      value: 1724228,
      label: "Мирзаабадский район",
    },
    {
      regionid: 1724,
      value: 1724231,
      label: "Сырдарьинский район",
    },
    {
      regionid: 1724,
      value: 1724401,
      label: "Гулистан",
    },
    {
      regionid: 1724,
      value: 1724410,
      label: "Ширин",
    },
    {
      regionid: 1724,
      value: 1724413,
      label: "Янгиер",
    },
    {
      regionid: 1727,
      value: 1727212,
      label: "Охангаронский район",
    },
    {
      regionid: 1727,
      value: 1727220,
      label: "Бекабадский район",
    },
    {
      regionid: 1727,
      value: 1727233,
      label: "Чирчикский район",
    },
    {
      regionid: 1727,
      value: 1727237,
      label: "Зангиатинский район",
    },
    {
      regionid: 1727,
      value: 1727248,
      label: "Кыбрай район",
    },
    {
      regionid: 1727,
      value: 1727249,
      label: "Паркенnский район",
    },
    {
      regionid: 1727,
      value: 1727250,
      label: "Пскентcкий район",
    },
    {
      regionid: 1727,
      value: 1727256,
      label: "Чинозский район",
    },
    {
      regionid: 1727,
      value: 1727404,
      label: "Олмалыкский район",
    },
    {
      regionid: 1727,
      value: 1727407,
      label: "Ангренский район ",
    },
    {
      regionid: 1727,
      value: 1727413,
      label: "Бекободский район",
    },
    {
      regionid: 1727,
      value: 1727419,
      label: "Чирчиксий район",
    },
    {
      regionid: 1730,
      value: 1730203,
      label: "Олтарикский район",
    },
    {
      regionid: 1730,
      value: 1730212,
      label: "Бувайдинский район",
    },
    {
      regionid: 1730,
      value: 1730215,
      label: "Бешарикский район",
    },
    {
      regionid: 1733,
      value: 1733200,
      label: "Районы Хорезмской области",
    },
    {
      regionid: 1733,
      value: 1733400,
      label: "Город областного значения в Хорезмской области.",
    },
    {
      regionid: 1735,
      value: 1735204,
      label: "Амударьинский район",
    },
    {
      regionid: 1735,
      value: 1735207,
      label: "Берунийский район",
    },
    {
      regionid: 1735,
      value: 1735212,
      label: "Кегейлийский район",
    },
    {
      regionid: 1724,
      value: 1724235,
      label: "Хавосский район",
    },
    {
      regionid: 1730,
      value: 1730209,
      label: "Богдодский район",
    },
    {
      regionid: 1735,
      value: 1735218,
      label: "Канлыкольский район",
    },
    {
      regionid: 1735,
      value: 1735200,
      label: "Районы Республики Каракалпакстан",
    },
    {
      regionid: 1727,
      value: 1727228,
      label: "Бокинский район",
    },
    {
      regionid: 1722,
      value: 1722226,
      label: "Шорчинский район",
    },
    {
      regionid: 1735,
      value: 1735225,
      label: "Нукусский район",
    },
    {
      regionid: 1735,
      value: 1735240,
      label: "Чимбойский район",
    },
    {
      regionid: 1735,
      value: 1735243,
      label: "Шуманайский район",
    },
    {
      regionid: 1735,
      value: 1735250,
      label: "Элликкалинский район",
    },
    {
      regionid: 1708,
      value: 1708225,
      label: "Зафарабадский район",
    },
    {
      regionid: 1710,
      value: 1710235,
      label: "Нишанский район",
    },
    {
      regionid: 1712,
      value: 1712244,
      label: "Томдинский район",
    },
    {
      regionid: 1722,
      value: 1722207,
      label: "Музработский район",
    },
    {
      regionid: 1724,
      value: 1724216,
      label: "Сайхунабадский район",
    },
    {
      regionid: 1727,
      value: 1727239,
      label: "Юкорчирчикский район",
    },
    {
      regionid: 1730,
      value: 1730224,
      label: "Риштанский район",
    },
    {
      regionid: 1730,
      value: 1730227,
      label: "Тошлокский район",
    },
    {
      regionid: 1730,
      value: 1730238,
      label: "Фуркатский район",
    },
    {
      regionid: 1730,
      value: 1730242,
      label: "Ёжиовонский район",
    },
    {
      regionid: 1730,
      value: 1730408,
      label: "Кувасой",
    },
    {
      regionid: 1733,
      value: 1733204,
      label: "Богатский район",
    },
    {
      regionid: 1733,
      value: 1733208,
      label: "Гурланский район",
    },
    {
      regionid: 1733,
      value: 1733217,
      label: "Ургенчский район",
    },
    {
      regionid: 1733,
      value: 1733220,
      label: "Хазораспский район",
    },
    {
      regionid: 1733,
      value: 1733223,
      label: "Ханкайский район",
    },
    {
      regionid: 1733,
      value: 1733226,
      label: "Хивинский район",
    },
    {
      regionid: 1733,
      value: 1733230,
      label: "Шаватский район",
    },
    {
      regionid: 1733,
      value: 1733233,
      label: "Янгарикский район",
    },
    {
      regionid: 1733,
      value: 1733236,
      label: "Янгибозарский район",
    },
    {
      regionid: 1733,
      value: 1733401,
      label: "Ургенч",
    },
    {
      regionid: 1730,
      value: 1730218,
      label: "Кувинский район",
    },
    {
      regionid: 1703,
      value: 1703408,
      label: "Ханабад",
    },
    {
      regionid: 1735,
      value: 1735401,
      label: "Нукус",
    },
    {
      regionid: 1735,
      value: 1735209,
      label: "Бозатовский район",
    },
    {
      regionid: 1733,
      value: 1733221,
      label: "Тупроккалинский район",
    },
    {
      regionid: 1726,
      value: 1726292,
      label: "Янгихаятский район",
    },
    {
      regionid: 1712,
      value: 1712412,
      label: "Газгон город",
    },
    {
      regionid: 1727,
      value: 1727415,
      label: "Охангаранский город",
    },
    {
      regionid: 1727,
      value: 1727265,
      label: "Ташкентский район",
    },
    {
      regionid: 1735,
      value: 1735228,
      label: "Тахиаташ",
    },
    {
      regionid: 1710,
      value: 1710405,
      label: "город Шахрисабз",
    },
    {
      regionid: 1727,
      value: 1727424,
      label: "город Янгёль",
    },
    {
      regionid: 1727,
      value: 1727401,
      label: "Город Нурафшан",
    },
    {
      regionid: 1733,
      value: 1733406,
      label: "Город Хива",
    },
    {
      regionid: 1703,
      value: 1703211,
      label: "Жалакудукский район",
    },
    {
      regionid: 1730,
      value: 1730230,
      label: "Район Узбекистана",
    },
    {
      regionid: 1722,
      value: 1722203,
      label: "Бандиханский район",
    },
    {
      regionid: 1730,
      value: 1730226,
      label: "Сохинский район",
    },
    {
      regionid: 1730,
      value: 1730236,
      label: "Дангаринский район",
    },
    {
      regionid: 1730,
      value: 1730405,
      label: "Какан",
    },
    {
      regionid: 1730,
      value: 1730401,
      label: "Фергана",
    },
    {
      regionid: 1735,
      value: 1735233,
      label: "Торткольский район",
    },
    {
      regionid: 1730,
      value: 1730412,
      label: "Маргилан",
    },
    {
      regionid: 1722,
      value: 1722212,
      label: "Джаркурганский район",
    },
    {
      regionid: 1733,
      value: 1733212,
      label: "Кошкопирский район",
    },
    {
      regionid: 1730,
      value: 1730400,
      label: "Города областного значения Ферганской области",
    },
    {
      regionid: 1730,
      value: 1730200,
      label: "Районы Ферганской области",
    },
    {
      regionid: 1730,
      value: 1730221,
      label: "Учкоприкский район",
    },
    {
      regionid: 1730,
      value: 1730233,
      label: "Ферганский район",
    },
    {
      regionid: 1730,
      value: 1730206,
      label: "Коштепинский район",
    },
    {
      regionid: 1718,
      value: 1718216,
      label: "Хошработский район",
    },
    {
      regionid: 1710,
      value: 1710250,
      label: "Яккабогский район",
    },
    {
      regionid: 1718,
      value: 1718206,
      label: "Булугурский район",
    },
    {
      regionid: 1714,
      value: 1714234,
      label: "Учкурганский район",
    },
    {
      regionid: 1714,
      value: 1714224,
      label: "Торакурганский район",
    },
    {
      regionid: 1735,
      value: 1735230,
      label: "Тахтакорпирский район",
    },
    {
      regionid: 1735,
      value: 1735236,
      label: "Ходжалинский район",
    },
    {
      regionid: 1710,
      value: 1710240,
      label: "Кукдалинский район",
    },
    {
      regionid: 1727,
      value: 1727206,
      label: "Уккурганский район",
    },
    {
      regionid: 1727,
      value: 1727224,
      label: "Бостанлыгский район",
    },
    {
      regionid: 1726,
      value: 1726283,
      label: "Сиргалинский район",
    },
    {
      regionid: 1726,
      value: 1726269,
      label: "Мирзо Улугбекский район",
    },
    {
      regionid: 1718,
      value: 1718227,
      label: "Пастдаргомский район",
    },
    {
      regionid: 1735,
      value: 1735215,
      label: "Кунгиратский район",
    },
    {
      regionid: 1714,
      value: 1714242,
      label: "Янгикурганский район",
    },
    {
      regionid: 1735,
      value: 1735211,
      label: "Караозакский район",
    },
    {
      regionid: 1708,
      value: 1708223,
      label: "Мирзачельский район",
    },
    {
      regionid: 1703,
      value: 1703202,
      label: "Олтынкольский район",
    },
    {
      regionid: 1706,
      value: 1706215,
      label: "Гиждуванский район",
    },
    {
      regionid: 1708,
      value: 1708215,
      label: "Дустликский район",
    },
    {
      regionid: 1718,
      value: 1718406,
      label: "Катакурган",
    },
    {
      regionid: 1727,
      value: 1727253,
      label: "Уртачирчикский район",
    },
    {
      regionid: 1703,
      value: 1703220,
      label: "Коргонтепинский район",
    },
    {
      regionid: 1703,
      value: 1703236,
      label: "Ходжаабадский район",
    },
    {
      regionid: 1706,
      value: 1706230,
      label: "Каракольский район",
    },
    {
      regionid: 1722,
      value: 1722214,
      label: "Кумкурганский район",
    },
    {
      regionid: 1703,
      value: 1703209,
      label: "Бозский район",
    },
    {
      regionid: 1727,
      value: 1727259,
      label: "Янгиельский район",
    },
    {
      regionid: 1718,
      value: 1718215,
      label: "Каттакурганский район",
    },
    {
      regionid: 1708,
      value: 1708209,
      label: "Галлаорольский район",
    },
    {
      regionid: 1710,
      value: 1710207,
      label: "Гузорский район",
    },
    {
      regionid: 1735,
      value: 1735222,
      label: "Мойнокский район",
    },
    {
      regionid: 1735,
      value: 1735400,
      label: "Важные города Республики Каракалпакстан",
    },
  ],
};

export const Regions = {
  uz: [
    {
      value: 1726,
      label: "Toshkent shahri",
    },
    {
      value: 1735,
      label: "Qoraqalpog`iston Respublikasi",
    },
    {
      value: 1703,
      label: "Andijon viloyati",
    },
    {
      value: 1706,
      label: "Buxoro viloyati",
    },
    {
      value: 1708,
      label: "Jizzax viloyati",
    },
    {
      value: 1710,
      label: "Qashqadaryo viloyati",
    },
    {
      value: 1712,
      label: "Navoiy viloyati",
    },
    {
      value: 1714,
      label: "Namangan viloyati",
    },
    {
      value: 1718,
      label: "Samarqand viloyati",
    },
    {
      value: 1722,
      label: "Surxandaryo viloyati",
    },
    {
      value: 1724,
      label: "Sirdaryo viloyati",
    },
    {
      value: 1727,
      label: "Toshkent viloyati",
    },
    {
      value: 1730,
      label: "Farg`ona viloyati",
    },
    {
      value: 1733,
      label: "Xorazm viloyati",
    },
  ],
  ru: [
    {
      value: 1726,
      label: "Город Ташкент",
    },
    {
      value: 1735,
      label: "Республика Каракалпакстан",
    },
    {
      value: 1703,
      label: "Андижанская область",
    },
    {
      value: 1706,
      label: "Бухарская область",
    },
    {
      value: 1708,
      label: "Джизахская область",
    },
    {
      value: 1710,
      label: "Кашкадаринская область",
    },
    {
      value: 1712,
      label: "Навоинская область",
    },
    {
      value: 1714,
      label: "Наманганская область",
    },
    {
      value: 1718,
      label: "Самаркандская область",
    },
    {
      value: 1722,
      label: "Сурхандаринская область",
    },
    {
      value: 1724,
      label: "Сирдариннская область",
    },
    {
      value: 1727,
      label: "Ташкентская область",
    },
    {
      value: 1730,
      label: "Ферганская область",
    },
    {
      value: 1733,
      label: "Хорезмская область",
    },
  ],
};
