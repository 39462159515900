import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { TableWrapper } from "./style";
import { useLocation, useNavigate } from "react-router-dom";

export default function TableComponent({
  data,
  columns,
  isloading,
  params = { Offset: 0, Limit: 10, Total: 10 },
}) {
  const [forParams, setForParams] = useState(params);
  const navigate = useNavigate();
  const location = useLocation();

  const onChange = (page) => {
    setForParams({ ...forParams, Offset: params?.Limit * (page - 1) });

    let queryParams =
      "?offset=" + params?.Limit * (page - 1) + "&limit=" + forParams.Limit;
    navigate({
      pathname: location.pathname,
      search: queryParams,
    });
  };

  useEffect(() => {
    if (params) {
      setForParams(params);
    }
  }, [params]);

  useEffect(() => {
    let queryParams = "?offset=" + params?.Offset + "&limit=" + params.Limit;
    navigate({
      pathname: location.pathname,
      search: queryParams,
    });
  }, []);

  return (
    <TableWrapper>
      <Table
        bordered
        loading={isloading}
        scroll={{ x: 400 }}
        columns={columns}
        dataSource={data}
        pagination={{
          defaultCurrent: params?.Offset + 1,
          total: params?.Total,
          pageSize: params?.Limit,
          onChange: onChange,
          responsive: true,
          showSizeChanger: false,
        }}
      />
    </TableWrapper>
  );
}
