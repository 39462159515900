import SidebarComponent from "components/sidebar";

import React from "react";
import { Navigate, Outlet } from "react-router-dom";

export default function PrivateRoute() {
  const auth = true;
  // localStorage.getItem("tokenExpairy") ===
  //   dayjs(new Date()).format("YYYYMMDD") &&
  // localStorage.getItem("RIZOADMINTOKEN")
  //   ? true
  //   : false;

  return (
    <>
      {auth ? (
        <SidebarComponent>
          <Outlet />
        </SidebarComponent>
      ) : (
        <Navigate to="/" />
      )}
    </>
  );
}
