import { EyeFilled } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { Alert, Button, Tag } from "antd";
import { Image } from "components/image/style";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { getsApi } from "services/api/pagesApi";
import { ApiSiteKey } from "services/jsons";

export default function useData() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { pathname, search = "?offset=0&limit=10" } = location;

  const { data: allSubcatalogs } = useQuery(
    [ApiSiteKey.getSubCatalogs],
    () => getsApi.getSubCatalogs({ limit: 100, offset: 0 }),

    {
      select: (data) => {
        return data?.data?.map((x) => {
          return {
            ...x,
            key: x?.id,
            keyForm: x?.key,
          };
        });
      },
      onSuccess: (data) => {
        console.log(data);
      },
    }
  );

  const { data, isLoading } = useQuery(
    [
      pathname.includes("unchecked-post")
        ? ApiSiteKey.getUncheckedPosts
        : pathname.includes("active-post")
        ? ApiSiteKey.getActivePosts
        : ApiSiteKey.getRejectedkedPosts,
      pathname,
      search,
    ],
    () =>
      pathname.includes("unchecked-post")
        ? getsApi.getUncheckedPosts(search)
        : pathname.includes("active-post")
        ? getsApi.getActivePosts(search)
        : getsApi.getRejectedPosts(search),
    {
      select: (data) => {
        const info = {
          param: data?.data?.pagination,
          data: data?.data?.list?.map((x) => {
            return {
              ...x,
              key: x?.postId?.replaceAll("-", ""),
            };
          }),
        };
        return info;
      },
      onSuccess: (data) => {
        return data;
      },
    }
  );

  let loading = isLoading;

  const columns = [
    {
      title: "Подкатегория",
      dataIndex: "post_subcatalog_id",
      key: "post_subcatalog_id",
      render: (x, row) => {
        console.log(row, "row");
        return row?.PostApplication &&
          allSubcatalogs?.find((a) => x == a.id)?.keyForm.includes("sale")
          ? t("application")
          : allSubcatalogs?.find((a) => x == a.id)?.name;
      },
    },
    {
      title: "Заголовок",
      dataIndex: "postTitle",
      key: "postTitle",
    },
    {
      title: "Комментарий администратора",
      dataIndex: "post_error_message",
      key: "post_error_message",
      render: (x) => <Alert message={x} type="error" />,
      hidden: pathname.includes("rejected-post") ? false : true,
    },
    // {
    //   title: "Описание",
    //   dataIndex: "postDescription",
    //   key: "postDescription",
    //   render: (x) => (
    //     <div
    //       style={{
    //         padding: 4,
    //         backgroundColor: "#fff",
    //         maxWidth: 500,
    //         textAlign: "center",
    //         borderRadius: 4,
    //       }}
    //     >
    //       <Paragraph ellipsis={{ rows: 7, expandable: true, symbol: "..." }}>
    //         {x}
    //       </Paragraph>
    //     </div>
    //   ),
    // },

    {
      title: "Цена",
      dataIndex: "postPrice",
      key: "postPrice",
      render: ({ total, uzs, application }) => (
        <Tag style={{ borderRadius: 4 }} color="green">
          {`${
            total
              ? total
              : application
              ? `${application?.price_home_from} - ${application?.price_home_to}`
              : "--"
          } `}{" "}
          <span style={{ color: "black" }}>{`${uzs ? "UZS" : "Y.E"}`}</span>
        </Tag>
      ),
    },

    {
      title: "Фото (основной)",
      dataIndex: "postImage",
      key: "postImage",
      render: (x) =>
        x === null || x?.length < 1 || (x?.length > 0 && x[0] === "") ? (
          <Tag color="red" style={{ borderRadius: 4 }}>
            Изображение не загружено
          </Tag>
        ) : (
          <div
            style={{
              padding: 4,
              backgroundColor: "#fff",
              maxWidth: 150,
              textAlign: "center",
            }}
          >
            <Image width={"100%"} src={x[0]} />
          </div>
        ),
    },

    // {
    //   title: "Telefon raqam",
    //   dataIndex: "postContact",
    //   key: "postContact",
    //   render: ({ tel, extraTel }) => (
    //     <>
    //       {tel != undefined && (
    //         <a style={{ display: "block" }} href={"tel:" + tel}>
    //           <Tag style={{ borderRadius: 4 }}>{tel}</Tag>
    //         </a>
    //       )}
    //       {extraTel != undefined && (
    //         <a
    //           style={{ marginTop: 4, display: "block" }}
    //           href={"tel:" + extraTel}
    //         >
    //           <Tag style={{ borderRadius: 4 }}>{extraTel}</Tag>
    //         </a>
    //       )}
    //     </>
    //   ),
    // },

    // {
    //   title: "manzil",
    //   dataIndex: "postLocation",
    //   key: "postLocation",
    //   render: (x) => x?.text?.location + ", " + x?.text?.home,
    // },

    // {
    //   title: "Elon berilgan vaqti",
    //   dataIndex: "postDate",
    //   key: "postDate",
    //   render: (x) => dayjs(x).format("DD.MM.YYYY HH:mm"),
    // },

    {
      title: "Действие",
      dataIndex: "Action",
      key: "Action",
      render: (id, row) => (
        <>
          <div className="btnWrapper-list" key={row.postId}>
            <Button
              style={{ marginRight: 8 }}
              type="link"
              icon={<EyeFilled />}
              onClick={() =>
                navigate(
                  (pathname.includes("unchecked-post")
                    ? "/unchecked-post/"
                    : pathname.includes("active-post")
                    ? "/active-posts/"
                    : "/rejected-post/") + row?.postId
                )
              }
            >
              Подробнее
            </Button>
            {/* <Button
              type="dashed"
              onClick={() => handleCheck(row?.posterId)}
              icon={<DeleteOutlined />}
            /> */}
          </div>
        </>
      ),
    },
  ].filter((item) => !item.hidden);

  return { data, columns, loading };
}
