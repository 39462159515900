import { Typography } from "antd";
import React from "react";
import { useLocation } from "react-router-dom";
import YandexMap from "./yandexMap";
import { useTranslation } from "react-i18next";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
const { Text } = Typography;
export default function PostHeader({ data, allSubcatalogs }) {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  return (
    <>
      <div>
        <h3>
          <b>Основная информация</b>
        </h3>
      </div>
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 991: 2, 1600: 3 }}>
        <Masonry gutter="16px">
          <div>
            <p>
              <b>Заголовок:</b> {data?.postTitle}
            </p>
          </div>

          {pathname.includes("rejected-post") && (
            <div>
              <p>
                <b>Комментарий администратора:</b>
                <Text type="danger">{data?.post_error_message}</Text>
              </p>
            </div>
          )}

          <div>
            <p>
              <b>Номер телефона:</b>{" "}
              <a href={"tel:" + data?.postContact?.tel}>
                {data?.postContact?.tel}
              </a>
            </p>
          </div>
          <div>
            <p>
              <b>Описание:</b>
              <div
                className="desc_dangerously"
                dangerouslySetInnerHTML={{ __html: data?.postDescription }}
              ></div>
            </p>
          </div>
          <div>
            <p>
              <b>Подкатегория:</b>{" "}
              {data?.PostApplication &&
              allSubcatalogs
                ?.find((a) => data?.post_subcatalog_id == a.id)
                ?.keyForm.includes("sale")
                ? t("application")
                : allSubcatalogs?.find((a) => data?.post_subcatalog_id == a.id)
                    ?.name}
            </p>
          </div>

          {data?.postContact?.extraTel && (
            <div>
              <p>
                <b>Дополнительный номер телефона:</b>{" "}
                <a href={"tel:" + data?.postContact?.extraTel}>
                  {data?.postContact?.extraTel}
                </a>
              </p>
            </div>
          )}

          <div>
            <p>
              <b>Цена:</b>{" "}
              {(data?.postPrice?.total !== undefined
                ? data?.postPrice?.total
                : data?.postPrice?.application?.price_home_from
                ? `${data?.postPrice?.application?.price_home_from} - ${data?.postPrice?.application?.price_home_to} `
                : "Сумма указана в неправильном формате ") +
                (data?.postPrice?.usd
                  ? "Y.E"
                  : data?.postPrice?.uzs
                  ? " UZS"
                  : " Валюта не выбрана")}
            </p>
          </div>

          {data?.postArea?.general && (
            <div>
              <p style={{ fontSize: "14px" }}>
                <b>Общая площадь:</b> {data?.postArea?.general} m²
              </p>
            </div>
          )}
          {data?.postArea?.rasidential && (
            <div>
              <p style={{ fontSize: "14px" }}>
                <b>Жилой площадь:</b> {data?.postArea?.rasidential} m²
              </p>
            </div>
          )}
          {data?.postArea?.kitchen && (
            <div>
              <p style={{ fontSize: "14px" }}>
                <b>Кухня:</b> {data?.postArea?.kitchen} m²
              </p>
            </div>
          )}

          <div>
            <p>
              <b>Адрес :</b>{" "}
              {data?.postLocation?.text
                ? `${
                    data?.postLocation?.text?.region ??
                    data?.postLocation?.text?.location
                  } ${
                    data?.postLocation?.text?.district ??
                    data?.postLocation?.text?.home ??
                    ""
                  }`
                : ""}
              {data?.postLocation?.coordinates && (
                <YandexMap marker={data?.postLocation?.coordinates} />
              )}
            </p>
          </div>
        </Masonry>
      </ResponsiveMasonry>
    </>
  );
}
