import React from 'react';
import { LoaderWrapper } from './style';

export default function LoaderComponent() {
  return (
    <LoaderWrapper>
      <div className="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </LoaderWrapper>
  );
}
