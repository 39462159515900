import React from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { FormGroupProvider, LabelInp } from "../input/style";
import { customStyles } from "./style";

const UseFormSelectComponent = ({
  control,
  options,
  defValue,
  disabled,
  name,
  placeholder,
  required = true,
  className,
  popoverText = false,
  isDisabled = false,
  ismulti = false,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: required }}
      render={({ field: { onChange, value, ref }, formState: { errors } }) => (
        <FormGroupProvider>
          <LabelInp>{placeholder}</LabelInp>
          <Select
            ref={ref}
            className={className}
            options={options}
            onChange={onChange}
            value={value}
            defaultValue={defValue ? defValue : []}
            // isDisabled={disabled ? disabled : false}
            styles={customStyles}
            placeholder={placeholder}
            error={!!errors?.title}
            isMulti={ismulti}
            components={{
              IndicatorSeparator: () => null,
            }}
            isDisabled={isDisabled}
          />
        </FormGroupProvider>
      )}
    />
  );
};

export default UseFormSelectComponent;

export const SelectItem = (allfaculty, setState) => {
  setState([]);
  let sortArr = allfaculty?.map((e) => {
    return {
      value: e.id,
      label: e.name,
    };
  });
  setState(sortArr);
};
