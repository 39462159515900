import { Col, Row } from "antd";
import { InputComponent } from "components/inputs/input";
import { ErrorP, LabelInp } from "components/inputs/input/style";
import React from "react";
import { Wrapper } from "./style";
import { MyTitle } from "components/hrWrapper/style";
import ButtonComponent from "components/buttons";
import useCrud from "./hooks/useCrud";
import FileCustom from "components/inputs/file";
import LoaderComponent from "components/loader";
import { useNavigate, useParams } from "react-router-dom";

export default function CrudPartners() {
  const navigate = useNavigate();
  const { id } = useParams();

  const { sbmt, form, img, setImg, loader } = useCrud();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = form;

  return (
    <Wrapper>
      {loader && <LoaderComponent />}
      <MyTitle>
        <h2>{id === undefined ? "Создавать" : "Редактирование"} Партнеры</h2>
        <ButtonComponent className="back" onClick={() => navigate(-1)}>
          Назад
        </ButtonComponent>
      </MyTitle>
      <form onSubmit={handleSubmit(sbmt)}>
        <Row gutter={[16, 16]}>
          <Col span={12} md={8}>
            <InputComponent
              control={control}
              nameProps="title"
              plProps="Имя"
              type="text"
              required={true}
              minLength={4}
              className={
                errors && errors?.hasOwnProperty("title") ? "input-error" : " "
              }
            />
            {errors && errors?.hasOwnProperty("title") && (
              <ErrorP>Это поле является обязательным!</ErrorP>
            )}
          </Col>
          <Col span={12} md={8}>
            <InputComponent
              control={control}
              nameProps="link"
              plProps="URL-адрес"
              type="url"
              required={true}
              minLength={4}
              className={
                errors && errors?.hasOwnProperty("title") ? "input-error" : " "
              }
            />
            {errors && errors?.hasOwnProperty("title") && (
              <ErrorP>Это поле является обязательным!</ErrorP>
            )}
          </Col>

          <Col span={12} md={8}>
            <LabelInp>Изображение (размер: 480x280)</LabelInp>
            <FileCustom imageUrl={img} setImageUrl={setImg} />
          </Col>

          <Col
            span={24}
            md={6}
            style={{ marginLeft: "auto", marginTop: "24px" }}
          >
            <ButtonComponent type="submit">
              {id === undefined ? "Создавать" : "Редактирование"}
            </ButtonComponent>
          </Col>
        </Row>
      </form>
    </Wrapper>
  );
}
