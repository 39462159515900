import Api from "../index";

class Gets extends Api {
  getAdmins() {
    return this.execute("get", "boss/admin-account/admin-list");
  }
  getApplications({ search, id }) {
    return this.execute(
      "get",
      `admin/zayavka/list${
        search?.includes("limit") ? search : "?offset=0&limit=10"
      }&investId=${id}`
    );
  }
  getPartnerApplications({ search }) {
    return this.execute(
      "get",
      `admin/partner-program/list${
        search?.includes("limit") ? search : "?offset=0&limit=10"
      }`
    );
  }
  getAdminById({ id }) {
    return this.execute("get", "boss/admin-account/admin-get?admin_id=" + id);
  }
  getTarifById({ id }) {
    return this.execute("get", "admin/vir-pay-mod/get?id=" + id);
  }
  getCatalogs({ limit, offset }) {
    return this.execute(
      "get",
      `admin/catalog/list?limit=${limit}&offset=${offset}`
    );
  }
  getUserList(data, searchkey) {
    return this.execute(
      "get",
      `admin/analytics/all-account${
        data?.includes("limit") ? data : "?offset=0&limit=10"
      }&search-key=${searchkey}`
    );
  }

  getUserPaymentList(data, id) {
    return this.execute(
      "get",
      `admin/analytics/payment-history${
        data?.includes("limit") ? data : "?offset=0&limit=10"
      }&userId=${id}&dateFilter=ASC`
    );
  }
  getFaqType({ limit, offset }) {
    return this.execute(
      "get",
      `admin/faq-category/list?limit=${limit}&offset=${offset}`
    );
  }
  getFaqs({ limit, offset }) {
    return this.execute(
      "get",
      `admin/faq/all-list?limit=${limit}&offset=${offset}`
    );
  }
  getTarifs() {
    return this.execute("get", `admin/vir-pay-mod/get-list`);
  }
  getCatalogById({ id }) {
    return this.execute("get", "/admin/catalog/get?id=" + id);
  }
  getFaqTypeById({ id }) {
    return this.execute("get", "/admin/faq-category/get?id=" + id);
  }
  getFaqById({ id }) {
    return this.execute("get", "/admin/faq/get?id=" + id);
  }
  getSubCatalogById({ id }) {
    return this.execute("get", "/admin/subcatalog/get?id=" + id);
  }
  getSubCatalogs({ limit, offset }) {
    return this.execute(
      "get",
      `admin/subcatalog/list?limit=${limit}&offset=${offset}`
    );
  }

  getInvestmentById({ id }) {
    return this.execute("get", "/admin/investment/get?id=" + id);
  }
  getPartnersById({ id }) {
    return this.execute("get", "/admin/partner/get?id=" + id);
  }

  getInvestments(data) {
    return this.execute(
      "get",
      `admin/investment/list${
        data?.includes("limit") ? data : "?offset=0&limit=10"
      }`
    );
  }
  getPartners(data) {
    return this.execute(
      "get",
      `admin/partner/list${
        data?.includes("limit") ? data : "?offset=0&limit=10"
      }`
    );
  }
  getAnalytics() {
    return this.execute("get", `admin/analytics/payments-analytics`);
  }
  getAllAnalytics() {
    return this.execute("get", `admin/analytics/accounts-and-posts-analytics`);
  }

  getUncheckedPosts(data) {
    return this.execute(
      "get",
      `admin/check-post/get-uncheckedposts${
        data?.includes("limit") ? data : "?offset=0&limit=10"
      }&postIsActive=false`
    );
  }
  getActivePosts(data) {
    return this.execute(
      "get",
      `admin/check-post/get-uncheckedposts${
        data?.includes("limit") ? data : "?offset=0&limit=10"
      }&postIsActive=true`
    );
  }
  getRejectedPosts(data) {
    return this.execute(
      "get",
      `admin/check-post/get-rejectedpostlist${
        data?.includes("limit") ? data : "?offset=0&limit=10"
      }&postIsActive=false`
    );
  }
  getPostById({ id }) {
    return this.execute("get", `admin/check-post/get-uncheckedpost?id=${id}`);
  }
  getPostRejectedById({ id }) {
    return this.execute("get", `admin/check-post/get-rejectedpost?id=${id}`);
  }
}

export default Gets;
