import { useQuery } from "@tanstack/react-query";
import { Tag } from "antd";
import dayjs from "dayjs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getsApi } from "services/api/pagesApi";
import { ApiSiteKey } from "services/jsons";
export default function useData() {
  const location = useLocation();
  const { search = "?offset=0&limit=10" } = location;
  const { id } = useParams();
  const userInfo = id?.split("$@@$___$@@$");
  const navigate = useNavigate();

  const { refetch, data, isLoading } = useQuery(
    [ApiSiteKey.getUserPayment, search],
    () => getsApi.getUserPaymentList(search, userInfo[0]),

    {
      select: (data) => {
        const info = {
          param: {
            Limit: data?.data?.pagination?.limit,
            Offset: data?.data?.pagination?.offset,
            Total: data?.data?.pagination?.total,
          },
          data: data?.data?.data?.map((x) => {
            return {
              ...x,
              key: x?.ID?.replaceAll("-", ""),
            };
          }),
        };
        return info;
      },
      onSuccess: (data) => {
        console.log(data);
      },
      enabled: id !== undefined ? true : false,
    }
  );

  function handleSearch() {
    navigate({ pathname: "/users", search: "?offset=0&limit=10" });
    refetch();
  }

  const columns = [
    {
      title: "ID транзакции",
      dataIndex: "postTransactionID",
      key: "postTransactionID",
    },
    {
      title: "Способ оплаты",
      dataIndex: "PaymentType",
      key: "PaymentType",
      render: (x) => x?.toLocaleUpperCase()?.replaceAll("_", " + "),
    },
    {
      title: "Статус платежа",
      dataIndex: "PaymentType",
      key: "PaymentType",
      render: (x) =>
        x === "Click" || x === "Payme" ? "Счет заполнен" : "Тариф был куплен",
    },
    {
      title: "Расходы",
      dataIndex: "amount",
      key: "amount",
      render: (x, row) => (
        <Tag
          color={
            row?.PaymentType === "Click" || row?.PaymentType === "Payme"
              ? "#87d068"
              : "#108ee9"
          }
        >
          {x + " сум"}
        </Tag>
      ),
    },
    {
      title: "Оплаченное время",
      dataIndex: "Date",
      key: "Date",
      render: (x) => dayjs(x).format("DD.MM.YYYY HH:mm"),
    },
  ].filter((item) => !item.hidden);

  return {
    data: data,
    columns,
    handleSearch,
    isLoading,
    userInfo,
  };
}
