import axios from "axios";
// import toast from 'react-hot-toast';
import { notification } from "antd";
const LocalToken = localStorage.getItem("RIZOADMINTOKEN");
class Api {
  constructor() {
    this.api2client = axios.create({
      baseURL: process.env.REACT_APP_API_ROOT,
    });
    this.api2client.defaults.params = {};
    this.query = {};
    // this.token = LocalToken;
    this.setInterceptors();
  }
  setInterceptors() {
    this.api2client.interceptors.response.use(
      (resp) => {
        if (resp.data) {
          if (typeof resp.data === "string") {
            resp.data = {
              data: resp.data,
              status: resp.status,
            };
          } else {
            resp.data.status = resp.status;
          }
        }
        return resp.data;
      },
      (err) => {
        throw err;
      }
    );
  }

  async execute(method, url, data, onUploadProgress, id) {
    const accessToken = localStorage.getItem("RIZOADMINTOKEN");
    let headers = {};
    if (accessToken) {
      headers = { LocalToken };
      headers = {
        Authorization: `Bearer ${localStorage.getItem("RIZOADMINTOKEN")}`,
      };
    }
    try {
      const resp = await this.api2client({
        method,
        url,
        data,
        headers,
        onUploadProgress,
        id,
      });
      if (url.endsWith("paging")) {
        const params = this.makeQueryString(data);
        window.history.replaceState(
          null,
          "TDAU | Hr",
          window.location.href.split("?")[0] + params
        );
      }

      return resp;
    } catch (err) {
      const error = err.response;
      if (error.code === 401) window.location.replace("/");
      console.log(error);
      notification["error"]({
        message: "Error",
        description:
          error?.data?.msg || error?.message || error?.msg || "Erorr",
      });

      if (!error) {
        notification["warning"]({
          message: "Warning",
          description: "Internet connection error!",
        });
        throw err;
      } else throw err.response.data;
    }
  }
}
export default Api;
