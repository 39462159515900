import { useMutation, useQuery } from "@tanstack/react-query";
import { notification } from "antd";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { getsApi, postApi, putApi } from "services/api/pagesApi";
import { ApiSiteKey } from "services/jsons";

export default function useCrud() {
  const [img, setImg] = useState(undefined);
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const types = [
    { value: "carousel", label: "Карусель" },
    { value: "search", label: "Топ" },
    {
      value: "carousel_search",
      label: "Топ + Карусель",
    },
  ];

  const { isLoading: load } = useQuery(
    [ApiSiteKey.getTarif],
    () => getsApi.getTarifById({ id }),

    {
      select: (data) => {
        return data?.data;
      },
      onSuccess: (data) => {
        reset({
          ...data,
          type: types?.find((x) => x?.value === data?.type),
        });
      },
      enabled: id !== undefined ? true : false,
    }
  );

  const { mutate, isLoading: loading } = useMutation(
    (data) =>
      id === undefined ? postApi.addTarifs(data) : putApi.editTarif(data),
    {
      onSuccess: (data) => {
        navigate("/tarifs");
        notification["success"]({
          message: `${id !== undefined ? "Отредактировано" : "Созданный"}`,
        });
      },
      onError: () => {
        notification["error"]({
          message: "Ошибка",
        });
      },
    }
  );

  function sbmt(info) {
    let data = {
      days: +info.days,
      price: +info.price,
      times: +info.times,
      type: info?.type?.value,
    };

    mutate(id === undefined ? data : { ...data, id });
  }

  let loader = (id !== undefined ? load : false) || loading;

  return {
    sbmt,
    handleSubmit,
    control,
    reset,
    loader,
    errors,
    img,
    setImg,
    types,
  };
}
