import { Col, Row } from "antd";
import { InputComponent } from "components/inputs/input";
import { ErrorP, LabelInp } from "components/inputs/input/style";
import React from "react";
import { Wrapper } from "./style";
import { MyTitle } from "components/hrWrapper/style";
import ButtonComponent from "components/buttons";
import useCrud from "./hooks/useCrud";
import FileCustom from "components/inputs/file";
import LoaderComponent from "components/loader";
import { useNavigate, useParams } from "react-router-dom";
import { TextareaComponent } from "components/inputs/textarea";
import UseFormSelectComponent from "components/inputs/select";
import useJsons, { StatusInvestment } from "services/jsons";
import { useFieldArray } from "react-hook-form";
import { AppstoreAddOutlined, DeleteOutlined } from "@ant-design/icons";
import MapSection from "./map";
import FileUpload from "./fileupload";

export default function CrudInvestment() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { Province, Regions } = useJsons();

  const { sbmt, form, img, setImg, loader, setLocation, file, setFile } =
    useCrud();
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = form;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "additional",
  });

  return (
    <Wrapper>
      {loader && <LoaderComponent />}
      <MyTitle>
        <h2>{id === undefined ? "Создавать" : "Редактирование"} Инвестиции</h2>
        <ButtonComponent className="back" onClick={() => navigate(-1)}>
          Назад
        </ButtonComponent>
      </MyTitle>
      <form onSubmit={handleSubmit(sbmt)}>
        <Row gutter={[16, 16]}>
          <Col span={12} md={12}>
            <InputComponent
              control={control}
              nameProps="title"
              plProps="Имя"
              type="text"
              required={true}
              minLength={4}
              className={
                errors && errors?.hasOwnProperty("title") ? "input-error" : " "
              }
            />
            {errors && errors?.hasOwnProperty("title") && (
              <ErrorP>Это поле является обязательным!</ErrorP>
            )}
          </Col>
          <Col span={24} lg={12}>
            <UseFormSelectComponent
              control={control}
              required={true}
              name="status"
              placeholder="Статус"
              options={StatusInvestment}
              disabled={false}
              className={
                errors && errors?.hasOwnProperty("status")
                  ? "input-error select"
                  : " select "
              }
            />
            {errors && errors?.hasOwnProperty("status") && (
              <ErrorP>
                <ErrorP>Это поле является обязательным!</ErrorP>
              </ErrorP>
            )}
          </Col>
          <Col span={24} lg={12}>
            <UseFormSelectComponent
              control={control}
              required={true}
              name="region"
              placeholder="Провинция/город"
              options={Regions}
              disabled={false}
              className={
                errors && errors?.hasOwnProperty("region")
                  ? "input-error select"
                  : " select "
              }
            />
            {errors && errors?.hasOwnProperty("region") && (
              <ErrorP>
                <ErrorP>Это поле является обязательным!</ErrorP>
              </ErrorP>
            )}
          </Col>
          <Col span={24} lg={12}>
            <UseFormSelectComponent
              control={control}
              required={true}
              name="district"
              placeholder="Районы"
              options={Province?.filter(
                (x) => x?.regionid === watch("region")?.value
              )}
              disabled={false}
              className={
                errors && errors?.hasOwnProperty("district")
                  ? "input-error select"
                  : " select "
              }
            />
            {errors && errors?.hasOwnProperty("district") && (
              <ErrorP>
                <ErrorP>Это поле является обязательным!</ErrorP>
              </ErrorP>
            )}
          </Col>
          <MapSection setLocation={setLocation} form={form} />
          <Col span={12} md={12}>
            <InputComponent
              control={control}
              nameProps="area"
              plProps="Общая площадь (m²)"
              type="number"
              required={true}
              className={
                errors && errors?.hasOwnProperty("area") ? "input-error" : " "
              }
            />
            {errors && errors?.hasOwnProperty("area") && (
              <ErrorP>Это поле является обязательным!</ErrorP>
            )}
          </Col>
          <Col span={12} md={12}>
            <InputComponent
              control={control}
              nameProps="pending_investment"
              plProps="Ожидаемые инвестиции (млн сум)"
              type="number"
              required={true}
              className={
                errors && errors?.hasOwnProperty("pending_investment")
                  ? "input-error"
                  : " "
              }
            />
            {errors && errors?.hasOwnProperty("pending_investment") && (
              <ErrorP>Это поле является обязательным!</ErrorP>
            )}
          </Col>
          <Col span={12} md={12}>
            <InputComponent
              control={control}
              nameProps="incoming_investment"
              plProps="Получено инвестиций (млн сум)"
              type="number"
              required={true}
              className={
                errors && errors?.hasOwnProperty("incoming_investment")
                  ? "input-error"
                  : " "
              }
            />
            {errors && errors?.hasOwnProperty("incoming_investment") && (
              <ErrorP>Это поле является обязательным!</ErrorP>
            )}
          </Col>
          <Col span={12} md={12}>
            <InputComponent
              control={control}
              nameProps="total_pecentage"
              plProps="Обшая прибыль (в %)"
              type="number"
              required={true}
              max={100}
              className={
                errors && errors?.hasOwnProperty("total_pecentage")
                  ? "input-error"
                  : " "
              }
            />
            {errors && errors?.hasOwnProperty("total_pecentage") && (
              <ErrorP>Это поле является обязательным!</ErrorP>
            )}
          </Col>
          <Col span={12} md={12}>
            <InputComponent
              control={control}
              nameProps="divident_pecentage"
              plProps="Дивидендная прибыль (в %)"
              type="number"
              required={true}
              max={100}
              className={
                errors && errors?.hasOwnProperty("divident_pecentage")
                  ? "input-error"
                  : " "
              }
            />
            {errors && errors?.hasOwnProperty("divident_pecentage") && (
              <ErrorP>Это поле является обязательным!</ErrorP>
            )}
          </Col>

          <Col span={12} md={8}>
            <TextareaComponent
              control={control}
              nameProps="description"
              plProps="Описание"
              type="text"
              minLength={4}
              required={true}
              className={
                errors && errors?.hasOwnProperty("description")
                  ? "input-error"
                  : " "
              }
            />
            {errors && errors?.hasOwnProperty("description") && (
              <ErrorP>Это поле является обязательным!</ErrorP>
            )}
          </Col>
          <Col span={12} md={5}>
            <LabelInp>Изображение (размер: 960x560)</LabelInp>
            <FileCustom imageUrl={img} setImageUrl={setImg} />
          </Col>
          <Col span={12} md={5}>
            <LabelInp>Загрузить презентаци: </LabelInp>
            <FileUpload file={file} setFile={setFile} />
          </Col>

          <Col span={24}>
            <h3>Дополнительная информация</h3>
          </Col>

          {fields?.map((field, index) => (
            <Col key={field?.id} span={24} md={12}>
              <div
                style={{
                  backgroundColor: "#f8f8f882",
                  borderRadius: 4,
                  padding: "8px 12px",
                }}
              >
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <InputComponent
                      control={control}
                      nameProps={`additional.${index}.title`}
                      plProps="Заголовок"
                      type="text"
                      required={true}
                      minLength={4}
                      className={
                        errors && errors?.additional?.[index]?.title?.type
                          ? "input-error"
                          : " "
                      }
                    />
                    {errors && errors?.additional?.[index]?.title?.type && (
                      <ErrorP>Это поле является обязательным!</ErrorP>
                    )}
                  </Col>

                  <Col span={24}>
                    <TextareaComponent
                      control={control}
                      nameProps={`additional.${index}.description`}
                      plProps="Описание"
                      type="text"
                      minLength={4}
                      required={true}
                      className={
                        errors && errors?.additional?.[index]?.description?.type
                          ? "input-error"
                          : " "
                      }
                    />
                    {errors &&
                      errors?.additional?.[index]?.description?.type && (
                        <ErrorP>Это поле является обязательным!</ErrorP>
                      )}
                  </Col>
                </Row>
              </div>
            </Col>
          ))}
          <Col span={24}>
            <div
              style={{ maxWidth: "120px", marginLeft: "auto" }}
              className="buttons"
            >
              <ButtonComponent
                onClick={() => remove(-1)}
                className="my-btn remove"
              >
                <DeleteOutlined />
              </ButtonComponent>
              <ButtonComponent onClick={() => append()} className="my-btn">
                <AppstoreAddOutlined />
              </ButtonComponent>
            </div>
          </Col>

          <Col
            span={24}
            md={6}
            style={{ marginLeft: "auto", marginTop: "24px" }}
          >
            <ButtonComponent type="submit">
              {id === undefined ? "Создавать" : "Редактирование"}
            </ButtonComponent>
          </Col>
        </Row>
      </form>
    </Wrapper>
  );
}
