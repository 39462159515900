import { useQuery } from "@tanstack/react-query";
import { getsApi } from "services/api/pagesApi";
import { ApiSiteKey } from "services/jsons";

export default function useData() {
  const { data = undefined, isLoading } = useQuery(
    [ApiSiteKey.getAnalytics],
    () => getsApi.getAnalytics(),

    {
      select: (data) => {
        return data?.data;
      },
    }
  );
  const { data: allData = undefined, isLoading: allLoading } = useQuery(
    [ApiSiteKey.getAllAnalytics],
    () => getsApi.getAllAnalytics(),

    {
      select: (data) => {
        return data?.data;
      },
    }
  );

  let loading = isLoading || allLoading;

  return { data, columns: [], loading, allData };
}
