import { CloseOutlined, EyeFilled, StarOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Badge, Button, Card, Tag, notification } from "antd";
import { Image } from "components/image/style";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getsApi } from "services/api/pagesApi";
import { ApiSiteKey } from "services/jsons";
import IMAGEPL from "assets/placeholder-image.jpeg";
import { putApi } from "services/api/pagesApi";
export default function useData() {
  const [value, setValue] = useState("");
  const location = useLocation();
  const { search = "?offset=0&limit=10" } = location;
  const navigate = useNavigate();
  const { refetch, data, isLoading } = useQuery(
    [ApiSiteKey.getCatalogs, search],
    () => getsApi.getUserList(search, value),

    {
      select: (data) => {
        const info = {
          param: data?.data?.pagination,
          data: data?.data?.list?.account_list?.map((x) => {
            return {
              ...x,
              key: x?.id?.replaceAll("-", ""),
            };
          }),
        };
        return info;
      },
      onSuccess: (data) => {
        console.log(data);
      },
    }
  );

  const { mutate, isLoading: premiumLoading } = useMutation(
    (data) => putApi.premium(data?.id, data?.type),
    {
      onSuccess: () => {
        refetch();
      },
      onError: (er) => {
        notification["error"]({
          message: "Ошибка",
          description: er?.message ?? er?.msg ?? "Ошибка",
        });
      },
    }
  );

  function handleSearch() {
    navigate({ pathname: "/users", search: "?offset=0&limit=10" });
    refetch();
  }

  const columns = [
    {
      title: "Имя",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "Фамилия",
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      title: "Количество объявлений",
      dataIndex: "all_post",
      key: "all_post",
    },
    {
      title: "Количество активных объявлений",
      dataIndex: "active_post",
      key: "active_post",
    },
    {
      title: "Количество платных объявлений",
      dataIndex: "paid_post",
      key: "paid_post",
    },

    {
      title: "Фото",
      dataIndex: "image",
      key: "image",
      render: (x, row) =>
        x === "" || x === " " ? (
          <>
            {row?.is_privileged ? (
              <Badge.Ribbon text="Special">
                <Image className="img-account" src={IMAGEPL} />
              </Badge.Ribbon>
            ) : (
              <Image className="img-account" src={IMAGEPL} />
            )}
          </>
        ) : (
          <div
            style={{
              padding: 4,
              backgroundColor: "#fff",
              maxWidth: 150,
              textAlign: "center",
            }}
          >
            {row?.is_privileged ? (
              <Badge.Ribbon text="Special">
                <Image width={"100%"} src={x} />
              </Badge.Ribbon>
            ) : (
              <Image width={"100%"} src={x} />
            )}
          </div>
        ),
    },

    {
      title: "Действие",
      dataIndex: "Action",
      key: "Action",
      render: (id, row) => (
        <>
          <div className="btnWrapper-list" key={row.id}>
            {row?.is_privileged ? (
              <Button
                style={{ marginRight: 8 }}
                icon={<CloseOutlined />}
                onClick={() => mutate({ id: row?.id, type: false })}
              />
            ) : (
              <Button
                style={{ marginRight: 8 }}
                icon={<StarOutlined />}
                type="primary"
                onClick={() => mutate({ id: row?.id, type: true })}
              />
            )}
            <Button
              style={{ marginRight: 8 }}
              type="primary"
              icon={<EyeFilled />}
              onClick={() =>
                navigate(
                  `/users/${row?.id}$@@$___$@@$${
                    row?.first_name + " " + row?.last_name
                  }`
                )
              }
            >
              История платежей
            </Button>
          </div>
        </>
      ),
    },
  ].filter((item) => !item.hidden);

  return {
    data: data,
    columns,
    handleSearch,
    isLoading: isLoading || premiumLoading,
    value,
    setValue,
  };
}
