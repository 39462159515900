import Api from "../index";

class Deletes extends Api {
  del(id) {
    return this.execute(
      "delete",
      `boss/admin-account/admin-delete?admin_id=${id}`
    );
  }
  delCatalog(id) {
    return this.execute("delete", `admin/catalog/delete?catalogId=${id}`);
  }
  delFaqType(id) {
    return this.execute(
      "delete",
      `admin/faq-category/delete?FaqCategoryId=${id}`
    );
  }
  delFaq(id) {
    return this.execute("delete", `admin/faq/delete?FaqId=${id}`);
  }
  delSubCatalog(id) {
    return this.execute("delete", `admin/subcatalog/delete?subcatalogId=${id}`);
  }
  delInvestment(id) {
    return this.execute("delete", `admin/investment/delete?investId=${id}`);
  }
  delPartner(id) {
    return this.execute("delete", `admin/partner/delete?PartnerId=${id}`);
  }
}

export default Deletes;
