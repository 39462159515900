import styled from 'styled-components';

export const TableWrapper = styled.div`
  .ant-table {
    color: rgba(0, 0, 0, 0.85);
    background: transparent;

    &-content {
      border-radius: 6px !important;
      background: #fff;
    }
  }

  .ant-table-thead > tr > th {
    background: #fbfbfb;
  }

  .ant-pagination-item-link {
    border-radius: 6px;
  }
  .ant-pagination-item {
    border-radius: 6px;
    &:hover {
      border-color: var(--green);
      a {
        color: var(--green);
      }
    }
  }

  .ant-pagination-next:focus-visible .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link,
  .ant-pagination-prev:focus-visible .ant-pagination-item-link,
  .ant-pagination-prev:hover .ant-pagination-item-link {
    color: var(--green);
    border-color: var(--green);
  }

  .ant-pagination-item-active {
    border-color: var(--green);
    a {
      color: var(--green);
    }
  }
`;
