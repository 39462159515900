import { Col, Row } from "antd";
import { WrapperChart } from "pages/payment/style";
import React, { useState } from "react";
import Chart from "react-apexcharts";

export default function AccountCharts({ data }) {
  console.log(data);
  const [userPost] = useState({
    options: {
      chart: {
        width: 380,
        type: "pie",
      },
      labels: data?.userPosts?.Label?.map((x) =>
        x === "paidUserPosts"
          ? "Количество платных объявлений"
          : "Количество обычных объявлений"
      ),
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
    series: data?.userPosts?.Data,
  });

  const [typeacountpost] = useState({
    options: {
      chart: {
        width: 380,
        type: "pie",
      },
      labels: data?.accountTypePosts?.Label?.map((x) =>
        x === "maklerPosts"
          ? "Количество объявлений Маклер"
          : "Количество обычных объявлений"
      ),
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
    series: data?.accountTypePosts?.Data,
  });

  const [maklerpost] = useState({
    options: {
      chart: {
        width: 380,
        type: "pie",
      },
      labels: data?.maklerPosts?.Label?.map((x) =>
        x == "paidMaklerPosts" ? "Платная реклама" : "Простые объявления"
      ),
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
    series: data?.maklerPosts?.Data,
  });

  const [Typepost] = useState({
    options: {
      chart: {
        width: 380,
        type: "pie",
      },
      labels: data?.TypePosts?.Label?.map((x) =>
        x == "customers" ? "Объявления о продаже" : "Объявления о покупке"
      ),
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
    series: data?.TypePosts?.Data,
  });
  const stsPosts = {
    active: "активный",
    deactive: "неактивный",
    waiting: "ожидающий",
    rejected: "отклоненный",
    search: "Топ",
    carousel: "карусель",
    searchCarousel: "Топ + карусель",
  };
  const [stspost] = useState({
    options: {
      chart: {
        width: 380,
        type: "pie",
      },
      labels: data?.SitePosts?.Label?.map(
        (x) => stsPosts[x]?.toLocaleUpperCase() ?? x
      ),
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
    series: data?.SitePosts?.Data,
  });

  return (
    <>
      <Row gutter={[24, 24]} align={"middle"}>
        <Col span={24} md={12}>
          <WrapperChart>
            <h3 style={{ margin: 0, marginBottom: 8 }}>
              Общее количество объявлений: {data?.AllPosts}
            </h3>
            <Chart
              options={typeacountpost.options}
              series={typeacountpost.series}
              type={"pie"}
            />
          </WrapperChart>
        </Col>

        <Col span={24} md={12}>
          <WrapperChart>
            <h3 style={{ margin: 0, marginBottom: 8 }}>
              Всего объявлений обычных пользователей: {data?.AllUserPosts}
            </h3>
            <Chart
              options={userPost.options}
              series={userPost.series}
              type={"pie"}
            />
          </WrapperChart>
        </Col>

        <Col span={24} md={12}>
          <WrapperChart>
            <h3 style={{ margin: 0, marginBottom: 8 }}>
              Общее количество объявлений Маклер: {data?.AllMaklerPosts} ta
            </h3>
            <Chart
              options={maklerpost.options}
              series={maklerpost.series}
              type={"pie"}
            />
          </WrapperChart>
        </Col>

        <Col span={24} md={12}>
          <WrapperChart>
            <h3 style={{ margin: 0, marginBottom: 8 }}>Типы объявлений</h3>
            <Chart
              options={Typepost.options}
              series={Typepost.series}
              type={"pie"}
            />
          </WrapperChart>
        </Col>
        <Col span={24} md={12}>
          <WrapperChart>
            <h3 style={{ margin: 0, marginBottom: 8 }}>
              Статистика по статусам объявлений
            </h3>
            <Chart
              options={stspost.options}
              series={stspost.series}
              type={"pie"}
            />
          </WrapperChart>
        </Col>
      </Row>
    </>
  );
}
