import ButtonComponent from "components/buttons";
import { MyTitle } from "components/hrWrapper/style";
import TableComponent from "components/table";
import React from "react";
import { useNavigate } from "react-router-dom";
import useData from "./components/hooks/usedata";
import { Wrapper } from "./style";
export default function Catalog() {
  const navigate = useNavigate();
  const { data, columns, loading } = useData();
  return (
    <Wrapper>
      <MyTitle>
        <h2>Страница категорий</h2>
        {/* <ButtonComponent onClick={() => navigate("create")}>
          Создавать
        </ButtonComponent> */}
      </MyTitle>

      <TableComponent isloading={loading} data={data} columns={columns} />
    </Wrapper>
  );
}

// const catalogs = {
//   status: "OK",
//   description: "The request has succeeded",
//   data: [
//     {
//       id: "0c62b148-ef78-41e5-9b77-7a45292dcc26",
//       name: "Коммерческие помещения",
//       description: "Коммерческие помещения",
//       image: " ",
//       image_link: "",
//       key: "commercial-premises",
//     },
//     {
//       id: "21bc4c77-51d8-453f-a358-db7dc6e3e9ef",
//       name: "Гаражи/Парковка",
//       description: "Гаражи/Парковка",
//       image: " ",
//       image_link: "",
//       key: "garages-parking",
//     },
//     {
//       id: "d20170ee-9444-478e-a88e-0050eec9f297",
//       name: "Земли и участки",
//       description: "Земли и участки",
//       image: " ",
//       image_link: "",
//       key: "lands-and-plots",
//     },
//     {
//       id: "6e912315-c758-4a7c-9aba-b62dd5bbf73b",
//       name: "Дом       ",
//       description: "Дом.        ",
//       image: " ",
//       image_link: "",
//       key: "house",
//     },
//     {
//       id: "8d260613-0804-49e4-8c6e-14be5b481a44",
//       name: "Квартиры",
//       description: "Квартиры",
//       image: " ",
//       image_link: "",
//       key: "apartments",
//     },
//     {
//       id: "3cbd92a1-dbea-41d9-bb12-147a7aa923d7",
//       name: "Посуточная аренда",
//       description: "Посуточная аренда",
//       image: " ",
//       image_link: "",
//       key: "daily-rent",
//     },
//   ],
// };

// const subcatalogs = {
//   status: "OK",
//   description: "The request has succeeded",
//   data: [
//     {
//       id: "abb4e19f-575d-43c0-a53e-04e68ea8dcc2",
//       name: "Продажа",
//       description: "Продажа",
//       image: " ",
//       image_link: "",
//       key: "commercial-premises-sale",
//     },
//     {
//       id: "65b31ed9-87df-4142-be7a-4136f77340cb",
//       name: "Арендовать",
//       description: "Арендовать",
//       image: " ",
//       image_link: "",
//       key: "commercial-premises-rent",
//     },
//     {
//       id: "88e2c89c-3475-4f73-803a-b193e7a2481a",
//       name: "Продажа",
//       description: "Распродажа",
//       image: " ",
//       image_link: "",
//       key: "garages-parking-sale",
//     },
//     {
//       id: "386bd782-7ac3-4a0d-91ff-35dd543f3841",
//       name: "Арендовать",
//       description: "Арендовать",
//       image: " ",
//       image_link: "",
//       key: "garages-parking-rent",
//     },
//     {
//       id: "f8aa2745-fe7c-4ef6-9d5a-018f3b9d4f16",
//       name: "Распродажа",
//       description: "Распродажа",
//       image: " ",
//       image_link: "",
//       key: "lands-and-plots-sale",
//     },
//     {
//       id: "93b4464e-32ba-4eb1-9d54-39fe46cd845d",
//       name: "Арендовать",
//       description: "Арендовать",
//       image: " ",
//       image_link: "",
//       key: "lands-and-plots-rent",
//     },
//     {
//       id: "ad4fe3fc-83de-46e8-a8fd-416ea37e1b9e",
//       name: "Обмен",
//       description: "Обмен",
//       image: " ",
//       image_link: "",
//       key: "house-exchange",
//     },
//     {
//       id: "7c1d5287-6cc8-4294-a53a-2b98fcd64069",
//       name: "Распродажа",
//       description: "Распродажа",
//       image: " ",
//       image_link: "",
//       key: "house-sale",
//     },
//     {
//       id: "44d657fe-8dd4-46f9-97d5-428d0d975622",
//       name: "Долгосрочная аренда",
//       description: "Долгосрочная аренда",
//       image: " ",
//       image_link: "",
//       key: "house-long-term-rental",
//     },
//     {
//       id: "9ad1c29d-75f9-4c64-a15a-68698f7013f2",
//       name: "Обмен",
//       description: "Обмен",
//       image: " ",
//       image_link: "",
//       key: "apartments-exchange",
//     },
//     {
//       id: "99fbf1b5-8a54-4197-80ae-dcccdaf3413a",
//       name: "Распродажа",
//       description: "Распродажа",
//       image: " ",
//       image_link: "",
//       key: "apartments-sale",
//     },
//     {
//       id: "a931bab8-219b-4d2d-88d5-77fcb24a10f5",
//       name: "Долгосрочная аренда",
//       description: "Долгосрочная аренда",
//       image: " ",
//       image_link: "",
//       key: "apartments-long-term-rental",
//     },
//     {
//       id: "3ba8e316-0a9f-496a-a2f7-4ca624f6db59",
//       name: "Виллы",
//       description: "Виллы",
//       image: " ",
//       image_link: "",
//       key: "daily-rent-villa",
//     },
//     {
//       id: "911feddb-421c-407e-b47e-799a4d08143f",
//       name: "Гостиницы",
//       description: "Гостиницы",
//       image: " ",
//       image_link: "",
//       key: "daily-rent-hotels",
//     },
//     {
//       id: "606c9d6d-57eb-4294-8487-37a75f8766aa",
//       name: "Квартиры",
//       description: "Квартиры",
//       image: " ",
//       image_link: "",
//       key: "daily-rent-apartment",
//     },
//     {
//       id: "338c31ca-3394-4071-9afa-1a928121e8ac",
//       name: "Санатории",
//       description: "Санатории",
//       image: " ",
//       image_link: "",
//       key: "daily-rent-sanatoriums",
//     },
//     {
//       id: "83d4a54d-2f6b-4a0b-85ba-5dc6eceea8cb",
//       name: "Хостелы",
//       description: "Хостелы",
//       image: " ",
//       image_link: "",
//       key: "daily-rent-hostel",
//     },
//   ],
// };
