import { MyTitle } from "components/hrWrapper/style";
import TableComponent from "components/table";
import React from "react";
import useData from "./components/hooks/usedata";
import { Wrapper } from "./style";
import ButtonComponent from "components/buttons";
import { useNavigate } from "react-router-dom";
export default function ApplicationInvestment() {
  const { data, columns, loading } = useData();
  const navigate = useNavigate();
  return (
    <Wrapper>
      <MyTitle>
        <h2>СТРАНИЦА заЯвлений</h2>
        <ButtonComponent
          className="back"
          onClick={() => navigate("/investment")}
        >
          Назад
        </ButtonComponent>
      </MyTitle>

      <TableComponent
        params={data?.param}
        isloading={loading}
        data={data?.data}
        columns={columns}
      />
    </Wrapper>
  );
}
