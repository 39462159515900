import { FullProvider } from './style';
import Routers from 'routes';
import 'antd/dist/antd.min.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'style/index.css';
function App() {
  return (
    <>
      <FullProvider>
        <Routers />
      </FullProvider>
    </>
  );
}

export default App;
