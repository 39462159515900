import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, message, Upload } from "antd";

import React from "react";
import { useState } from "react";
const FileUpload = ({ file, setFile }) => {
  const [show, setShow] = useState(true);
  const props = {
    name: "file",
    multiple: false,
    action: process.env.REACT_APP_API_ROOT + "upload/pdf",

    headers: {
      Authorization: `Bearer ${localStorage.getItem("RIZOADMINTOKEN")}`,
    },

    beforeUpload: (file) => {
      const isPNG = file.type === "application/pdf";
      if (!isPNG) {
        message.error(`${file.name} это не pdf файл`);
      }
      return isPNG || Upload.LIST_IGNORE;
    },
    onChange(info) {
      console.log(info, "info");
      if (info.file.status !== "загрузка") {
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} Файл успешно загружен`);
        setFile([
          {
            uid: info.file.response?.data,
            name: info.file.response?.data,
            status: "done",
            title: info.file.response?.data,
          },
        ]);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} загрузка файла не удалась.`);
      }
    },
  };

  return (
    <>
      {file[0]?.url && show ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "8px 8px 8px 10px",
            border: "1px solid #eee",
            borderRadius: 6,
          }}
        >
          <a target="_blank" rel="noreferrer" href={file[0]?.url}>
            File.pdf
          </a>
          <Button
            onClick={() => setShow(false)}
            type="default"
            icon={<DeleteOutlined />}
          />
        </div>
      ) : (
        <Upload {...props} maxCount={1}>
          <Button icon={<UploadOutlined />}>Загрузить</Button>
        </Upload>
      )}
    </>
  );
};
export default FileUpload;
