import { Col, Row } from "antd";
import { InputComponent } from "components/inputs/input";
import { ErrorP } from "components/inputs/input/style";
import React from "react";
import { Wrapper } from "./style";
import { MyTitle } from "components/hrWrapper/style";
import ButtonComponent from "components/buttons";
import useCrud from "./hooks/useCrud";
import LoaderComponent from "components/loader";
import { useNavigate, useParams } from "react-router-dom";
import { TextareaComponent } from "components/inputs/textarea";
import UseFormSelectComponent from "components/inputs/select";

export default function CrudFaq() {
  const navigate = useNavigate();
  const { id } = useParams();

  const { sbmt, handleSubmit, control, loader, errors, img, setImg, faqtype } =
    useCrud();

  return (
    <Wrapper>
      {loader && <LoaderComponent />}
      <MyTitle>
        <h2>FAQ Тип {id === undefined ? "Создавать" : "Редактирование"}</h2>
        <ButtonComponent className="back" onClick={() => navigate(-1)}>
          Назад
        </ButtonComponent>
      </MyTitle>
      <form onSubmit={handleSubmit(sbmt)}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <UseFormSelectComponent
              control={control}
              required={true}
              name="category_id"
              placeholder={"Выберите тип FAQ"}
              options={faqtype}
              disabled={false}
              className={
                errors && errors?.hasOwnProperty("category_id")
                  ? "input-error select"
                  : " select "
              }
            />
            {errors && errors?.hasOwnProperty("category_id") && (
              <ErrorP>
                <ErrorP>Это поле является обязательным!</ErrorP>
              </ErrorP>
            )}
          </Col>
          <Col span={24} lg={12}>
            <InputComponent
              control={control}
              nameProps="question_uz"
              plProps="Вопрос (узбекский)"
              type="text"
              required={true}
              minLength={4}
              className={
                errors && errors?.hasOwnProperty("question_uz")
                  ? "input-error"
                  : " "
              }
            />
            {errors && errors?.hasOwnProperty("question_uz") && (
              <ErrorP>Это поле является обязательным!</ErrorP>
            )}
          </Col>
          <Col span={24} lg={12}>
            <InputComponent
              control={control}
              nameProps="question_ru"
              plProps="Вопрос (русский)"
              type="text"
              required={true}
              minLength={4}
              className={
                errors && errors?.hasOwnProperty("question_ru")
                  ? "input-error"
                  : " "
              }
            />
            {errors && errors?.hasOwnProperty("question_ru") && (
              <ErrorP>Это поле является обязательным!</ErrorP>
            )}
          </Col>
          <Col span={24} lg={12}>
            <InputComponent
              control={control}
              nameProps="question_en"
              plProps="Вопрос (en)"
              type="text"
              required={true}
              minLength={4}
              className={
                errors && errors?.hasOwnProperty("question_en")
                  ? "input-error"
                  : " "
              }
            />
            {errors && errors?.hasOwnProperty("question_en") && (
              <ErrorP>Это поле является обязательным!</ErrorP>
            )}
          </Col>
          <Col span={24} md={12}>
            <TextareaComponent
              control={control}
              nameProps="answer_uz"
              plProps="Ответы (узбекский)"
              type="text"
              minLength={4}
              className={
                errors && errors?.hasOwnProperty("answer_uz")
                  ? "input-error"
                  : " "
              }
            />
          </Col>
          <Col span={24} md={12}>
            <TextareaComponent
              control={control}
              nameProps="answer_ru"
              plProps="Ответы (русский)"
              type="text"
              minLength={4}
              className={
                errors && errors?.hasOwnProperty("answer_ru")
                  ? "input-error"
                  : " "
              }
            />
          </Col>
          <Col span={24} md={12}>
            <TextareaComponent
              control={control}
              nameProps="answer_en"
              plProps="Ответы (en)"
              type="text"
              minLength={4}
              className={
                errors && errors?.hasOwnProperty("answer_en")
                  ? "input-error"
                  : " "
              }
            />
          </Col>
          <Col
            span={24}
            md={6}
            style={{ marginLeft: "auto", marginTop: "24px" }}
          >
            <ButtonComponent type="submit">
              {id === undefined ? "Создавать" : "Редактирование"}
            </ButtonComponent>
          </Col>
        </Row>
      </form>
    </Wrapper>
  );
}
