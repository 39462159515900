import { useMutation, useQuery } from "@tanstack/react-query";
import { notification } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { getsApi, postApi, putApi } from "services/api/pagesApi";
import { ApiSiteKey, StatusInvestment } from "services/jsons";

export default function useCrud() {
  const [img, setImg] = useState(undefined);
  const [location, setLocation] = useState("");
  const [imgLink, setImgLink] = useState("");
  const [file, setFile] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();

  const form = useForm({
    defaultValues: {
      additional: [
        {
          title: "",
          description: "",
        },
      ],
    },
  });

  const { mutate: fileMutate, isLoading: fileLoading } = useMutation(
    // (data) => request.post("minio/upload/image", data),
    (data) => postApi.addImage(data),
    {
      onSuccess: (res) => {
        setImgLink(res?.data);
        notification["success"]({
          message: "Файл загружен",
        });
      },
      onError: (er) => {
        notification["error"]({
          message: "Ошибка",
          description: er?.message ?? "При загрузке файла произошла ошибка",
        });
      },
    }
  );

  const { isLoading: load } = useQuery(
    [ApiSiteKey.getInvestmentsById],
    () => getsApi.getInvestmentById({ id }),

    {
      select: (data) => {
        return data?.data;
      },
      onSuccess: (data) => {
        form.reset({
          ...data,
          status: StatusInvestment?.find((x) => x?.value == data?.status),
          district: data?.location.district,
          region: data?.location.region,
          additional: data?.additional?.data,
          divident_pecentage: data?.percentage?.divident_pecentage,
          total_pecentage: data?.percentage?.total_pecentage,
          incoming_investment: data?.price?.incoming_investment,
          pending_investment: data?.price?.pending_investment,
        });
        setImg(data?.image[0]);
        setFile([
          {
            uid: data?.pdfName,
            name: data?.pdfName,
            status: "done",
            url: data?.pdfLink,
            title: data?.pdfName,
          },
        ]);
      },
      enabled: id !== undefined ? true : false,
    }
  );

  const { mutate, isLoading: loading } = useMutation(
    (data) =>
      id === undefined
        ? postApi.addInvestment(data)
        : putApi.editInvestment(data),
    {
      onSuccess: (data) => {
        navigate("/investment");
        notification["success"]({
          message: `Инвестиции ${id ? "отредактировано" : "добавлен"}`,
        });
      },
      onError: () => {
        notification["error"]({
          message: "Ошибка",
        });
      },
    }
  );

  useEffect(() => {
    if (img != undefined && typeof img !== "string") {
      const formdata = new FormData();
      formdata.append("file", img);
      fileMutate(formdata);
    }
  }, [img]);

  function sbmt(info) {
    const {
      district,
      additional,
      incoming_investment,
      pending_investment,
      divident_pecentage,
      total_pecentage,
      region,
      status,
      ...rest
    } = info;

    let data = {
      ...rest,
      status: status?.value.toString(),
      image: [imgLink],
      pdfName: file[0]?.title,
      location: {
        district,
        region,
        cordinates: location,
      },
      additional: {
        data: additional,
      },
      price: {
        pending_investment,
        incoming_investment,
      },
      percentage: {
        divident_pecentage,
        total_pecentage,
      },
    };

    mutate(data);
  }

  let loader = fileLoading || (id !== undefined ? load : false) || loading;
  return {
    sbmt,
    setLocation,
    loader,
    img,
    setImg,
    form,
    file,
    setFile,
  };
}
