import { MyTitle } from "components/hrWrapper/style";
import TableComponent from "components/table";
import React from "react";
import useData from "./components/hooks/usedata";
import { Wrapper } from "./style";
import ButtonComponent from "components/buttons";
import { useNavigate } from "react-router-dom";
export default function PaymentHistory() {
  const { data, columns, isLoading, userInfo } = useData();
  const navigate = useNavigate();
  return (
    <Wrapper>
      <MyTitle>
        <h2>Платежная страница {userInfo[1]}</h2>

        <ButtonComponent className="back" onClick={() => navigate("/users")}>
          Назад
        </ButtonComponent>
      </MyTitle>

      <TableComponent
        isloading={isLoading}
        data={data?.data}
        columns={columns}
        params={data?.param}
      />
    </Wrapper>
  );
}
