import { DeleteOutlined, EditOutlined, EyeFilled } from "@ant-design/icons";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, Comment, Modal, Tag, Typography } from "antd";
import { Image } from "components/image/style";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DeletesApi, getsApi } from "services/api/pagesApi";
import { ApiSiteKey, StatusInvestment } from "services/jsons";
const { Paragraph } = Typography;

const { confirm } = Modal;
export default function useData() {
  const navigate = useNavigate();
  const location = useLocation();
  const { search = "?offset=0&limit=10" } = location;

  const { refetch, data, isLoading } = useQuery(
    [ApiSiteKey.getInvestments, search],
    () => getsApi.getInvestments(search),

    {
      select: (data) => {
        const info = {
          param: data?.data?.pagination,
          data: data?.data?.list?.map((x) => {
            return {
              ...x,
              key: x?.postId?.replaceAll("-", ""),
            };
          }),
        };
        return info;
      },
      onSuccess: (data) => {
        console.log(data);
      },
    }
  );

  function handleDel(id) {
    confirm({
      title: "Удалить",
      content: "Вы уверены, что хотите удалить?",
      onOk() {
        mutate(id);
      },
      onCancel() {
        console.log("close");
      },
      okText: "Удалить",
    });
  }

  const { mutate, isLoading: load } = useMutation(
    (id) => DeletesApi.delInvestment(id),
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  function handleEdit(id) {
    navigate(`edit/${id}`);
  }

  let loading = load || isLoading;

  const columns = [
    {
      title: "Заголовок",
      dataIndex: "title",
      key: "title",
    },
    // {
    //   title: "Описание",
    //   dataIndex: "description",
    //   key: "description",
    //   render: (x) => (
    //     <div
    //       style={{
    //         padding: 4,
    //         backgroundColor: "#fff",
    //         maxWidth: 300,
    //         textAlign: "center",
    //         borderRadius: 4,
    //       }}
    //     >
    //       <Paragraph ellipsis={{ rows: 7, expandable: true, symbol: "..." }}>
    //         {x}
    //       </Paragraph>
    //     </div>
    //   ),
    // },

    // {
    //   title: "Umumiy maydoni (gektarda)",
    //   dataIndex: "area",
    //   key: "area",
    // },

    {
      title: "Фото",
      dataIndex: "image",
      key: "image",
      render: (x) =>
        x?.length < 1 || (x?.length > 0 && x[0] === "") ? (
          <Tag style={{ borderRadius: 4 }}>Изображение не загружено</Tag>
        ) : (
          <div
            style={{
              padding: 4,
              backgroundColor: "#fff",
              maxWidth: 150,
              textAlign: "center",
            }}
          >
            <Image width={"100%"} src={x[0]} />
          </div>
        ),
    },

    {
      title: "Провинция/город",
      dataIndex: "location",
      key: "locationDistrict",
      render: ({ region }) => region?.label,
    },

    {
      title: "Районы",
      dataIndex: "location",
      key: "locationdistrict",
      render: ({ district }) => district?.label,
    },
    // {
    //   title: "Дополнительная информация",
    //   dataIndex: "additional",
    //   key: "additional",

    //   render: ({ data }) => (
    //     <>
    //       {data?.map((x, i) => (
    //         <div
    //           key={i}
    //           style={{
    //             padding: "0 8px",
    //             backgroundColor: "#fff",
    //             maxWidth: 300,
    //             minWidth: 900,
    //             textAlign: "left",
    //             margin: "4px 0",
    //             borderRadius: 4,
    //           }}
    //         >
    //           <Comment
    //             content={
    //               <>
    //                 <h4 style={{ fontWeight: "bold", textAlign: "left" }}>
    //                   {x?.title}
    //                 </h4>
    //                 <Paragraph ellipsis={{ rows: 2, expandable: true }}>
    //                   {x?.description}
    //                 </Paragraph>
    //               </>
    //             }
    //           />
    //         </div>
    //       ))}
    //     </>
    //   ),
    // },

    // {
    //   title: "Umumiy foyda (% da)",
    //   dataIndex: "percentage",
    //   key: "total_pecentage",
    //   render: ({ total_pecentage }) => total_pecentage,
    // },
    // {
    //   title: "Dividend foyda (% da)",
    //   dataIndex: "percentage",
    //   key: "divident_pecentage",
    //   render: ({ divident_pecentage }) => divident_pecentage,
    // },
    // {
    //   title: "Kutilayotgan investetsiya (mlrd UZSda)",
    //   dataIndex: "price",
    //   key: "pending_investment",
    //   render: ({ pending_investment }) => pending_investment,
    // },
    // {
    //   title: "Qabul qilingan investetsiya (mlrd UZSda)",
    //   dataIndex: "price",
    //   key: "incoming_investment",
    //   render: ({ incoming_investment }) => incoming_investment,
    // },
    {
      title: "Статус ",
      dataIndex: "status",
      key: "status",
      render: (x) => StatusInvestment?.find((a) => a?.value == x)?.label,
    },

    {
      title: "Действие",
      dataIndex: "Action",
      key: "Action",
      render: (id, row) => (
        <>
          <div className="btnWrapper-list" key={row.key}>
            <Button
              style={{ marginRight: 8 }}
              type="primary"
              icon={<EditOutlined />}
              onClick={() => handleEdit(row?.id)}
            />
            <Button
              style={{ marginRight: 8 }}
              type="link"
              icon={<EyeFilled />}
              onClick={() => navigate("/investment/" + row?.id)}
            />
            <Button
              type="dashed"
              onClick={() => handleDel(row?.id)}
              icon={<DeleteOutlined />}
            />
          </div>
        </>
      ),
    },
  ].filter((item) => !item.hidden);

  return { data, columns, loading };
}
