import { Col, Row } from "antd";
import { InputComponent } from "components/inputs/input";
import { CustomMask } from "components/inputs/mask";
import { ErrorP } from "components/inputs/input/style";
import React from "react";
import { Wrapper } from "./style";
import { MyTitle } from "components/hrWrapper/style";
import ButtonComponent from "components/buttons";
import useCrud from "./hooks/useCrud";
import FileCustom from "components/inputs/file";
import LoaderComponent from "components/loader";
import { useNavigate, useParams } from "react-router-dom";

export default function CrudSuperadmin() {
  const navigate = useNavigate();
  const { id } = useParams();

  const { sbmt, handleSubmit, control, loader, errors, img, setImg } =
    useCrud();

  return (
    <Wrapper>
      {loader && <LoaderComponent />}
      <MyTitle>
        <h2>
          {id === undefined ? "Создавать" : "Редактирование"} Администратор
        </h2>
        <ButtonComponent className="back" onClick={() => navigate(-1)}>
          Назад
        </ButtonComponent>
      </MyTitle>
      <form onSubmit={handleSubmit(sbmt)}>
        <Row gutter={[16, 16]}>
          <Col span={12} md={8}>
            <InputComponent
              control={control}
              nameProps="fullname"
              plProps="Имя"
              type="text"
              required={true}
              minLength={8}
              className={
                errors && errors?.hasOwnProperty("fullname")
                  ? "input-error"
                  : " "
              }
            />
            {errors && errors?.hasOwnProperty("fullname") && (
              <ErrorP>Это поле является обязательным!</ErrorP>
            )}
          </Col>

          <Col span={12} md={8}>
            <InputComponent
              control={control}
              nameProps="address"
              plProps="Адрес"
              minLength={8}
              type="text"
              required={true}
              className={
                errors && errors?.hasOwnProperty("address")
                  ? "input-error"
                  : " "
              }
            />
            {errors && errors?.hasOwnProperty("address") && (
              <ErrorP>Это поле является обязательным!</ErrorP>
            )}
          </Col>

          <Col span={12} md={8}>
            <InputComponent
              control={control}
              nameProps="username"
              plProps="Имя пользователя"
              minLength={8}
              type="username"
              required={true}
              className={
                errors && errors?.hasOwnProperty("username")
                  ? "input-error"
                  : " "
              }
            />
            {errors && errors?.hasOwnProperty("username") && (
              <ErrorP>Это поле является обязательным!</ErrorP>
            )}
          </Col>
          {id == undefined && (
            <Col span={12} md={8}>
              <InputComponent
                control={control}
                nameProps="password"
                plProps="Пароль"
                minLength={8}
                type="password"
                required={true}
                className={
                  errors && errors?.hasOwnProperty("password")
                    ? "input-error"
                    : " "
                }
              />
              {errors && errors?.hasOwnProperty("password") && (
                <ErrorP>Это поле является обязательным!</ErrorP>
              )}
            </Col>
          )}
          <Col span={12} md={8}>
            <CustomMask
              control={control}
              mask="+\9\98 (99) 999 99 99"
              nameProps="phone"
              placeholder="Номер телефона"
              required={true}
              className={
                errors && errors?.hasOwnProperty("phone") ? "input-error" : " "
              }
            />
            {errors && errors?.hasOwnProperty("phone") && (
              <ErrorP>Это поле является обязательным!</ErrorP>
            )}
          </Col>
          <Col span={12} md={4}>
            <InputComponent
              control={control}
              nameProps="email"
              plProps="Электронная почта"
              type="email"
              required={true}
              className={
                errors && errors?.hasOwnProperty("email") ? "input-error" : " "
              }
            />
            {errors && errors?.hasOwnProperty("email") && (
              <ErrorP>Это поле является обязательным!</ErrorP>
            )}
          </Col>
          <Col span={12} md={4}>
            <FileCustom imageUrl={img} setImageUrl={setImg} />
          </Col>
          <Col
            span={24}
            md={6}
            style={{ marginLeft: "auto", marginTop: "24px" }}
          >
            <ButtonComponent type="submit">Создавать</ButtonComponent>
          </Col>
        </Row>
      </form>
    </Wrapper>
  );
}
