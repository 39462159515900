import styled from "styled-components";

export const PhoneMaskProvider = styled.div`
  width: 92%;
`;
export const Wrapper = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
  & input {
    width: 100%;
    height: 35px;
    border: 1px solid var(--secondary);
    outline: none;
    background-color: transparent !important;
    border-radius: 4px !important;
    /* padding: 10px 12px 8px 16px !important; */
    color: var(--text-color) !important;
    font-size: 14px;
    margin-top: 6px;
    text-indent: 12px;
    &::placeholder {
      font-size: 14px;
      color: var(--secondary-text) !important;
    }
    &:focus {
      border: 1px solid var(--green) !important;
    }
    &.input-error {
      border: 1px solid var(--red) !important;
    }
    &:disabled {
      background-color: var(--secondary) !important;
    }
  }
`;
