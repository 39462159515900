import { Col, Row } from "antd";
import { WrapperChart } from "pages/payment/style";
import React, { useState } from "react";
import Chart from "react-apexcharts";

export default function Charts({ data, accountdata }) {
  const [IncomeData] = useState({
    options: {
      chart: {
        width: 380,
        type: "pie",
      },
      labels: data?.income?.Label?.map(
        (x) => `Оплачивается через ${x?.toLocaleUpperCase()}`
      ),
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
    series: data?.income?.Data,
  });

  const [outComeData] = useState({
    options: {
      chart: {
        width: 380,
        type: "pie",
      },
      labels: data?.outcome?.Label?.map((x) =>
        x?.toLocaleUpperCase()?.replaceAll("_", " ")
      ),
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
    series: data?.outcome?.Data,
  });

  const [LineData] = useState({
    series: [
      {
        name: "Доход (сум)",
        data: data?.Statistics?.Data,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      title: {
        text: "Годовая статистика",
        align: "left",
      },
      grid: {
        row: {
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: data?.Statistics?.Label?.map((x) => x?.toLocaleUpperCase()),
      },
    },
  });

  const [AccountData] = useState({
    options: {
      chart: {
        width: 380,
        type: "pie",
      },
      labels: accountdata?.ActiveAccounts?.Label?.map((x) =>
        x == "users" ? "Пользователи" : "Маклери"
      ),
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
    series: accountdata?.ActiveAccounts?.Data,
  });

  // const IncomeData = {
  //   options: {
  //     chart: {
  //       width: 380,
  //       type: "pie",
  //     },
  //     labels: data?.income?.Label?.map(
  //       (x) => `${x?.toLocaleUpperCase()} orqali to'ldirilgan`
  //     ),
  //     responsive: [
  //       {
  //         breakpoint: 480,
  //         options: {
  //           chart: {
  //             width: 200,
  //           },
  //           legend: {
  //             position: "bottom",
  //           },
  //         },
  //       },
  //     ],
  //   },
  //   series: data?.income?.Data,
  // };

  // const outComeData = {
  //   options: {
  //     chart: {
  //       width: 380,
  //       type: "pie",
  //     },
  //     labels: data?.outcome?.Label?.map((x) =>
  //       x?.toLocaleUpperCase()?.replaceAll("_", " ")
  //     ),
  //     responsive: [
  //       {
  //         breakpoint: 480,
  //         options: {
  //           chart: {
  //             width: 200,
  //           },
  //           legend: {
  //             position: "bottom",
  //           },
  //         },
  //       },
  //     ],
  //   },
  //   series: data?.outcome?.Data,
  // };

  // const LineData = {
  //   series: [
  //     {
  //       name: "Tushum (сум)",
  //       data: data?.Statistics?.Data,
  //     },
  //   ],
  //   options: {
  //     chart: {
  //       height: 350,
  //       type: "line",
  //       zoom: {
  //         enabled: false,
  //       },
  //     },
  //     dataLabels: {
  //       enabled: false,
  //     },
  //     stroke: {
  //       curve: "straight",
  //     },
  //     title: {
  //       text: "Yillik statistika",
  //       align: "left",
  //     },
  //     grid: {
  //       row: {
  //         opacity: 0.5,
  //       },
  //     },
  //     xaxis: {
  //       categories: data?.Statistics?.Label?.map((x) => x?.toLocaleUpperCase()),
  //     },
  //   },
  // };

  // const AccountData = {
  //   options: {
  //     chart: {
  //       width: 380,
  //       type: "pie",
  //     },
  //     labels: accountdata?.ActiveAccounts?.Label?.map((x) =>
  //       x == "users" ? "Пользователи" : "Маклер"
  //     ),
  //     responsive: [
  //       {
  //         breakpoint: 480,
  //         options: {
  //           chart: {
  //             width: 200,
  //           },
  //           legend: {
  //             position: "bottom",
  //           },
  //         },
  //       },
  //     ],
  //   },
  //   series: accountdata?.ActiveAccounts?.Data,
  // };

  return (
    <>
      {data && (
        <Row gutter={[24, 24]} align={"middle"}>
          <Col span={24} md={12}>
            <WrapperChart>
              <h3 style={{ margin: 0, marginBottom: 8 }}>
                Общий доход: {data?.total} сум
              </h3>
              <Chart
                options={IncomeData.options}
                series={IncomeData.series}
                type={"pie"}
              />
            </WrapperChart>
          </Col>
          <Col span={24} md={12}>
            <WrapperChart>
              <h3 style={{ margin: 0, marginBottom: 8 }}>
                Приобретенные тарифы (сум)
              </h3>
              <Chart
                options={outComeData.options}
                series={outComeData.series}
                type={"pie"}
              />
            </WrapperChart>
          </Col>
          <Col span={24} md={12}>
            <WrapperChart>
              <Chart
                options={LineData.options}
                series={LineData.series}
                type={"line"}
              />
            </WrapperChart>
          </Col>
          <Col span={24} md={12}>
            <WrapperChart>
              <h3 style={{ margin: 0, marginBottom: 8 }}>
                Всего Пользователи: {accountdata?.AllActiveAccounts}
              </h3>
              <Chart
                options={AccountData.options}
                series={AccountData.series}
                type={"pie"}
              />
            </WrapperChart>
          </Col>
        </Row>
      )}
    </>
  );
}
