import { QueryCache, QueryClient } from "@tanstack/react-query";
import { notification } from "antd";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
    },
  },
  queryCache: new QueryCache({
    onError: (error) => {
      console.log(error);
      // notification['error']({
      //   message: 'Error',
      //   description:
      //     error?.error_message ||
      //     error?.data?.msg ||
      //     error?.message ||
      //     error?.msg ||
      //     'Erorr',
      // });
    },
  }),
});
