import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  td {
    min-width: 200px;
  }
`;

export const WrapperChart = styled.div`
  /* background-color: #f0f2f5; */
  border-radius: 12px;
  padding: 20px;
`;
