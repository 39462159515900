import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  .img-account {
    max-width: 160px !important;
    margin-right: auto;
    margin-left: auto;
  }
`;

export const CardCatalog = styled.div`
  background-color: red;
`;
