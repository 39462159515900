import {
  ExclamationCircleOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Modal } from "antd";
import ImageCustom from "components/image";
import React from "react";
import { useState } from "react";
import useSide from "./hooks/useSide";
import { Wrapper } from "./style";
import Logo from "assets/auth/RizoLogo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import jwt_decode from "jwt-decode";
const { confirm } = Modal;
const { Header, Content, Sider } = Layout;
export default function SidebarComponent({ children }) {
  const [collapsed, setCollapsed] = useState(false);
  const [options, setOptions] = useState([false]);
  const [selectedLink, setSelectedLink] = useState("info");
  const { items } = useSide();
  const { pathname } = useLocation();
  const naviation = useNavigate();

  useEffect(() => {
    let decoded = jwt_decode(localStorage.getItem("RIZOADMINTOKEN"));
    if (decoded?.role !== "boss") {
      setOptions(
        items?.filter(
          (x) =>
            x?.key === "active-posts" ||
            x?.key === "unchecked-post" ||
            x?.key === "rejected-post" ||
            x?.key === "logout"
        )
      );
    } else {
      setOptions(items);
    }

    items?.map((a) => {
      if (pathname?.includes(a?.key)) {
        setSelectedLink(a?.key);
      }
    });
  }, []);
  return (
    <Wrapper>
      <Layout hasSider>
        <Sider
          style={{
            overflow: "auto",
            height: "calc(100vh)",
            position: "fixed",
            left: 0,
            top: 0,
            bottom: 0,
            padding: "16px 0 16px 16px",
            backgroundColor: "transparent",
            zIndex: 999,
          }}
          width={window.innerWidth > 991 ? 280 : "100%"}
          trigger={null}
          collapsible
          collapsed={collapsed}
        >
          <div className="logo">
            <ImageCustom src={Logo} />
          </div>
          <button onClick={() => setCollapsed(!collapsed)} className="closebtn">
            x
          </button>
          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={[pathname?.split("/")[1] || selectedLink]}
            items={options}
            onClick={({ key }) => {
              if (key === "logout") {
                confirm({
                  title: "Вы действительно хотите выйти?",
                  icon: <ExclamationCircleOutlined />,
                  onOk() {
                    localStorage.clear();
                    naviation(`/`);
                  },
                  onCancel() {
                    console.log("Cancel");
                  },
                });
              } else {
                naviation(`/${key}`);
                setSelectedLink(selectedLink);
              }
            }}
          />
        </Sider>
        <Layout
          className="site-layout"
          style={{
            marginLeft:
              !collapsed && window.innerWidth >= 991
                ? 280
                : window.innerWidth < 991
                ? 0
                : 80,
          }}
        >
          <Header
            className="site-layout-background"
            style={{
              margin: "16px 16px 0",
              borderRadius: "12px",
              padding: 0,
              paddingLeft: 24,
            }}
          >
            {React.createElement(
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: "trigger",
                onClick: () => setCollapsed(!collapsed),
              }
            )}
          </Header>
          <Content
            className="site-layout-background"
            style={{
              margin: "24px 16px",
              padding: 24,
              borderRadius: 12,
              minHeight: "calc(100vh - 124px)",
            }}
          >
            {children}
          </Content>
          {/* <Footer
            style={{
              textAlign: "center",
            }}
          >
            Azamov Asadbek © 2023 Created by{" "}
            <a href="https://asadbekazamov.uz">Azamov</a> {"  "} Ilova test
            rejimida ishlayapti!
          </Footer> */}
        </Layout>
      </Layout>
    </Wrapper>
  );
}
