import { Col, Row } from "antd";
import { InputComponent } from "components/inputs/input";
import { ErrorP } from "components/inputs/input/style";
import React from "react";
import { Wrapper } from "./style";
import { MyTitle } from "components/hrWrapper/style";
import ButtonComponent from "components/buttons";
import useCrud from "./hooks/useCrud";
import LoaderComponent from "components/loader";
import { useNavigate, useParams } from "react-router-dom";
import UseFormSelectComponent from "components/inputs/select";

export default function CrudTarifs() {
  const navigate = useNavigate();
  const { id } = useParams();

  const { sbmt, types, handleSubmit, control, loader, errors, img, setImg } =
    useCrud();

  return (
    <Wrapper>
      {loader && <LoaderComponent />}
      <MyTitle>
        <h2>{id === undefined ? "Создавать" : "Редактирование"} Тариф</h2>
        <ButtonComponent className="back" onClick={() => navigate(-1)}>
          Назад
        </ButtonComponent>
      </MyTitle>
      <form onSubmit={handleSubmit(sbmt)}>
        <Row gutter={[16, 16]}>
          <Col span={24} lg={12}>
            <UseFormSelectComponent
              control={control}
              required={true}
              name="type"
              placeholder={"Выберите категорию"}
              options={types}
              disabled={false}
              className={
                errors && errors?.hasOwnProperty("type")
                  ? "input-error select"
                  : " select "
              }
            />
            {errors && errors?.hasOwnProperty("type") && (
              <ErrorP>
                <ErrorP>Это поле является обязательным!</ErrorP>
              </ErrorP>
            )}
          </Col>
          <Col span={12} md={12}>
            <InputComponent
              control={control}
              nameProps="days"
              plProps="День"
              type="number"
              required={true}
              min={0}
              minLength={1}
              className={
                errors && errors?.hasOwnProperty("days") ? "input-error" : " "
              }
            />
            {errors && errors?.hasOwnProperty("days") && (
              <ErrorP>Это поле является обязательным!</ErrorP>
            )}
          </Col>
          <Col span={12} md={12}>
            <InputComponent
              control={control}
              min={0}
              minLength={1}
              nameProps="times"
              plProps="Количество просмотров (раз)"
              type="number"
              required={true}
              className={
                errors && errors?.hasOwnProperty("times") ? "input-error" : " "
              }
            />
            {errors && errors?.hasOwnProperty("times") && (
              <ErrorP>Это поле является обязательным!</ErrorP>
            )}
          </Col>
          <Col span={12} md={12}>
            <InputComponent
              control={control}
              min={0}
              minLength={1}
              nameProps="price"
              plProps="Цена (сум)"
              type="number"
              required={true}
              className={
                errors && errors?.hasOwnProperty("price") ? "input-error" : " "
              }
            />
            {errors && errors?.hasOwnProperty("price") && (
              <ErrorP>Это поле является обязательным!</ErrorP>
            )}
          </Col>

          <Col
            span={24}
            md={6}
            style={{ marginLeft: "auto", marginTop: "24px" }}
          >
            <ButtonComponent type="submit">
              {id === undefined ? "Создавать" : "Редактирование"}
            </ButtonComponent>
          </Col>
        </Row>
      </form>
    </Wrapper>
  );
}
