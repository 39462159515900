import axios from "axios";

const baseURL = process.env.REACT_APP_IMGAPI_ROOT;

const request = axios.create({
  baseURL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

request.interceptors.request.use(
  async (config) => {
    const tokens = localStorage.getItem("RIZOADMINTOKEN");
    if (tokens) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${tokens}`,
      };
      return config;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export { request };
