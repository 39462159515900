import Home from "pages/superadmin";
import LoginBossPage from "pages/loginboss";
import LoginPage from "pages/login";

import Superadmin from "pages/superadmin";
import CrudSuperadmin from "pages/superadmin/components/crud";
import Catalog from "pages/catalog";
import CrudCatalog from "pages/catalog/components/crud";
import SubCatalog from "pages/subcatalog";
import CrudSubCatalog from "pages/subcatalog/components/crud";
import Investment from "pages/investment";
import CrudInvestment from "pages/investment/components/crud";
import ApplicationInvestment from "pages/application";
import UncheckedPosts from "pages/posts/uncheckedPost";
import PostDetail from "pages/postDetail";
import Tarifs from "pages/tarifs";
import CrudTarifs from "pages/tarifs/components/crud";
import Payment from "pages/payment";
import Partners from "pages/partners";
import CrudPartners from "pages/partners/components/crud";
import FaqType from "pages/faqType";
import CrudFaqType from "pages/faqType/components/crud";
import CrudFaq from "pages/faq/components/crud";
import Faq from "pages/faq";
import Users from "pages/users";
import PaymentHistory from "pages/paymentHistory";
export const myRoutes = [
  {
    private: false,
    element: <LoginPage />,
    path: "/",
  },
  {
    private: false,
    element: <LoginBossPage />,
    path: "/boss",
  },

  {
    private: true,
    element: <Superadmin />,
    path: "/admins",
  },
  {
    private: true,
    element: <CrudSuperadmin />,
    path: "/admins/create",
  },
  {
    private: true,
    element: <CrudSuperadmin />,
    path: "/admins/edit/:id",
  },

  {
    private: true,
    element: <Catalog />,
    path: "/catalogs",
  },
  {
    private: true,
    element: <CrudCatalog />,
    path: "/catalogs/create",
  },
  {
    private: true,
    element: <CrudCatalog />,
    path: "/catalogs/edit/:id",
  },
  {
    private: true,
    element: <FaqType />,
    path: "/faq-type",
  },
  {
    private: true,
    element: <CrudFaqType />,
    path: "/faq-type/create",
  },
  {
    private: true,
    element: <CrudFaqType />,
    path: "/faq-type/edit/:id",
  },

  {
    private: true,
    element: <Faq />,
    path: "/faq",
  },
  {
    private: true,
    element: <CrudFaq />,
    path: "/faq/create",
  },
  {
    private: true,
    element: <CrudFaq />,
    path: "/faq/edit/:id",
  },

  {
    private: true,
    element: <SubCatalog />,
    path: "/subcatalogs",
  },
  {
    private: true,
    element: <CrudSubCatalog />,
    path: "/subcatalogs/create",
  },
  {
    private: true,
    element: <CrudSubCatalog />,
    path: "/subcatalogs/edit/:id",
  },

  {
    private: true,
    element: <Investment />,
    path: "/investment",
  },
  {
    private: true,
    element: <ApplicationInvestment />,
    path: "/investment/:id",
  },
  {
    private: true,
    element: <ApplicationInvestment />,
    path: "/partner-program",
  },
  {
    private: true,
    element: <Users />,
    path: "/users",
  },
  {
    private: true,
    element: <PaymentHistory />,
    path: "/users/:id",
  },
  {
    private: true,
    element: <Payment />,
    path: "/home",
  },
  {
    private: true,
    element: <CrudInvestment />,
    path: "/investment/create",
  },
  {
    private: true,
    element: <CrudInvestment />,
    path: "/investment/edit/:id",
  },
  {
    private: true,
    element: <UncheckedPosts />,
    path: "/unchecked-post",
  },
  {
    private: true,
    element: <PostDetail />,
    path: "/unchecked-post/:id",
  },
  {
    private: true,
    element: <UncheckedPosts />,
    path: "/active-posts",
  },
  {
    private: true,
    element: <PostDetail />,
    path: "/active-posts/:id",
  },
  {
    private: true,
    element: <UncheckedPosts />,
    path: "/rejected-post",
  },
  {
    private: true,
    element: <PostDetail />,
    path: "/rejected-post/:id",
  },
  {
    private: true,
    element: <Tarifs />,
    path: "/tarifs",
  },
  {
    private: true,
    element: <CrudTarifs />,
    path: "/tarifs/create",
  },
  {
    private: true,
    element: <CrudTarifs />,
    path: "/tarifs/edit/:id",
  },
  {
    private: true,
    element: <Partners />,
    path: "/partners",
  },
  {
    private: true,
    element: <CrudPartners />,
    path: "/partners/create",
  },
  {
    private: true,
    element: <CrudPartners />,
    path: "/partners/edit/:id",
  },
];
