import React from "react";
import { Controller } from "react-hook-form";
import MaskedInput from "react-input-mask";
import { LabelInp } from "../input/style";
import { Wrapper } from "./style";

export function CustomMask({
  control,
  nameProps,
  placeholder,
  mask = "+998 (99) 999 99 99",
  required,
  className = "pasport-mask",
  disabled = false,
}) {
  return (
    <Wrapper>
      <LabelInp>{placeholder}</LabelInp>
      <Controller
        control={control}
        name={nameProps}
        rules={{ required: required, minLength: 3 }}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { invalid, isTouched, isDirty, error },
          formState: { errors },
        }) => (
          <>
            <MaskedInput
              ref={ref}
              mask={mask}
              className={className}
              value={value}
              onChange={onChange}
              placeholder={placeholder}
              disabled={disabled}
            />
          </>
        )}
      />
    </Wrapper>
  );
}
