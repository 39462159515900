import { Col, notification, Row } from "antd";
import ButtonComponent from "components/buttons";
import ImageCustom from "components/image";
import { InputComponent } from "components/inputs/input";
import { ErrorP } from "components/inputs/input/style";
import { InputPasswordComponent } from "components/inputs/passwordInput";
import { RegisterWrapper } from "pages/register/styled";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Container } from "style";
import HiImg from "assets/auth/RizoLogo.png";
import { useNavigate } from "react-router-dom";
import { authApi } from "services/api/pagesApi";
import dayjs from "dayjs";
import { useMutation } from "@tanstack/react-query";
import LoaderComponent from "components/loader";
import jwt_decode from "jwt-decode";
export default function LoginPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const { mutate, isLoading } = useMutation((data) => authApi.login(data), {
    onSuccess: (res) => {
      let decoded = jwt_decode(res?.data?.token);
      console.log(decoded, "decode");
      if (decoded?.role === "admin" || decoded?.role === "boss") {
        localStorage.setItem("RIZOADMINTOKEN", res?.data?.token);
        localStorage.setItem(
          "tokenExpairy",
          dayjs(new Date()).format("YYYYMMDD")
        );
        if (decoded?.role === "boss") {
          notification["success"]({
            message: "Вы вошли в систему как суперадмин",
          });
          navigate("/home");
        } else {
          notification["success"]({
            message: t("Вы успешно вошли в RizoMulk-admin"),
          });
          navigate("/unchecked-post");
        }
      } else {
        notification["error"]({
          message: "Ошибка",
          description:
            "Извините, вы не администратор или ввели неверный пароль для входа!",
        });
      }
    },
    onError: (er) => {
      notification["error"]({
        message: "Ошибка",
        description: er?.message ?? er?.msg ?? "Ошибка",
      });
    },
  });

  function sbmt(info) {
    mutate(info);
  }

  return (
    <RegisterWrapper>
      <form onSubmit={handleSubmit(sbmt)}>
        <Container>
          <div className="content">
            <Row gutter={[12, 12]}>
              <Col span={24}>
                <div className="image">
                  <ImageCustom src={HiImg} />
                </div>
                <h1>{t("Добро пожаловать в админку RizoMulk")}</h1>
                <h2>{t("Авторизоваться")}</h2>
              </Col>

              <Col span={24}>
                <InputComponent
                  Controller={Controller}
                  control={control}
                  nameProps="phone"
                  plProps={t("Телефон")}
                  minLength={8}
                  type="text"
                  required={true}
                  className={
                    errors && errors?.hasOwnProperty("phone")
                      ? "input-error"
                      : " "
                  }
                />
                {errors && errors?.hasOwnProperty("phone") && (
                  <ErrorP>
                    {t(
                      "Пожалуйста, вводите информацию (только латинскими буквами)!"
                    )}
                  </ErrorP>
                )}
              </Col>

              <Col span={24}>
                <InputPasswordComponent
                  Controller={Controller}
                  control={control}
                  nameProps="password"
                  plProps={t("Пароль")}
                  minLength={4}
                  type="password"
                  required={true}
                  className={
                    errors && errors?.hasOwnProperty("password")
                      ? "input-error"
                      : " "
                  }
                />
                {errors && errors?.hasOwnProperty("password") && (
                  <ErrorP>
                    {t(
                      "Пожалуйста, вводите информацию (только латинскими буквами)!"
                    )}
                  </ErrorP>
                )}
              </Col>

              <Col span={24}>
                <ButtonComponent type="submit">{t("Входить")}</ButtonComponent>
              </Col>
            </Row>
          </div>
        </Container>
      </form>

      {isLoading && <LoaderComponent />}
    </RegisterWrapper>
  );
}
