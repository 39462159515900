import Api from "../index";

class Posts extends Api {
  addUser(data) {
    return this.execute("post", `boss/admin-account/admin-create`, data);
  }
  addImage(data) {
    return this.execute("post", `upload/image`, data);
  }

  addCatalog(data) {
    return this.execute("post", `admin/catalog/create`, data);
  }
  addFaqType(data) {
    return this.execute("post", `admin/faq-category/create`, data);
  }
  addFaq(data) {
    return this.execute("post", `admin/faq/create`, data);
  }
  addTarifs(data) {
    return this.execute("post", `admin/vir-pay-mod/create`, data);
  }
  addSubCatalog(data) {
    return this.execute("post", `admin/subcatalog/create`, data);
  }
  addInvestment(data) {
    return this.execute("post", `admin/investment/create`, data);
  }
  addPartner(data) {
    return this.execute("post", `admin/partner/create`, data);
  }

  postMapSearch(data) {
    return this.execute("post", `admin/investment/get-coordinate`, data);
  }
}

export default Posts;
