import styled from "styled-components";

export const Wrapper = styled.div`
  padding-top: 24px;
`;
export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  transition: all 0.3s ease;
  @media (max-width: 991px) {
    opacity: 0;
    visibility: hidden;
    padding-top: 8px;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    width: 100%;
    height: 100vh;
    z-index: 99;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;
    .link {
      width: 100%;
      text-align: center;
      align-items: center;
      flex-direction: column;
      flex-wrap: wrap;
      margin: 8px 0;
    }
    button {
      position: absolute;
      top: 20px;
      right: 20px;
    }

    &.show {
      opacity: 1;
      visibility: visible;
    }
  }

  .hr-img {
    width: 40px;
    margin-right: 8px;
    .account-img {
      border-radius: 50%;
      border: 2px solid #eee;
    }
  }
`;

export const Header = styled.nav`
  width: 100%;
  border-radius: 12px;
  background-color: #fff;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  .notif {
    background-color: transparent;
    border: none;
    padding: 6px;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: all 0.3s ease;
    &:hover {
      background-color: #eee;
    }
  }
  .logo {
    width: 160px;
    display: inline-block;
  }

  .link {
    padding: 0 4px;
    display: flex;
    align-items: center;

    .linkbtn {
      padding: 8px 16px;
      color: var(--text-color);
      transition: all 0.3s ease;
      font-size: 16px;
      font-weight: 500;
      border-radius: 6px;
      &:hover,
      &.active {
        background-color: var(--green);
        color: #fff;
      }
    }

    .dropdown-hr {
      color: var(--text-color);
      margin: 0;
      padding: 0;
      cursor: pointer;
      padding-left: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        margin-left: 4px;
      }
    }
    &.active {
      a {
        color: #fff;
      }
    }
  }
`;

export const ButtonNavigate = styled.button`
  display: none;
  border: none;
  outline: none;
  background-color: var(--fc-event-bg-color);
  width: 40px;
  height: 40px;
  border-radius: 6px;
  font-size: 16px;
  transition: all 0.3s ease;
  &:hover {
    background-color: var(--green);
    color: #fff;
  }
  @media (max-width: 991px) {
    display: block;
  }
`;

export const MyTitle = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0;
  margin-bottom: 16px;
  flex-wrap: wrap;
  align-items: center;
  gap: 24px;
  h2 {
    font-size: 18px;
    color: black;
    text-transform: uppercase;
  }
  button,
  a {
    font-size: 16px;
    margin-left: auto;
    display: inline-block;
    max-width: 240px;
    font-weight: normal;
  }

  .search {
    display: flex;
    align-items: center;
    gap: 12px;
    button {
      font-size: 14px;
    }

    input {
      &:hover {
        border-color: var(--green);
      }
    }
  }
`;
