import {
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Button, message, Upload } from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function FileCustom({
  typeComponent = "image",
  imageUrl,
  setImageUrl,
}) {
  const [loading, setLoading] = useState(false);
  const [urlImg, setUrlImg] = useState(undefined);
  const { t } = useTranslation();

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng && typeComponent !== "image") {
      message.error(t("ошибка загрузки img"));
      setLoading(false);
    }

    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M && typeComponent !== "image") {
      message.error(t("ошибка загрузки img"));
      setLoading(false);
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info) => {
    setLoading(true);
    if (
      (info.file.type === "image/jpeg" || info.file.type === "image/png") &&
      info.file.size / 1024 / 1024 < 2
    ) {
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setUrlImg(url);
        setImageUrl(info.file.originFileObj);
      });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (imageUrl !== undefined) {
      setUrlImg(
        imageUrl?.length > 0 && imageUrl?.includes("http")
          ? imageUrl
          : imageUrl?.length > 0
          ? process.env.REACT_APP_API_ROOT + imageUrl[0]
          : undefined
      );
    } else {
      setImageUrl(undefined);
    }
  }, [imageUrl]);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        {t("Загрузить изображение")}
      </div>
    </div>
  );

  const props = {
    onChange: (info) => {
      setImageUrl(info.fileList[0]);
    },
  };

  return (
    <div style={{ position: "static", marginTop: 8 }}>
      {typeComponent !== "image" ? (
        <Upload {...props}>
          <Button icon={<UploadOutlined />}>
            {" "}
            {t("Загрузить изображение")}
          </Button>
        </Upload>
      ) : (
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={handleChange}
        >
          {urlImg ? (
            <img
              src={urlImg}
              alt="avatar"
              style={{
                width: "100%",
              }}
            />
          ) : (
            uploadButton
          )}
        </Upload>
      )}
    </div>
  );
}
