import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Districts, Regions } from "services/jsons";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

export default function PostAdditional({ data }) {
  const [info, setInfo] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (data) {
      setInfo(Object.entries(data));
    }
  }, [data]);

  return (
    <>
      <div>
        <h3>
          <b>Дополнительная информация</b>
        </h3>
      </div>
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 991: 2, 1600: 3 }}>
        <Masonry gutter="16px">
          {info?.map((x, i) => (
            <div>
              <p>
                <b>{t(x[0])}:</b>{" "}
                {typeof x[1] != typeof info
                  ? t(
                      x[0] === "district_id"
                        ? Districts["ru"].find((s) => s?.value == x[1])?.label
                        : x[0] === "region_id"
                        ? Regions["ru"].find((s) => s?.value == x[1])?.label
                        : x[1]
                    )
                  : x[0] === "district_id"
                  ? x[1]?.map(
                      (a) =>
                        `${t(
                          Districts["ru"].find((s) => s?.value == a)?.label
                        )}, `
                    )
                  : x[1]?.map((a) => `${t(a)}, `)}{" "}
              </p>
            </div>
          ))}
        </Masonry>
      </ResponsiveMasonry>
    </>
  );
}
