import styled from 'styled-components';

export const device = {
  mobile: '768px',
  laptop: '1024px',
  desktop: '1400px',
};

export const sizes = {
  s1: '4px',
  s2: '8px',
  s3: '16px',
  s4: '24px',
  s5: '32px',
  shadow: 'rgba(0, 0, 0, 0.05) 0px 1px 2px 0px',
};

export const customColor = {
  bgColor: '#fff',
  textColor: 'rgba(25, 51, 37, 0.75)',
  green: 'rgb(44 71 62)',
};

export const FullProvider = styled.div``;

export const Container = styled.div`
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 576px) {
    max-width: 540px;
    padding-right: 15px;
    padding-left: 15px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }
  @media (min-width: 1400px) {
    max-width: 1320px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span,
  a {
    margin: 0;
    padding: 0;
  }
`;
