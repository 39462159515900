import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import { Suspense, useEffect } from 'react';
import { myRoutes } from './routes.path';
// import { SystemContext } from 'context';
import PrivateRoute from 'layout';
import LoaderComponent from 'components/loader';

//layouts
const Routers = () => {
  // const { setLoader } = useContext(SystemContext);
  const { i18n } = useTranslation();
  const language = localStorage.getItem('language');

  useEffect(() => {
    if (language !== null) {
      i18n.changeLanguage(language);
    } else {
      localStorage.setItem('language', 'uz');
    }
  }, [language, i18n]);

  return (
    <>
      <Suspense fallback={<LoaderComponent />}>
        <Routes>
          <Route element={<PrivateRoute />}>
            {myRoutes.map(
              (item, index) =>
                item.private && (
                  <Route key={index} path={item.path} element={item.element} />
                )
            )}
          </Route>
          {myRoutes.map(
            (item, index) =>
              !item.private && (
                <Route key={index} path={item.path} element={item.element} />
              )
          )}
        </Routes>
      </Suspense>
    </>
  );
};

export default Routers;
