import ButtonComponent from "components/buttons";
import { MyTitle } from "components/hrWrapper/style";
import TableComponent from "components/table";
import React from "react";
import { useNavigate } from "react-router-dom";
import useData from "./components/hooks/usedata";
import { Wrapper } from "./style";
export default function FaqType() {
  const navigate = useNavigate();
  const { data, columns, loading } = useData();
  return (
    <Wrapper>
      <MyTitle>
        <h2>Страница типа вопроса</h2>
        <ButtonComponent onClick={() => navigate("create")}>
          Создавать
        </ButtonComponent>
      </MyTitle>

      <TableComponent isloading={loading} data={data} columns={columns} />
    </Wrapper>
  );
}
