import { MyTitle } from "components/hrWrapper/style";
import React from "react";
import useData from "./components/hooks/usedata";
import { Wrapper } from "./style";
import Charts from "./components/chart";
import { Col, Row } from "antd";
import AccountCharts from "./components/accountsChart";
import LoaderComponent from "components/loader";
export default function Payment() {
  const { data, allData } = useData();
  return (
    <Wrapper>
      <MyTitle>
        <h2>Статистика</h2>
      </MyTitle>
      <Row gutter={[24, 24]}>
        {data != undefined && allData != undefined ? (
          <>
            <Col span={24}>
              <h3>Платежи и статистика пользователей</h3>
              <Charts accountdata={allData?.accounts} data={data} />
            </Col>
            <Col span={24}>
              <h3>Статистика по объявлениям</h3>
              <AccountCharts data={allData?.posts} />
            </Col>
          </>
        ) : (
          <LoaderComponent />
        )}
      </Row>
    </Wrapper>
  );
}
