import {
  AimOutlined,
  ApartmentOutlined,
  AppstoreOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  DollarCircleOutlined,
  FileProtectOutlined,
  LogoutOutlined,
  MoneyCollectFilled,
  PartitionOutlined,
  PieChartOutlined,
  QuestionCircleOutlined,
  StopOutlined,
  UserSwitchOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";

export default function useSide() {
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const items = [
    getItem("Администраторы", "admins", <UserSwitchOutlined />),
    getItem("Статистика", "home", <PieChartOutlined />),
    getItem("Пользователи", "users", <UsergroupAddOutlined />),
    getItem("Категория", "catalogs", <AppstoreOutlined />),
    getItem("Подкатегория", "subcatalogs", <ApartmentOutlined />),
    getItem("Инвестиции", "investment", <MoneyCollectFilled />),
    getItem("Активные объявления", "active-posts", <CheckCircleOutlined />),
    getItem("Новые объявления", "unchecked-post", <ClockCircleOutlined />),
    getItem("Возвращенные объявления", "rejected-post", <StopOutlined />),
    getItem("Тарифы", "tarifs", <AimOutlined />),
    getItem(
      "Партнерская программа",
      "partner-program",
      <FileProtectOutlined />
    ),
    getItem("Спонсоры", "partners", <DollarCircleOutlined />),
    getItem("FAQ тип", "faq-type", <PartitionOutlined />),
    getItem("FAQ", "faq", <QuestionCircleOutlined />),
    getItem("Выход", "logout", <LogoutOutlined />),
  ];
  return { items };
}
