import ButtonComponent from "components/buttons";
import { MyTitle } from "components/hrWrapper/style";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useData from "./components/hooks/usedata";
import { Wrapper } from "./style";
import { Col, Modal, Row } from "antd";
import PostHeader from "./components/header";
import PostAssets from "./components/assets";
import PostAdditional from "./components/additional";
import LoaderComponent from "components/loader";
import BtnsActive from "./components/btns";
import TextArea from "antd/lib/input/TextArea";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
export default function PostDetail() {
  const { pathname } = useLocation();

  const {
    data,
    loading,
    allSubcatalogs,
    isModalOpen,
    handleOk,
    handleCancel,
    handleCheck,
    handleReject,
    text,
    setText,
  } = useData();
  const navigate = useNavigate();
  return (
    <Wrapper>
      <MyTitle>
        <h2>Проверить объявление</h2>
        <ButtonComponent className="back" onClick={() => navigate(-1)}>
          Назад
        </ButtonComponent>
      </MyTitle>

      <Row className="content" gutter={[24, 24]}>
        {loading && <LoaderComponent />}
        <Col span={24}>
          <PostHeader data={data} allSubcatalogs={allSubcatalogs} />
        </Col>
        <Col span={24}>
          <PostAssets data={data} />
        </Col>
        <Col span={24}>
          <PostAdditional data={data?.postDetails} />
        </Col>

        {(pathname.includes("unchecked-post") ||
          pathname.includes("active-posts")) && (
          <Col span={24}>
            <BtnsActive handleCheck={handleCheck} handleReject={handleReject} />
          </Col>
        )}

        <Modal
          okButtonProps={{
            disabled: text?.length > 6 ? false : true,
          }}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <h3>
            {pathname.includes("active-posts") ? "Заблокировать" : "Удалить"}{" "}
            объявление
          </h3>

          <label>
            Пожалуйста, напишите причину{" "}
            {pathname.includes("active-posts") ? "Заблокировать" : "Удалить"}!
          </label>
          <TextArea
            value={text}
            onChange={(e) => setText(e.target.value)}
            rows={4}
          />
        </Modal>
      </Row>
    </Wrapper>
  );
}
