import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, Modal, Tag } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { DeletesApi, getsApi } from "services/api/pagesApi";
import { ApiSiteKey } from "services/jsons";
const { confirm } = Modal;
export default function useData() {
  const navigate = useNavigate();

  const { refetch, data, isLoading } = useQuery(
    [ApiSiteKey.geFaqType],
    () => getsApi.getFaqType({ limit: 30, offset: 0 }),

    {
      select: (data) => {
        return data?.data?.faq_category_list.map((x) => {
          return {
            ...x,
            key: x?.id,
          };
        });
      },
      onSuccess: (data) => {
        console.log(data);
      },
    }
  );

  function handleDel(id) {
    confirm({
      title: "Удалить",
      content: "Вы уверены, что хотите удалить?",
      onOk() {
        mutate(id);
      },
      onCancel() {
        console.log("close");
      },
      okText: "Удалить",
    });
  }

  const { mutate, isLoading: load } = useMutation(
    (id) => DeletesApi.delFaqType(id),
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  function handleEdit(id) {
    navigate(`edit/${id}`);
  }

  let loading = load || isLoading;

  const columns = [
    {
      title: "Имя(uz)",
      dataIndex: "name_uz",
      key: "name_uz",
    },
    {
      title: "Имя(ru)",
      dataIndex: "name_ru",
      key: "name_ru",
    },
    {
      title: "Имя(en)",
      dataIndex: "name_en",
      key: "name_en",
    },

    {
      title: "Действие",
      dataIndex: "Action",
      key: "Action",
      render: (id, row) => (
        <>
          <div className="btnWrapper-list" key={row.id}>
            <Button
              style={{ marginRight: 8 }}
              type="primary"
              icon={<EditOutlined />}
              onClick={() => handleEdit(row?.id)}
            />
            <Button
              type="dashed"
              onClick={() => handleDel(row?.id)}
              icon={<DeleteOutlined />}
            />
          </div>
        </>
      ),
    },
  ].filter((item) => !item.hidden);

  return { data, columns, loading };
}
