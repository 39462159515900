import { useMutation, useQuery } from "@tanstack/react-query";
import { Modal, Typography } from "antd";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getsApi, putApi } from "services/api/pagesApi";
import { ApiSiteKey } from "services/jsons";

const { confirm } = Modal;
export default function useData() {
  const { pathname } = useLocation();

  const navigate = useNavigate();
  // admin/check-post/get-rejectedpost
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [text, setText] = useState(undefined);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    reject({ post_error_message: text });
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const { id } = useParams();

  const { data: allSubcatalogs } = useQuery(
    [ApiSiteKey.getSubCatalogs],
    () => getsApi.getSubCatalogs({ limit: 100, offset: 0 }),

    {
      select: (data) => {
        return data?.data?.map((x) => {
          return {
            ...x,
            key: x?.id,
            keyForm: x?.key,
          };
        });
      },
      onSuccess: (data) => {
        console.log(data);
      },
    }
  );

  const { data, isLoading } = useQuery(
    [ApiSiteKey.getUncheckedPostById],
    () =>
      pathname.includes("unchecked-post")
        ? getsApi.getPostById({ id })
        : getsApi.getPostRejectedById({ id }),
    {
      select: (data) => {
        return data?.data;
      },
      onSuccess: (data) => {
        console.log(data);
      },
      enabled: id === undefined ? false : true,
    }
  );

  function handleCheck() {
    confirm({
      title: "Tasdiqlash",
      content: "Rostdan ushbu elonni ko'rib chiqdingizmi?",
      onOk() {
        mutate();
      },
      onCancel() {
        console.log("close");
      },
      okText: "Tasdiqlash",
    });
  }

  const { mutate, isLoading: load } = useMutation(
    () => putApi.activedPost(id),
    {
      onSuccess: () => {
        navigate("/unchecked-post");
      },
    }
  );
  const { mutate: reject, isLoading: loadd } = useMutation(
    (data) => putApi.rejectedPost(id, data),
    {
      onSuccess: () => {
        navigate("/unchecked-post");
      },
    }
  );

  function handleReject() {
    showModal();
  }

  let loading = load || isLoading || loadd;

  return {
    data,
    loading,
    allSubcatalogs,
    handleCheck,
    handleReject,
    isModalOpen,
    handleOk,
    handleCancel,
    text,
    setText,
  };
}
