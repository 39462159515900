import styled from 'styled-components';

export const Input = styled.input`
  width: 100%;
  height: 35px;
  background: transparent;
  border: 1px solid var(--secondary);
  font-size: 14px;
  font-family: 'Rubik', sans-serif;
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
  margin: 8px 0px;
  text-indent: 12px;
  transition: all 0.3s ease;
  color: var(--text-color);
  &.input-error {
    border: 1px solid var(--red);
  }
  &:focus {
    border: 1px solid var(--green);
  }
  &::placeholder {
    font-size: 12px;
    color: var(--secondary-text);
    font-family: 'Rubik', sans-serif;
  }
  &:disabled {
    background: var(--secondary);
  }
`;
export const FormGroupProvider = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  /* .password {
    input {
      padding-right: 30px !important;
    }
  } */
`;
export const ErrorP = styled.p`
  color: var(--red);
`;
export const LabelInp = styled.label`
  color: var(--text-color);
  font-size: 16px;
  width: 100%;
`;
// export const FormInput = styled.input`
//   width: 100%;
//   height: ${(props) => props.propsHeight};
//   background-color: ${systemColors.mainBackground} !important;
//   border-radius: 8px;
//   font-family: 'Montserrat', sans-serif !important;
//   border: 1px solid transparent;
//   margin-top: 4px;
//   box-shadow: inset 0 0 0 0 !important;

//   &::placeholder {
//     font-weight: 500;
//     font-size: 14px;
//     font-family: 'Montserrat', sans-serif;
//   }
//   &:-webkit-autofill {
//     background: #fff !important;
//   }
//   &.error-input {
//     border: 1px solid red !important;
//   }
//   &.error {
//     border: 1px solid ${systemColors.errorColor} !important;
//     &:focus {
//       border: 1px solid ${systemColors.errorColor} !important;
//       box-shadow: inset 0 0 0 0 !important;
//     }
//   }
// `;
