import { useMutation, useQuery } from "@tanstack/react-query";
import { notification } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { getsApi, postApi, putApi } from "services/api/pagesApi";
import { request } from "services/api/request";
import { ApiSiteKey } from "services/jsons";

export default function useCrud() {
  const [img, setImg] = useState(undefined);
  const [imgLink, setImgLink] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  console.log(id);
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();
  const { mutate: fileMutate, isLoading: fileLoading } = useMutation(
    // (data) => request.post("minio/upload/image", data),
    // (data) => postApi.addImage(data),
    {
      onSuccess: (res) => {
        setImgLink(res?.data);
        notification["success"]({
          message: "Файл загружен",
        });
      },
      onError: (er) => {
        notification["error"]({
          message: "Xatolik",
          description:
            er?.message ?? er?.msg ?? "При загрузке файла произошла ошибка",
        });
      },
    }
  );

  const { isLoading: load } = useQuery(
    [ApiSiteKey.getAdmin],
    () => getsApi.getAdminById({ id }),

    {
      select: (data) => {
        return data?.data?.data;
      },
      onSuccess: (data) => {
        reset({
          ...data,
        });
        setImg(data?.image_link);
      },
      enabled: id === undefined ? false : true,
    }
  );

  const { mutate, isLoading: loading } = useMutation(
    (data) =>
      id === undefined ? postApi.addUser(data) : putApi.editAdmin(data, id),
    {
      onSuccess: () => {
        navigate("/admins");
        notification["success"]({
          message: "Пользователь создан",
        });
      },
      onError: () => {
        notification["error"]({
          message: "Пользователь не добавлен",
        });
      },
    }
  );

  function sbmt(info) {
    const { id, ...rest } = info;
    let data = {
      ...rest,
      is_boss: false,
      image: imgLink,
    };

    mutate(data);
  }
  useEffect(() => {
    if (img !== undefined && typeof img !== typeof "Asadbek Azamov") {
      let data = new FormData();
      data.append("file", img);
      fileMutate(data);
    }
  }, [img]);

  let loader = fileLoading || (id !== undefined ? load : false) || loading;
  console.log(load);
  return {
    sbmt,
    handleSubmit,
    control,
    reset,
    loader,
    errors,
    img,
    setImg,
  };
}
