import React, { useEffect, useCallback, useState } from "react";
import { GoogleMap, LoadScriptNext, Marker } from "@react-google-maps/api";

const ZOOM = 7;
const CENTER = { lat: 40.57371667464446, lng: 65.69197388629195 };

export const MapComponent = (props) => {
  const [map, setMap] = useState(null);
  const onLoad = useCallback((map) => setMap(map), []);

  // Fit bounds function
  const fitBounds = () => {
    const bounds = new window.google.maps.LatLngBounds();
    props.markers.map((item) => {
      bounds.extend({ ...item.position });
      return item.id;
    });
    map.fitBounds(bounds);
    map.setZoom(17);
  };

  // Fit bounds on mount, and when the markers change
  useEffect(() => {
    if (map) {
      props.markers?.length > 0 &&
        props.markers[0]?.position !== undefined &&
        fitBounds();
    }
  }, [props.data, map]);

  const onMarkerDragEnd = (coord) => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    props.setValue({ lat, lng });
  };
  const containerStyle = {
    width: "100%",
    height: props.height ?? "460px",
    borderRadius: 12,
  };
  return (
    <div>
      <LoadScriptNext googleMapsApiKey={process.env.REACT_APP_GOOGLEMAP}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={CENTER}
          zoom={ZOOM}
          onLoad={onLoad}
          options={{
            mapTypeControl: false,
            streetViewControl: false,
          }}
        >
          {/* Child components, such as markers, info windows, etc. */}
          <>
            {props.markers?.length > 0 &&
              props.markers.map(({ position }, index) => (
                <Marker
                  key={`marker_${index}`}
                  position={position}
                  draggable={true}
                  onDrag={(e) => onMarkerDragEnd(e)}
                />
              ))}
          </>
        </GoogleMap>
      </LoadScriptNext>
    </div>
  );
};
