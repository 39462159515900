import ButtonComponent from "components/buttons";
import { MyTitle } from "components/hrWrapper/style";
import TableComponent from "components/table";
import React from "react";
import { useLocation } from "react-router-dom";
import useData from "./components/hooks/usedata";
import { Wrapper } from "./style";
export default function UncheckedPosts() {
  const { data, columns, loading } = useData();

  const { pathname } = useLocation();
  return (
    <Wrapper>
      <MyTitle>
        <h2>
          {pathname.includes("unchecked-post")
            ? "Непроверенные "
            : pathname.includes("active-post")
            ? "Активные "
            : "Отклоненные "}{" "}
          объявления
        </h2>
      </MyTitle>

      <TableComponent
        params={data?.param}
        isloading={loading}
        data={data?.data}
        columns={columns}
      />
    </Wrapper>
  );
}
