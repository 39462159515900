import styled from "styled-components";
export const Wrapper = styled.div`
  background-color: #f0f2f5;

  .ant-layout-sider-children {
    background-color: #fff;
    border-radius: 12px;
    overflow: hidden;
    padding-right: 4px;
    padding-bottom: 4px;
    .ant-menu {
      height: calc(100vh - 150px);
      overflow-y: auto;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        background-color: var(--fc-event-bg-color);
        border-radius: 8px;
        width: 4px;
        height: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--green);
        border-radius: 8px;
      }
    }
  }
  #components-layout-demo-custom-trigger .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
  }

  #components-layout-demo-custom-trigger .trigger:hover {
    color: #1890ff;
  }

  #components-layout-demo-custom-trigger .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
  }

  .site-layout .site-layout-background {
    background: #fff;
  }
  .logo {
    height: auto;
    width: 100px;
    margin: 16px auto;
  }

  .ant-layout-sider-collapsed .logo {
    height: auto;
    width: 48px;
  }

  .ant-layout-sider {
    background-color: #fff;
  }
  .ant-menu.ant-menu-light {
    background-color: #fff;
  }

  /* aside {
    @media (min-width: 1400px) {
      width: 300px;
    }
  } */
`;
