import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, Modal, Tag } from "antd";
import { Image } from "components/image/style";
import React from "react";
import { useNavigate } from "react-router-dom";
import { DeletesApi, getsApi } from "services/api/pagesApi";
import { ApiSiteKey } from "services/jsons";
const { confirm } = Modal;
export default function useData() {
  const navigate = useNavigate();

  const { refetch, data, isLoading } = useQuery(
    [ApiSiteKey.getCatalogs],
    () => getsApi.getCatalogs({ limit: 10, offset: 0 }),

    {
      select: (data) => {
        return data?.data?.map((x) => {
          return {
            ...x,
            key: x?.id,
            keyForm: x?.key,
          };
        });
      },
      onSuccess: (data) => {
        console.log(data);
      },
    }
  );

  function handleDel(id) {
    confirm({
      title: "Foydalanuvchini Удалить",
      content: "Rostdan ham foydalanuvchini o'chirmoqchimisiz?",
      onOk() {
        mutate(id);
      },
      onCancel() {
        console.log("close");
      },
      okText: "Удалить",
    });
  }

  const { mutate, isLoading: load } = useMutation(
    (id) => DeletesApi.delCatalog(id),
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  function handleEdit(id) {
    navigate(`edit/${id}`);
  }

  let loading = load || isLoading;

  const columns = [
    {
      title: "Имя",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Описание",
      dataIndex: "description",
      key: "description",
    },
    // {
    //   title: "Ключ",
    //   dataIndex: "keyForm",
    //   key: "keyForm",
    // },

    {
      title: "Фото",
      dataIndex: "image_link",
      key: "image_link",
      render: (x) =>
        x === "" || x === " " ? (
          <Tag style={{ borderRadius: 4 }}>Изображение не загружено</Tag>
        ) : (
          <div
            style={{
              padding: 4,
              backgroundColor: "#fff",
              maxWidth: 150,
              textAlign: "center",
            }}
          >
            <Image width={"100%"} src={x} />
          </div>
        ),
    },

    // {
    //   title:"Action",
    //   dataIndex:"Action",
    //   key:"Action",
    //   render: (id, row) => (
    //     <>
    //       <div className="btnWrapper-list" key={row.id}>
    //         <Button
    //           style={{ marginRight: 8 }}
    //           type="primary"
    //           icon={<EditOutlined />}
    //           onClick={() => handleEdit(row?.id)}
    //         />
    //         <Button
    //           type="dashed"
    //           onClick={() => handleDel(row?.id)}
    //           icon={<DeleteOutlined />}
    //         />
    //       </div>
    //     </>
    //   ),
    // },
  ].filter((item) => !item.hidden);

  return { data, columns, loading };
}
