import { useQuery } from "@tanstack/react-query";
import { Modal } from "antd";
import { useLocation, useParams } from "react-router-dom";
import { getsApi } from "services/api/pagesApi";
import { ApiSiteKey } from "services/jsons";
const { confirm } = Modal;
export default function useData() {
  // const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();

  const { search = "?offset=0&limit=10" } = location;
  const { data, isLoading } = useQuery(
    [ApiSiteKey.getApplications, search],
    () =>
      id
        ? getsApi.getApplications({ search, id: id })
        : getsApi.getPartnerApplications({ search }),

    {
      select: (data) => {
        const info = {
          param: data?.data?.pagination,
          data: data?.data?.list?.map((x) => {
            return {
              ...x,
              key: x?.postId?.replaceAll("-", ""),
            };
          }),
        };
        return info;
      },
      onSuccess: (data) => {
        console.log(data);
      },
    }
  );

  // function handleDel(id) {
  //   confirm({
  //     title: "Foydalanuvchini Удалить",
  //     content: "Rostdan ham foydalanuvchini o'chirmoqchimisiz?",
  //     onOk() {
  //       mutate(id);
  //     },
  //     onCancel() {
  //       console.log("close");
  //     },
  //     okText: "Удалить",
  //   });
  // }

  // const { mutate, isLoading: load } = useMutation((id) => DeletesApi.del(id), {
  //   onSuccess: () => {
  //     refetch();
  //   },
  // });

  // function handleEdit(id) {
  //   navigate(`edit/${id}`);
  // }

  let loading = isLoading;

  const columns = [
    {
      title: "Полное имя",
      dataIndex: "name",
      key: "name",
    },

    // {
    //   title: "Имя пользователя",
    //   dataIndex: "username",
    //   key: "username",
    // },

    {
      title: "Номер телефона",
      dataIndex: "phone",
      key: "phone",
    },

    // {
    //   title:"Action",
    //   dataIndex:"Action",
    //   key:"Action",
    //   render: (id, row) => (
    //     <>
    //       <div className="btnWrapper-list" key={row.id}>
    //         <Button
    //           style={{ marginRight: 8 }}
    //           type="primary"
    //           icon={<EditOutlined />}
    //           onClick={() => handleEdit(row?.id)}
    //         />
    //         <Button
    //           type="dashed"
    //           onClick={() => handleDel(row?.id)}
    //           icon={<DeleteOutlined />}
    //         />
    //       </div>
    //     </>
    //   ),
    // },
  ].filter((item) => !item.hidden);
  // const data = [
  //   {
  //     key: "11",
  //     ism: "Asadbek",
  //     address: "Navoiy, Qiziltepa",
  //     login: "Azamov",
  //     email: "azamov@mail.com",
  //     isboss: true,
  //     password: "1111111",
  //   },
  //   {
  //     key: "1111",
  //     ism: "Asadbek",
  //     address: "Navoiy, Qiziltepa",
  //     login: "Azamov",
  //     email: "azamov@mail.com",

  //     isboss: false,
  //     password: "1111111",
  //   },
  // ];

  return { data, columns, loading };
}
