import { Button, Col, Row } from "antd";
import React from "react";
import { useLocation } from "react-router-dom";

export default function BtnsActive({ handleCheck, handleReject }) {
  const { pathname } = useLocation();

  return (
    <Row justify={"end"} gutter={[24]}>
      <Col span={24} lg={12}>
        <div className="btns">
          <Button
            onClick={() => handleReject()}
            className="red"
            type="primary"
            size="large"
          >
            {pathname.includes("unchecked-post")
              ? "Отказ"
              : "Отключить рекламу"}
          </Button>
          {pathname.includes("unchecked-post") && (
            <Button onClick={() => handleCheck()} type="primary" size="large">
              Подтвердить
            </Button>
          )}
        </div>
      </Col>
    </Row>
  );
}
