import { Image } from "./style";

export default function ImageCustom({ src, style, className = " " }) {
  return (
    <Image
      src={src}
      style={style}
      effect="blur"
      className={className}
      width="100%"
      height="100%"
    />
  );
}
