import { useMutation, useQuery } from "@tanstack/react-query";
import { notification } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { getsApi, postApi, putApi } from "services/api/pagesApi";
import { ApiSiteKey } from "services/jsons";

export default function useCrud() {
  const [img, setImg] = useState(undefined);
  const [imgLink, setImgLink] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  console.log(id);
  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const { mutate: fileMutate, isLoading: fileLoading } = useMutation(
    (data) => postApi.addImage(data),
    {
      onSuccess: (res) => {
        setImgLink(res?.data);
        notification["success"]({
          message: "Файл загружен",
        });
      },
      onError: (er) => {
        notification["error"]({
          message: "Ошибка",
          description:
            er?.message ?? er?.msg ?? "При загрузке файла произошла ошибка",
        });
      },
    }
  );

  const { isLoading: load } = useQuery(
    [ApiSiteKey.getAdmin],
    () => getsApi.getCatalogById({ id }),

    {
      select: (data) => {
        return data?.data;
      },
      onSuccess: (data) => {
        reset({
          ...data,
        });
        // setImg(data?.image_link);
      },
      enabled: id !== undefined ? true : false,
    }
  );

  const { mutate, isLoading: loading } = useMutation(
    (data) =>
      id === undefined ? postApi.addCatalog(data) : putApi.editCatalog(data),
    {
      onSuccess: (data) => {
        navigate("/catalogs");
        notification["success"]({
          message: `${id !== undefined ? "Отредактировано" : "Созданный"}`,
        });
      },
      onError: () => {
        notification["error"]({
          message: "Ошибка",
        });
      },
    }
  );

  function sbmt(info) {
    const { id: idd, ...rest } = info;
    let data = {
      ...rest,
      image: " ",
    };

    mutate(id === undefined ? data : { ...data, id });
  }

  let loader = fileLoading || (id !== undefined ? load : false) || loading;

  return {
    sbmt,
    handleSubmit,
    control,
    reset,
    loader,
    errors,
    img,
    setImg,
  };
}
