import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, Modal, Tag } from "antd";
import { Image } from "components/image/style";
import React from "react";
import { useNavigate } from "react-router-dom";
import { DeletesApi, getsApi } from "services/api/pagesApi";
import { ApiSiteKey } from "services/jsons";
const { confirm } = Modal;
export default function useData() {
  const navigate = useNavigate();

  const { refetch, data, isLoading } = useQuery(
    [ApiSiteKey.getAdmins],
    () => getsApi.getAdmins(),

    {
      select: (data) => {
        return data?.data?.data?.map((x) => {
          return {
            ...x,
            key: x?.id,
          };
        });
      },
      onSuccess: (data) => {
        console.log(data);
      },
    }
  );

  function handleDel(id) {
    confirm({
      title: "Удалить пользователя",
      content: "Вы уверены, что хотите удалить этого пользователя?",
      onOk() {
        mutate(id);
      },
      onCancel() {
        console.log("close");
      },
      okText: "Удалить",
    });
  }

  const { mutate, isLoading: load } = useMutation((id) => DeletesApi.del(id), {
    onSuccess: () => {
      refetch();
    },
  });

  function handleEdit(id) {
    navigate(`edit/${id}`);
  }

  let loading = load || isLoading;

  const columns = [
    {
      title: "Имя",
      dataIndex: "fullname",
      key: "fullname",
    },
    {
      title: "Адрес",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Электронная почта",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Имя пользователя",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Фото",
      dataIndex: "image_link",
      key: "image_link",
      render: (x) =>
        x === "" || x === " " ? (
          <Tag style={{ borderRadius: 4 }}>Изображение не загружено</Tag>
        ) : (
          <div
            style={{
              padding: 4,
              backgroundColor: "#fff",
              maxWidth: 150,
              textAlign: "center",
            }}
          >
            <Image width={"100%"} src={x} />
          </div>
        ),
    },
    // {
    //   title: "IsBoss",
    //   dataIndex: "is_boss",
    //   key: "is_boss",
    //   render: (x) =>
    //     x === true ? (
    //       <Tag style={{ borderRadius: 4 }}>True</Tag>
    //     ) : (
    //       <Tag style={{ borderRadius: 4 }} color="pink">
    //         False
    //       </Tag>
    //     ),
    // },

    {
      title: "Номер телефона",
      dataIndex: "phone",
      key: "phone",
    },

    {
      title: "Действие",
      dataIndex: "Action",
      key: "Action",
      render: (id, row) => (
        <>
          <div className="btnWrapper-list" key={row.id}>
            <Button
              style={{ marginRight: 8 }}
              type="primary"
              icon={<EditOutlined />}
              onClick={() => handleEdit(row?.id)}
            />
            <Button
              type="dashed"
              onClick={() => handleDel(row?.id)}
              icon={<DeleteOutlined />}
            />
          </div>
        </>
      ),
    },
  ].filter((item) => !item.hidden);
  // const data = [
  //   {
  //     key: "11",
  //     ism: "Asadbek",
  //     address: "Navoiy, Qiziltepa",
  //     login: "Azamov",
  //     email: "azamov@mail.com",
  //     isboss: true,
  //     password: "1111111",
  //   },
  //   {
  //     key: "1111",
  //     ism: "Asadbek",
  //     address: "Navoiy, Qiziltepa",
  //     login: "Azamov",
  //     email: "azamov@mail.com",

  //     isboss: false,
  //     password: "1111111",
  //   },
  // ];

  return { data, columns, loading };
}
