import React from 'react';
import { ButtonElement } from './style';

export default function ButtonComponent({
  children,
  onClick,
  className = ' ',
  type = 'button',
  style,
  color = 'defualt',
}) {
  return (
    <ButtonElement
      style={style}
      className={className}
      onClick={onClick}
      type={type}
      colorbtn={color}
    >
      {children}
    </ButtonElement>
  );
}
