import { useQuery } from "@tanstack/react-query";
import { Col } from "antd";
import React from "react";
import { MapComponent } from "./map";
import { postApi } from "services/api/pagesApi";

export default function MapSection({ form, setLocation }) {
  const { watch } = form;
  const region = watch("region");
  const district = watch("district");

  const { data } = useQuery(
    ["map", region, district],
    () =>
      postApi.postMapSearch({
        Destination: district?.label,
        Origin: region?.label,
      }),
    {
      select: (res) => {
        return res?.data;
      },
      onSuccess: (res) => {
        console.log(res);
        setLocation(res);
        return res;
      },
      enabled: region && district ? true : false,
    }
  );

  return (
    <Col span={24}>
      <MapComponent
        height="600px"
        setValue={setLocation}
        data={data}
        markers={data ? [{ position: data }] : []}
      />
    </Col>
  );
}
