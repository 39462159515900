import Auth from "./modules/auth";
import Deletes from "./modules/delete";
import Gets from "./modules/gets";
import Posts from "./modules/posts";
import Puts from "./modules/updete";

const authApi = new Auth();
const getsApi = new Gets();
const postApi = new Posts();
const putApi = new Puts();
const DeletesApi = new Deletes();

export { authApi, getsApi, postApi, DeletesApi, putApi };
