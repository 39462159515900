import React, { useCallback, useEffect, useState } from "react";
import { GoogleMap, LoadScriptNext, Marker } from "@react-google-maps/api";
const ZOOM = 16;
const CENTER = { lat: 40.57371667464446, lng: 65.69197388629195 };

export default function YandexMap({ marker }) {
  const [map, setMap] = useState(null);
  const onLoad = useCallback((map) => setMap(map), []);
  const fitBounds = () => {
    const bounds = new window.google.maps.LatLngBounds();
    bounds.extend({ ...marker });
    map.fitBounds(bounds);
    map.setZoom(15);
  };

  useEffect(() => {
    if (map) {
      marker !== undefined && fitBounds();
    }
  }, [marker, map]);

  const containerStyle = {
    width: "100%",
    height: "400px",
    borderRadius: 6,
  };

  return (
    <div>
      <LoadScriptNext googleMapsApiKey={process.env.REACT_APP_GOOGLEMAP}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={CENTER}
          zoom={ZOOM}
          onLoad={onLoad}
          options={{
            mapTypeControl: false,
            streetViewControl: false,
          }}
        >
          <>
            <Marker position={marker} />
          </>
        </GoogleMap>
      </LoadScriptNext>
    </div>
  );
}

// import React, { useEffect, useState } from "react";
// import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
// import LoaderComponent from "components/loader";
// const ZOOM = 16;
// const CENTER = { lat: 40.57371667464446, lng: 65.69197388629195 };

// function YandexMap({ marker }) {
//   const [map, setMap] = useState(null);
//   const { isLoaded } = useJsApiLoader({
//     id: "google-map-script",
//     googleMapsApiKey: process.env.REACT_APP_GOOGLEMAP,
//   });

//   const onLoad = React.useCallback(function callback(map) {
//     const bounds = new window.google.maps.LatLngBounds(CENTER);
//     map.fitBounds(bounds);
//     setMap(map);
//   }, []);

//   const fitBounds = () => {
//     const bounds = new window.google.maps.LatLngBounds();
//     bounds.extend({ ...marker });
//     map.fitBounds(bounds);
//     map.setZoom(15);
//   };

//   const onUnmount = React.useCallback(function callback(map) {
//     setMap(null);
//   }, []);

//   useEffect(() => {
//     if (map) {
//       marker !== undefined && fitBounds();
//     }
//   }, [marker, map]);

//   const containerStyle = {
//     width: "100%",
//     height: "400px",
//     borderRadius: 6,
//   };
//   if (isLoaded) {
//     return (
//       <div>
//         <GoogleMap
//           id="google-map-script"
//           mapContainerStyle={containerStyle}
//           center={CENTER}
//           zoom={ZOOM}
//           options={{
//             mapTypeControl: false,
//             streetViewControl: false,
//           }}
//           onLoad={onLoad}
//           onUnmount={onUnmount}
//         >
//           <>
//             <Marker position={marker} />
//           </>
//         </GoogleMap>
//       </div>
//     );
//   } else {
//     <LoaderComponent />;
//   }
// }
// export default React.memo(YandexMap);
