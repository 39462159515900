import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  td {
    min-width: 200px;
  }
`;

export const CardCatalog = styled.div`
  background-color: red;
`;
